<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a @click="$router.go(-1)" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Workshop Working Time</h1>
                <div></div>
              </div>

              <Form @submit="handleOnSave">
                <div class="row mb-4 justify-content-center">
                  <div class="col-8">
                    <p class="font-18 font-medium mb-1">Set Working Days</p>
                    <p class="text-secondary mb-4">
                      Note: Unselected day will be considered as workshop's
                      weekly off.
                    </p>
                  </div>
                  <div class="col-lg-8 d-lg-flex justify-content-center mb-">
                    <div class="mb-4 border-bottom pb-4">
                      <div class="btn-group check-btns flex-wrap" role="group">
                        <div v-for="(item, i) in WorkingTImeList" :key="i">
                          <Field
                            type="checkbox"
                            class="btn-check"
                            :id="item.day"
                            autocomplete="off"
                            name="working_days"
                            :value="item.day"
                            v-model="setworkingTime.list"
                          />
                          <label
                            class="btn btn-outline-primary"
                            :for="item.day"
                          >
                            <img
                              src="static/images/check-icon.svg"
                              width="12"
                              class="check-icon"
                            />
                            {{ item.day }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 mb-4">
                    <p class="font-18 font-medium mb-1">Set Working Time</p>
                    <div class="row mb-4 mt-3">
                      <div class="col-lg-3">
                        <label class="form-label">Open Time </label>

                        <Field
                          name="from_time"
                          rules="required:Open Time,true"
                          id="from_time"
                          v-slot="{ field }"
                          v-model="setworkingTime.from_time"
                        >
                          <Datepicker
                            v-bind="field"
                            autoApply
                            placeholder="Open Time"
                            :is-24="true"
                            :enable-seconds="true"
                            timePicker
                            v-model="setworkingTime.from_time"
                            :maxTime="setworkingTime.to_time"
                          />
                        </Field>
                        <ErrorMessage
                          name="from_time"
                          class="validation-msg mt-1"
                        />
                      </div>

                      <div class="col-lg-3">
                        <label class="form-label">Close Time </label>
                        <Field
                          name="to_time"
                          rules="required:Close Time,true"
                          v-slot="{ field }"
                          v-model="setworkingTime.to_time"
                        >
                          <Datepicker
                            v-bind="field"
                            autoApply
                            placeholder="Close Time"
                            :is-24="true"
                            :enable-seconds="true"
                            timePicker
                            v-model="setworkingTime.to_time"
                            :minTime="setworkingTime.from_time"
                          />
                          <ErrorMessage
                            name="to_time"
                            class="validation-msg mt-1"
                          />
                        </Field>
                      </div>
                    </div>

                    <p class="font-18 font-medium mb-3">Pickup & Drop</p>
                    <div
                      class="btn-group check-btns flex-wrap w-100"
                      role="group"
                    >
                      <div class="row mb-3 w-100">
                        <div class="col-lg-3 col-md-4 col-6">
                          <Field
                            v-slot="{ field }"
                            name="is_pickup"
                            v-model="setPickupDropTime.is_pickup"
                          >
                            <input
                              v-bind="field"
                              type="checkbox"
                              class="btn-check"
                              id="is_pickup"
                              name="is_pickup"
                              @change="clearValue"
                              v-model="setPickupDropTime.is_pickup"
                              :validateOnInput="true"
                              true-value="Y"
                              false-value="N"
                            />
                            <label
                              class="btn btn-outline-primary"
                              for="is_pickup"
                              style="min-width: 100%"
                              ><img
                                src="static/images/check-icon.svg"
                                width="12"
                                class="check-icon"
                              />Pick-up</label
                            >
                          </Field>
                          <div class="mt-4"></div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <Field
                            v-slot="{ field }"
                            name="is_drop"
                            v-model="setPickupDropTime.is_drop"
                          >
                            <input
                              v-bind="field"
                              type="checkbox"
                              class="btn-check"
                              id="is_drop"
                              name="is_drop"
                              @change="clearValue"
                              v-model="setPickupDropTime.is_drop"
                              :true-value="'Y'"
                              :false-value="'N'"
                              :validateOnInput="true"
                            />
                            <label
                              class="btn btn-outline-primary"
                              for="is_drop"
                              style="min-width: 100%"
                              ><img
                                src="static/images/check-icon.svg"
                                width="12"
                                class="check-icon"
                              />Drop</label
                            >
                          </Field>
                          <div class="mt-4"></div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4 w-100">
                      <div
                        class="col-lg-3 col-md-4 col-6"
                        v-if="setPickupDropTime.is_pickup === 'Y'"
                      >
                        <label class="form-label">Pickup Range in KM</label>
                        <Field
                          type="number"
                          class="form-control"
                          name="pickup_km"
                          placeholder="Enter KM"
                          min="1"
                          rules="KILOMETER:PickUP"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                          :validateOnInput="true"
                          v-model="setPickupDropTime.pickup_km"
                        />
                        <ErrorMessage
                          name="pickup_km"
                          class="validation-msg mt-1"
                        />
                      </div>
                      <div class="col-lg-3 col-md-4 col-6" v-else></div>

                      <div
                        class="col-lg-3 col-md-4 col-6"
                        v-if="setPickupDropTime.is_drop === 'Y'"
                      >
                        <label class="form-label">Drop Range in KM</label>
                        <Field
                          type="number"
                          class="form-control"
                          name="drop_km"
                          placeholder="Enter KM"
                          min="1"
                          rules="KILOMETER:Drop"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                          :validateOnInput="true"
                          v-model="setPickupDropTime.drop_km"
                        />
                        <ErrorMessage
                          name="drop_km"
                          class="validation-msg mt-1"
                        />
                      </div>
                      <div class="col-lg-3 col-md-4 col-6" v-else></div>
                    </div>
                    <div class="row mb-4 w-100">
                      <div
                        class="col-lg-3 col-md-4 col-6"
                        v-if="setPickupDropTime.is_pickup === 'Y'"
                      >
                        <label class="form-label">Charges</label>
                        <Field
                          type="number"
                          class="form-control"
                          name="pickup_cost"
                          placeholder="₹00"
                          min="1"
                          rules="KILOMETER:Charge"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                          :validateOnInput="true"
                          v-model="setPickupDropTime.pickup_cost"
                        />
                        <ErrorMessage
                          name="pickup_cost"
                          class="validation-msg mt-1"
                        />
                      </div>
                      <div class="col-lg-3 col-md-4 col-6" v-else></div>
                      <div
                        class="col-lg-3 col-md-4 col-6"
                        v-if="setPickupDropTime.is_drop === 'Y'"
                      >
                        <label class="form-label">Charges</label>
                        <Field
                          type="number"
                          class="form-control"
                          name="drop_cost"
                          placeholder="₹00"
                          min="1"
                          rules="KILOMETER:Charge"
                          v-on:keypress="
                            $helperService.allowOnlyNumericValue($event)
                          "
                          :validateOnInput="true"
                          v-model="setPickupDropTime.drop_cost"
                        />
                        <ErrorMessage
                          name="drop_cost"
                          class="validation-msg mt-1"
                        />
                      </div>
                      <div class="col-lg-3 col-md-4 col-6" v-else></div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      native-type="submit"
                      class="btn btn-primary px-5"
                      id="working_btn"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>

<script>
import "./onboarding.scss";
import $ from "jquery";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent.vue";

export default {
  name: "setworkingTime",
  components: {
    Datepicker,
    Field,
    Form,
    ErrorMessage,
    ErrorComponent,
  },

  data() {
    return {
      id: "",
      WorkingTImeList: [],
      PickupDropList: [],
      setworkingTime: {
        from_time: "",
        to_time: "",
        list: [],
      },
      setPickupDropTime: {
        is_pickup: "N",
        is_drop: "N",
        pickup_km: 0,
        drop_km: 0,
        pickup_cost: 0,
        drop_cost: 0,
      },
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getWorkingTimeList();
      this.getPickupDrop();
    }

    $(".btn-check").change(function () {
      $(this).closest(".form-check").toggleClass("selected", this.checked);
    });
  },
  methods: {
    getWorkingTimeList() {
      this.setworkingTime.from_time = "";
      this.setworkingTime.to_time = "";
      this.setworkingTime.list = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      const daysOfWeek = [
        { id: "SUNDAY", day: "SUNDAY", is_ticked: false },
        { id: "MONDAY", day: "MONDAY", is_ticked: false },
        { id: "TUESDAY", day: "TUESDAY", is_ticked: false },
        { id: "WEDNESDAY", day: "WEDNESDAY", is_ticked: false },
        { id: "THURSDAY", day: "THURSDAY", is_ticked: false },
        { id: "FRIDAY", day: "FRIDAY", is_ticked: false },
        { id: "SATURDAY", day: "SATURDAY", is_ticked: false },
      ];

      const dayOrder = {
        MONDAY: 0,
        TUESDAY: 1,
        WEDNESDAY: 2,
        THURSDAY: 3,
        FRIDAY: 4,
        SATURDAY: 5,
        SUNDAY: 6,
      };

      this.$api
        .getAPI({
          _action: `get-working-time/` + this.id,
        })
        .then((res) => {
          if (res.list && res.list.length > 0) {
            const responseDays = res.list.map((item) => item.day);
            this.WorkingTImeList = daysOfWeek.map((day) => {
              if (responseDays.includes(day.day)) {
                day.is_ticked = true;
                this.setworkingTime.list.push(day.day);
              }
              return day;
            });
          } else {
            this.WorkingTImeList = daysOfWeek;
          }

          this.WorkingTImeList.sort(
            (a, b) => dayOrder[a.day] - dayOrder[b.day]
          );

          if (res.list[0].from_time) {
            var fromtime = res.list[0].from_time.split(":");
            this.setworkingTime.from_time = {
              hours: fromtime[0],
              minutes: fromtime[1],
              seconds: fromtime[2],
            };
          }
          if (res.list[0].to_time) {
            var time = res.list[0].to_time.split(":");
            this.setworkingTime.to_time = {
              hours: time[0],
              minutes: time[1],
              seconds: time[2],
            };
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    getPickupDrop() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `get-pickup-drop/` + this.id,
        })
        .then((res) => {
          this.setPickupDropTime.is_pickup = res.info.is_pickup;
          this.setPickupDropTime.is_drop = res.info.is_drop;
          this.setPickupDropTime.pickup_km = res.info.pickup_km;
          this.setPickupDropTime.drop_km = res.info.drop_km;
          this.setPickupDropTime.pickup_cost = res.info.pickup_cost;
          this.setPickupDropTime.drop_cost = res.info.drop_cost;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    handleworkingtimeClick(days) {
      this.setworkingTime.list.push = days;
    },

    handleOnSave() {
      this.handleWorkingDayPost(), this.handlePickDropPost();
    },

    handleWorkingDayPost() {
      if (!this.setworkingTime.list || this.setworkingTime.list.length == 0) {
        this.$toast.open({
          message: "Please select working days !!",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }

      if (this.setworkingTime.from_time) {
        this.setworkingTime.from_time = moment(
          this.setworkingTime.from_time
        ).format("HH:mm:ss");
      }
      if (this.setworkingTime.to_time) {
        this.setworkingTime.to_time = moment(
          this.setworkingTime.to_time
        ).format("HH:mm:ss");
      }

      if (
        this.setworkingTime.from_time + "" ==
        this.setworkingTime.to_time + ""
      ) {
        this.setworkingTime.from_time = "";
        this.setworkingTime.to_time = "";
        this.$toast.open({
          message: "Please select close time greater than open time !!",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }

      this.$api
        .postAPI({
          _action: "/set-working-time/" + this.id,
          _body: this.setworkingTime,
          _buttonId: "working_btn",
        })
        .then((res) => {
          this.getWorkingTimeList();
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          if (res) {
            if (this.page_type == "EDIT") {
              this.$router.push("/settings");
            }else {
            this.$router.push("/onboarding-steps");
          }
          } 
        })
        .catch(() => {
          this.$router.push("/working-time");
        });
    },
    handlePickDropPost() {
      if (!this.setworkingTime.list || this.setworkingTime.list.length == 0) {
        return;
      }
      this.$api
        .postAPI({
          _action: "/set-pickup-drop/" + this.id,
          _body: this.setPickupDropTime,
        })
        .then(() => {})
        .catch(() => {
          this.$router.push("/working-time");
        });
    },
  },
};
</script>
