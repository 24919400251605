<template>
  <div class="login-page">
    <section>
      <div class="container-fluid min-vh-100 h-xs-auto">
        <div class="row min-vh-100 h-xs-auto">
          <div
            class="col-lg-6 min-vh-100 h-xs-auto d-flex align-items-center order-2 order-lg-1"
            style="background-color: #00292e"
          >
            <div>
              <div class="text-center pt-lg-5 pt-3 image-body">
                <img
                  src="/static/images/login-caraousel-img.svg"
                  width="400"
                  class="img-fluid login-img"
                  alt=""
                />
              </div>
              <div id="login-carsousel" class="carousel slide login-carsousel">
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#login-carsousel"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#login-carsousel"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#login-carsousel"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner text-center">
                  <div class="carousel-item active">
                    <h2 class="title">Select the best experience for your ride!</h2>
                    <p class="description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna.
                    </p>
                  </div>
                  <div class="carousel-item">
                    <h2 class="title">Select the best experience for your ride!</h2>
                    <p class="description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna.
                    </p>
                  </div>
                  <div class="carousel-item">
                    <h2 class="title">Select the best experience for your ride!</h2>
                    <p class="description">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 order-1 order-lg-2">
            <div class="row justify-content-center h-100 align-items-center">
              <div class="form-style col-lg-8 py-4">
                <div class="text-center">
                  <img
                    src="static/images/logo-horizontal.svg"
                    width="200"
                    class="mx-auto mb-5"
                    alt=""
                  />
                  <div class="text-center">
                    <h1 class="font-22 mb-3">Login to your account</h1>
                    <p class="text-secondary">Enter OTP to verify mobile number</p>
                    <p class="font-medium">
                      <span class="text-decoration-underline">
                        {{ VerifyData?.mobile_number }}
                      </span>
                      &nbsp;
                      <a @click="this.$router.go(-1)" class="text-brand cursor-pointer"
                        >Edit</a
                      >
                    </p>
                  </div>
                  <Form ref="otpFormNew" @submit="verifyOtp()">
                    <div class="d-flex justify-content-between mb-4 d-flex gap-3">
                      <!-- <Field
                        id="codeBox1"
                        type="text"
                        class="form-control text-center font-22"
                        maxlength="1"
                        name="otp1"
                        v-on:keyup="onKeyUpEvent(1, $event)"
                        v-on:keydown="verifyOtp"
                        v-on:focus="onFocusEvent(1)"
                        rules="required"
                        :validateOnInput="true"
                      />
                      <Field
                        id="codeBox2"
                        type="text"
                        class="form-control text-center font-22"
                        maxlength="1"
                        name="otp2"
                        v-on:keyup="onKeyUpEvent(2, $event)"
                        v-on:keydown="verifyOtp"
                        v-on:focus="onFocusEvent(2)"
                        rules="required"
                        :validateOnInput="true"
                      />
                      <Field
                        id="codeBox3"
                        type="text"
                        class="form-control text-center font-22"
                        maxlength="1"
                        name="otp3"
                        v-on:keyup="onKeyUpEvent(3, $event)"
                        v-on:keydown="verifyOtp"
                        v-on:focus="onFocusEvent(3)"
                        rules="required"
                        :validateOnInput="true"
                      />
                      <Field
                        id="codeBox4"
                        type="text"
                        class="form-control text-center font-22"
                        maxlength="1"
                        name="name"
                        v-on:keyup="onKeyUpEvent(4, $event)"
                        v-on:keydown="verifyOtp"
                        v-on:focus="onFocusEvent(4)"
                        rules="required: OTP"
                        :validateOnInput="true"
                      /> -->

                      <v-otp-input
                        ref="otpInput"
                        input-classes="otp-input form-control text-center"
                        separator="&nbsp;&nbsp;&nbsp;&nbsp;"
                        inputType="text"
                        :num-inputs="4"
                        v-model:value="code"
                        :should-auto-focus="true"
                        :should-focus-order="true"
                        @on-change="handleOnChange"
                      />
                    </div>
                    <div class="mb-2">
                      <ErrorMessage name="name" class="text-danger" />
                    </div>

                    <button
                      native-type="submit"
                      class="btn btn-primary w-100 mb-3"
                      id="otp_verify"
                    >
                      Submit
                    </button>

                    <div class="text-center font-medium">
                      <span
                        v-if="!timerRunning"
                        @click="startTimer"
                        class="text-secondary cursor-pointer"
                        >Resend OTP</span
                      >
                      <span v-else class="text-danger"
                        >Resend OTP in {{ countdown }} seconds</span
                      >
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <button
      type="button"
      hidden
      id="add-partner-details"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#AddPartnerModal"
    ></button>

    <div
      class="modal fade"
      id="AddPartnerModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">AGREEMENT</h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div v-html="content"></div>
          </div>
          <div class="modal-footer">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="acknowledged"
              true-value="Y"
              false-value="N"
              id="acknowledged"
            />
            <label class="form-check-label" for="acknowledged"> I Acknowledged </label>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="getMeCall()"
              class="btn btn-primary"
              :disabled="acknowledged == 'N'"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./login.scss";
import $ from "jquery";
import { Form, ErrorMessage } from "vee-validate";
import VOtpInput from "vue3-otp-input";

export default {
  name: "PageOtp",

  components: {
    Form,
    // Field,
    ErrorMessage,
    VOtpInput,
  },

  data() {
    return {
      VerifyData: {},
      countdown: 30,
      code: "",
      timerRunning: false,
      timer: null, // Added timer property to store the interval ID
      push_token: localStorage.getItem("push_token"),
      url: "https://carappapi.winayak.com/vendor/",
      access_token: "",
      content: "",
      acknowledged: "N",
    };
  },

  mounted() {
    const mob_no = this.$sharedService.getMapValue("mobile_number");
    if (mob_no) {
      this.VerifyData.mobile_number = mob_no;
    } else {
      this.$router.push("/login");
      return;
    }
    console.log("rrfd01", this.VerifyData.mobile_number);

    $(document).ready(() => {
      $(".digit-group")
        .find("input")
        .each(function () {
          $(this).attr("maxlength", 1);
          $(this).on("keyup", (e) => {
            const parent = $($(this).parent());

            if (e.keyCode === 8 || e.keyCode === 37) {
              const prev = parent.find("input#" + $(this).data("previous"));

              if (prev.length) {
                $(prev).select();
              }
            } else if (
              (e.keyCode >= 48 && e.keyCode <= 57) ||
              (e.keyCode >= 65 && e.keyCode <= 90) ||
              (e.keyCode >= 96 && e.keyCode <= 105) ||
              e.keyCode === 39
            ) {
              const next = parent.find("input#" + $(this).data("next"));

              if (next.length) {
                $(next).select();
              } else {
                if (parent.data("autosubmit")) {
                  parent.submit();
                }
              }
            }
          });
        });
    });
    this.startTimer();
  },

  methods: {
    handleOnChange(value) {
      this.code = value;
    },
    startTimer() {
      this.stopTimer();
      this.onResendOtp();
      this.timerRunning = true;
      this.countdown = 30;

      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.stopTimer();
        }
      }, 1000);
    },

    stopTimer() {
      clearInterval(this.timer);
      this.timerRunning = false;
    },

    onResendOtp() {
      var obj = {};
      obj.type = "LOGIN";
      obj.mobile_number = this.VerifyData.mobile_number;
      this.$api
        .postAPI({
          _action: "/resend-otp",
          _body: obj,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            position: "top-right",
          });
        })
        .catch(() => {});
    },

    verifyOtp() {
      var obj = {};
      this.VerifyData.type = "LOGIN";

      obj.type = this.VerifyData.type;
      obj.mobile_number = this.VerifyData.mobile_number;
      obj.otp = this.code;

      if (obj.otp.length == 4) {
        this.VerifyData.type = "LOGIN";
        this.$api
          .postAPI({
            _action: "/verify-otp",
            _body: obj,
            _buttonId: "otp_verify",
            _hide_loader: false,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.access_token = res.access_token;
            localStorage.setItem("access_token", this.access_token);
            this.getMeCall();
            if (
              !this.$sharedService.getMapValue("is_agreement_show") ||
              this.$sharedService.getMapValue("is_agreement_show") == "Y"
            ) {
              this.getAgreement();
            } else {
              localStorage.setItem("access_token", this.access_token);
              this.getMeCall();
            }
          })
          .catch(() => {});
      } else {
        this.validate = true;
      }
    },
    getAgreement() {
      this.$api
        .getAPI({
          _action: "/partner-agreement",
        })
        .then((res) => {
          this.content = res;
          document.getElementById("add-partner-details").click();
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    },
    onKeyUpEvent(index, event) {
      if (index < 4) {
        console.log("event", event);
        const eventCode = event.which || event.keyCode;
        if (this.getCodeBoxElement(index).value.length == 1) {
          console.log("inside", index);
          if (index !== 4) {
            this.getCodeBoxElement(index + 1).focus();
          } else {
            console.log(".....", index);
            this.getCodeBoxElement(index).blur();
            // Submit code
            console.log("submit code ");
            this.validate = false;
          }
        }
        if (eventCode === 8 && index !== 1) {
          this.getCodeBoxElement(index - 1).focus();
          this.validate = true;
        } else {
          this.validate = false;
        }
      }
    },
    onFocusEvent(index) {
      if (index < 4) {
        for (var item = 1; item < index; item++) {
          const currentElement = this.getCodeBoxElement(item);
          if (!currentElement.value) {
            currentElement.focus();
            break;
          }
        }
      }
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    getMeCall() {
      localStorage.setItem("access_token", this.access_token);
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          var next_url = localStorage.getItem("next_url") ?? null;
          // this.getRegisterCall();

          if (next_url) {
            this.$router.push(next_url);
          } else {
            localStorage.setItem("next_url", "");
            this.$store.commit("setAccessToken", this.$access_token);
            localStorage.setItem("page_type", "ONBOARDING");
            this.$store.commit("setPageType", "ONBOARDING");

            if (res.result && res.result.workshop_count == 0) {
              this.$router.push("/personal-details");
            } else {
              this.$router.push("/choose-outlet");
            }
          }
          this.$sharedService.clearMap();
        })
        .catch(() => {});
    },

    // getRegisterCall() {
    //   if (this.push_token) {
    //     const obj = {
    //       token: this.push_token,
    //       push_type: "WEB_PUSH",
    //     };
    //     this.$api
    //       .postAPI({
    //         _action: `register-device`,
    //         _body: obj,
    //       })
    //       .then(() => {})
    //       .catch(() => {});
    //   }
    // },

    // getRegisterCall(res) {
    //   const obj = {
    //     mobile_number: res.mobile_number,
    //     dial_code: res.dial_code,
    //     country_code: res.country_code,
    //   };
    //   axios
    //     .post(`${this.url}/register`, obj, {
    //       headers: {
    //         Authorization: `Bearer ${this.push_token}`,
    //       },
    //     })
    //     .then(() => {
    //       // Handle success response
    //     })
    //     .catch(() => {
    //       // Handle error response
    //     });
    // },
  },
};
</script>
