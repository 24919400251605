<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-6">
              <h1 class="page-title">Conversation</h1>
            </div>
            <div class="col-lg-6">
              <div class="input-group page-search-control">
                <span
                  @click="getConversationList"
                  class="input-group-text"
                  id="inputGroup-sizing-default"
                  ><img src="static/images/search-icon-dark.svg" width="14" class="icon"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search.."
                  v-model="keyword"
                  @keydown.enter="getConversationList"
                />
              </div>
            </div>
          </div>
          <div
            class="card p-3 mb-3"
            v-for="(item, index) in this.conversationList"
            :key="index"
          >
            <div
              class="d-flex gap-4 align-items-center cursor-pointer"
              v-on:click="$router.push(`/chat/${item.chat.chat_conversation_id}`)"
            >
              <div>
                <img
                  :src="item?.uservehicle?.manufacturer_model?.icon"
                  width="200"
                  class="icon"
                />
              </div>
              <div class="w-100">
                <h4 class="mb-1 font-20 font-bold">
                  {{ item?.uservehicle?.manufacturer_model?.title }}
                </h4>
                <p class="m-0">
                  {{ item?.order?.category?.map((obj) => obj?.brand?.title).join(", ") }}
                </p>
                <p class="mb-0 text-secondary font-12">
                  {{ $helperService.getFormattedDateDDMMMMYY(item.chat.created_at) }}
                </p>
              </div>
              <div v-if="item.unread_count > 0">
                <div
                  class="bg-success d-flex justify-content-center align-items-center font-12 rounded-circle text-white"
                  style="width: 30px; height: 30px"
                >
                  {{ item.unread_count }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent ref="errorComponent" />
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "ConversationPage",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),
      conversationList: [],
      keyword: "",
    };
  },

  mounted() {
    this.getConversationList();
    localStorage.setItem("page_type", "HOME");
    this.$store.commit("setPageType", "HOME");
  },
  methods: {
    getConversationList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `conversation-list/${this.workshop_id}`,
          _body: { keyword: this.keyword },
        })
        .then((res) => {
          this.conversationList = res.list;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>

