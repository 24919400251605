<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7 text-center">
          <h1 class="page-title">FAQ's</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="accordion accordion-flush faq-accordion" id="accordionFlushExample">
            <template v-for="(item, i) in list" :key="item">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#flush-collapseOne_' + i"
                    aria-expanded="false"
                    :aria-controls="'flush-collapseOne_' + i"
                  >
                    {{ item.question }}
                  </button>
                </h2>
                <div
                  :id="'flush-collapseOne_' + i"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    {{ item.answer }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Alert  -->
  <div
    class="modal fade"
    id="AlertrModal"
    tabindex="-1"
    aria-labelledby="AlertrModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <img src="static/images/success-icon.svg" alt="" />
            <p>We have received your closure request, we will get back to you.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
export default {
  name: "FaqPage",
  components: {},
  data() {
    return {
      list: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.list = [];
      this.$api
        .getAPI({
          _action: "/faqs/VENDOR",
          _hide_loader: true,
        })
        .then((res) => {
          this.list = res;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

