<template>
  <div class="app">
    <notifications position="top right" classes="vue-notification" />
    <Header v-if="hasAccessToken"></Header>
    <main class="main-content">
      <router-view></router-view>
    </main>
    <Footer class="footer" v-if="hasAccessToken"> </Footer>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
    };
  },
  computed: {
    hasAccessToken() {
      return this.$store.getters.getAccessToken;
    },
  },
  mounted() {},
};
</script>
<style>
.vue-notification {
  margin-top: 30px !important;
}
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.footer {
  margin-top: auto;
}
</style>
