<template>
  <div class="login-page">
    <section>
      <div class="container-fluid min-vh-100 h-xs-auto">
        <div class="row min-vh-100 h-xs-auto">
          <div class="col-lg-6 order-1 order-lg-2 py-lg-5 py-4 px-lg-5">
            <i
              class="bi bi-arrow-left"
              style="
                width: 70px;
                height: 50px;
                cursor: pointer;
                color: black;
                font-size: 25px;
              "
              v-on:click="moveToBack"
            ></i>
            <div class="col-lg-6" style="margin-top: 7%">
              <input
                v-model="searchAddress"
                class="form-control"
                type="text"
                id="autocompleteInput"
                placeholder="Search address"
              />
            </div>
            <div ref="map" class="google-map card p-4 mt-3"></div>
          </div>
          <div class="col-lg-6 order-1 order-lg-2 py-lg-5 py-4 px-lg-5">
            <div class="">
              <div class="text-center">
                <img
                  src="static/images/logo-horizontal.svg"
                  width="200"
                  class="mx-auto mb-5"
                  alt=""
                />
                <h1 class="font-22 mb-3">Add Address</h1>
              </div>
              <div class="card p-4">
                <h4>Enter complete details</h4>
                <Form @submit="save" class="form-style">
                  <div class="mb-3">
                    <label for="" class="font-14 mb-2"
                      >Shop No / Building Name <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="address-line-1"
                      rules="required:Shop No / Building Name"
                      v-model="details.address_line_1"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="details.address_line_1"
                        placeholder="Enter Shop No / Building Name"
                      />
                    </Field>
                  </div>
                  <div>
                    <ErrorMessage name="address-line-1" class="validation-msg" />
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-14 mb-2">Area / Sector / Locality </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="details.address_line_2"
                      placeholder="Enter Area / Sector / Locality"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="" class="font-14 mb-2"
                      >Pin code <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="pincode"
                      rules="required:pincode"
                      v-model="details.pincode"
                    >
                      <input
                        v-bind="field"
                        type="text"
                        v-model="details.pincode"
                        class="form-control"
                        placeholder="Enter pincode"
                    /></Field>
                  </div>
                  <ErrorMessage name="pincode" class="validation-msg" />
                  <div class="mb-3">
                    <label for="" class="font-14 mb-2"
                      >Nearby Landmarks <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="landmark"
                      rules="required:Nearby Landmarks"
                      v-model="details.landmark"
                    >
                      <input
                        v-bind="field"
                        type="text"
                        v-model="details.landmark"
                        class="form-control"
                        placeholder="Enter Nearby Landmarks"
                    /></Field>
                  </div>
                  <ErrorMessage name="landmark" class="validation-msg" />
                  <div class="text-center">
                    <button
                      id="save-address"
                      native-type="submit"
                      class="btn btn-primary"
                    >
                      Save Details
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import "./login.scss";
import { Loader } from "@googlemaps/js-api-loader";
import { Form, Field, ErrorMessage } from "vee-validate";
ErrorComponent;
export default {
  name: "SetAddress",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },

  data() {
    return {
      googleMap: null,
      mapOptions: {
        center: { lat: 18.5204, lng: 73.8567 },
        zoom: 12,
        componentRestrictions: { country: "IN" },
        fields: ["address_components", "geometry", "name"],
        types: ["establishment"],
      },
      searchAddress: "",
      autocomplete: null,
      google: null,
      workshopDetail: this.$store.getters.getWorkshopDetails,
      details: {},
    };
  },
  mounted() {
    if (this.workshopDetail && this.workshopDetail.workshop) {
      this.details = this.workshopDetail.workshop;
    }
    this.loadGoogleMaps();
  },
  methods: {
    moveToBack() {
      this.$router.go(-1);
    },
    loadGoogleMaps() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      const loader = new Loader({
        apiKey: "AIzaSyDF5xEC_aqtTstR0ItP-9-o4rg9d5Ccomk",
        version: "weekly",
        libraries: ["places"],
      });
      if (
        this.details &&
        this.details.latitude !== undefined &&
        this.details.longitude !== undefined
      ) {
        if (
          this.details &&
          !isNaN(this.details.latitude) &&
          !isNaN(this.details.longitude)
        ) {
          this.mapOptions.center = {
            lat: parseFloat(this.details.latitude),
            lng: parseFloat(this.details.longitude),
          };
        }
      }

      loader
        .load()
        .then((google) => {
          this.google = google;
          this.initMap();
          this.initAutocomplete();
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error("Error loading Google Maps API:", error);
        });
    },

    initMap() {
      this.googleMap = new this.google.maps.Map(this.$refs.map, this.mapOptions);

      if (
        this.details &&
        this.details.address_line_1 &&
        this.googleMap &&
        this.mapOptions &&
        this.mapOptions.center
      ) {
        new this.google.maps.Marker({
          position: this.mapOptions.center,
          map: this.googleMap,
          title: this.details.address_line_1,
        });
      } else if (this.googleMap && this.mapOptions && this.mapOptions.center) {
        new this.google.maps.Marker({
          position: this.mapOptions.center,
          map: this.googleMap,
          title: "Pune, India",
        });
      }
    },

    initAutocomplete() {
      this.autocomplete = new this.google.maps.places.Autocomplete(
        document.getElementById("autocompleteInput"),
        {
          componentRestrictions: { country: "IN" },
          fields: ["address_components","formatted_address", "geometry", "name"],
          types: ["establishment"],
        }
      );

      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          console.error("No location found for input:", place);
          return;
        }

        this.addMarker(place);

        this.googleMap.setCenter(place.geometry.location);
        this.googleMap.setZoom(15);
      });
    },

    addMarker(place) {
      if (!this.googleMap) {
        console.error("Google Map not initialized yet.");
        return;
      }
      const marker = new this.google.maps.Marker({
        position: place.geometry.location,
        map: this.googleMap,
        title: place.formatted_address,
        pincode: this.getPincodeFromPlace(place),
        landmark: place.name,
      });

      if (marker) {
        this.details.address_line_1 = marker.title;
        this.details.pincode = marker.pincode;
        this.details.landmark = marker.landmark;
      }
    },

    getPincodeFromPlace(place) {
      const addressComponents = place.address_components || [];
      for (let component of addressComponents) {
        for (let type of component.types) {
          if (type === "postal_code") {
            return component.long_name;
          }
        }
      }
      return "";
    },
    save() {
      this.$router.push("/personal-details");
    },
  },
};
</script>

<style scoped>
.google-map {
  display: flex;
  width: 100%;
  height: 490px;
}
</style>