<template>
  <div class="page-content">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-7">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
            >
              <a v-on:click="this.$router.push('/settings')" class="cursor-pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-0">Partner Details</h1>
              <div></div>
            </div>
            <Form @submit="onSave">
              <div
                class="card p-3 form-style mb-3 bg-light"
                v-for="(item, index) in this.PatnerList"
                :key="index"
              >
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="font-18">Partner details {{ index + 1 }}</h4>
                  </div>

                  <div class="col-md-6 text-end" v-on:click="storeIndex(index)">
                    <a
                      class="font-16 cursor-pointer"
                      data-bs-toggle="modal"
                      data-bs-target="#AlertModal"
                    >
                      <i
                        class="bi bi-trash text-danger"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Remove"
                      ></i>
                    </a>
                  </div>
                </div>

                <div class="mb-3">
                  <label for="" class="form-label">Partner’s name</label>
                  <Field
                    type="text"
                    id="name"
                    class="form-control"
                    v-model="item.name"
                    :name="`name` + index"
                    placeholder="Enter Partner’s name"
                    rules="required:Name "
                    :validateOnInput="true"
                  />
                  <ErrorMessage
                    :name="`name` + index"
                    component="span"
                    class="text-danger"
                  />
                </div>
                <div class="mb-3">
                  <label for="" class="form-label"
                    >Partner’s mobile number</label
                  >
                  <Field
                    type="text"
                    id="mobile_number"
                    class="form-control"
                    v-model="item.mobile_number"
                    :name="`mobile_number` + index"
                    placeholder="Enter Mobile Number "
                    rules="required:mobile number|mobileNumber"
                    :validateOnInput="true"
                    v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                  />
                  <ErrorMessage
                    :name="`mobile_number` + index"
                    component="span"
                    class="text-danger"
                  />
                </div>
                <div class="">
                  <label for="" class="form-label">Partner’s PAN Number </label>

                  <Field
                    type="text"
                    id="pan_number"
                    class="form-control"
                    v-model="item.pan_number"
                    :name="`pan_number` + index"
                    placeholder="Enter pan_number  "
                    rules="panNumber"
                    :validateOnInput="true"
                  />
                  <ErrorMessage
                    :name="`pan_number` + index"
                    component="span"
                    class="text-danger"
                  />
                </div>
              </div>

              <div class="text-end">
                <button
                  class="btn btn-secondary px-4"
                  v-on:click="handleAddPatner"
                >
                  + Add
                </button>
              </div>

              <div class="text-center my-5">
                <button
                  native-type="submit"
                  id="btn_save"
                  class="btn btn-primary px-5"
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!--  Alert -->
  <div
    class="modal fade"
    id="AlertModal"
    tabindex="-1"
    aria-labelledby="AlertModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center p-4">
          <p class="font-bold font-18 mb-3">Remove Partner</p>
          <p class="font-14 mb-4 text-secondary">
            Are you sure you want to remove the partner?
          </p>
          <div class="d-flex gap-3">
            <button
              type="button"
              class="btn btn-outline-danger w-100"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button
              id="close_popup"
              type="button"
              class="btn btn-primary w-100"
              data-bs-dismiss="modal"
              v-on:click="handleRemovePatner(this.indexValue)"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../Onboarding/onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "PartnerDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),
      PatnerList: [
        {
          name: "",
          mobile_number: "",
          pan_number: "",
          dial_code: "+91",
          country_code: "IN",
        },
      ],
      indexValue: "",
    };
  },

  mounted() {
    this.getPatnerData();
  },
  methods: {
    getPatnerData() {
      this.$api
        .getAPI({
          _action: `get-workshop-partner`,
        })
        .then((res) => {
          if (res.list.length > 0) {
            this.PatnerList = res.list;
          }
        })
        .catch(() => {});
    },
    handleAddPatner() {
      this.PatnerList.push({
        name: "",
        mobile_number: "",
        pan_number: "",
        dial_code: "+91",
        country_code: "IN",
      });
    },
    handleRemovePatner(index) {
      this.PatnerList.splice(index, 1);
      this.indexValue = "";
      console.log(" this.PatnerList222", this.PatnerList);
    },
    onSave() {
      const obj = {
        partner_list: this.PatnerList,
      };
      this.$api
        .postAPI({
          _action: `set-workshop-partner`,
          _buttonId: "btn_save",
          _body: obj,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        })
        .catch(() => {});
    },
    storeIndex(index) {
      console.log(" this.PatnerList", this.PatnerList);
      this.indexValue = index;
    },
  },
};
</script>
