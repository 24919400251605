<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Choose CNG Brands</h1>
                <div></div>
              </div>
              <div class="row mb-4 justify-content-center">
                <div class="col-lg-6 text-center mb-4">
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      ><img
                        src="static/images/search-icon-dark.svg"
                        width="14"
                        alt=""
                        v-on:click="getManufactureList"
                    /></span>

                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by Battery Brands"
                      v-model="keyword"
                      @keydown.enter="getManufactureList"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes logos flex-wrap"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, index) in CNGBrandLIst"
                        :key="index"
                      >
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="btn-check form-check-input"
                            :id="item.tittle"
                            autocomplete="off"
                            true-value="1"
                            false-value="0"
                            v-model="item.is_ticked"
                            v-on:change="handlecheckbox(item.id)"
                          />
                        </div>
                        <label
                          class="btn btn-outline-primary"
                          data-bs-target="#selectModal"
                          :style="
                            item.is_ticked ? { borderColor: '#004852' } : null
                          "
                        >
                          <div class="label">{{ item.tittle }}</div>
                          <img
                            :src="
                              item.icon
                                ? item.icon
                                : 'static/images/default.png'
                            "
                            class="icon"
                            width="110"
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    @click="onSetCNGBrand"
                    class="btn btn-primary px-5"
                    id="cng_btn"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";

import "../onboarding.scss";

// import $ from "jquery";
export default {
  name: "ChooseCNGBrands",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),
      vehicle_id: this.$route.params.id,
      CNGBrandLIst: [],
      selectedCNGBrand: null,
      keyword: "",
      Brands: this.$sharedService.getMapValue("Brands"),
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    //     $(".btn-check").change(function () {
    //     $(this).closest(".form-check").toggleClass('selected', this.checked);a
    // });
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    if (this.workshop_id) {
      this.getManufactureList();
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },

    handlecheckbox(id) {
      if (!this.selectedCNGBrand.includes(id)) {
        this.selectedCNGBrand.push(id);
      } else {
        this.selectedCNGBrand = this.selectedCNGBrand.filter(
          (item) => item !== id
        );
      }
      console.log("this.selectedCNGBrandID---------", this.selectedCNGBrand);
    },
    getManufactureList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "get-brand/" + this.workshop_id + "?type=CNG",
          _body: {
            keyword: this.keyword,
          },
        })
        .then((res) => {
          this.CNGBrandLIst = res.list;

          if (res.list.length > 0) {
            this.selectedCNGBrand = [];
            for (let i = 0; i < res.list.length; i++) {
              if (res.list[i].is_ticked && res.list[i].is_ticked) {
                this.selectedCNGBrand.push(res.list[i].id);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error(error);
        });
    },
    onSetCNGBrand() {
      const obj = {
        list: this.selectedCNGBrand,
      };
      this.$api
        .postAPI({
          _action: "set-brand/CNG/" + this.workshop_id,
          _body: obj,
          _buttonId: "cng_btn",
        })
        .then(() => {
          this.$router.push("/rate-card");
        })
        .catch(() => {});
    },
  },
};
</script>

