<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div>
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Select Vehicle Model</h1>
                <div></div>
              </div>

              <div
                class="row mb-4 justify-content-center"
                v-if="vehicleModalList && vehicleModalList.length > 0"
              >
                <div class="col-8 mb-4">
                  <p class="text-secondary">
                    This cost is applicable to the Periodic services only
                  </p>
                </div>

                <Form @submit="onSaveSetCost()">
                  <div class="col-lg-8 mb-4">
                    <table class="table table-bordered" style="width: 100%">
                      <tr class="font-14">
                        <th>Vehicle Model Name</th>
                        <th>Fuel</th>
                        <th width="200" style="padding: 10px">Cost</th>
                      </tr>

                      <tr
                        class="font-16"
                        v-for="(item, index) in vehicleModalList"
                        :key="index"
                      >
                        <td>{{ item.title }}</td>
                        <td>{{ item.fuel.title }}</td>
                        <td style="padding: 10px">
                          <Field
                            type="number"
                            class="form-control"
                            :name="'cost_' + index"
                            placeholder="Enter Cost"
                            rules="required:cost"
                            v-model="item.cost"
                            v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            :name="'cost_' + index"
                            class="validation-msg mt-1"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="text-center mt-5">
                    <button
                      id="btn_save"
                      class="btn btn-primary px-5"
                      :disabled="isShowNoData"
                      native-type="submit"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              </div>

              <span>
                <ErrorComponent @retry="getModalData()" ref="errorComponent" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "VehicleModel",
  components: {
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    return {
      workshop_id: "",
      vehicle_id: this.$route.params.id,
      manufacture_id: this.$route.params.btn,
      vehicleModalList: [],
      isShowNoData: false,
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    if (this.workshop_id) {
      this.getModalData();
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getModalData() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action:
            `my-manufacturer-model/` +
            this.vehicle_id +
            "/" +
            this.manufacture_id +
            "/" +
            this.workshop_id,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
              this.isShowNoData = true;
            }
          }
          this.vehicleModalList = res.list;
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    updateCost(index, cost) {
      this.vehicleModalList[index].cost = cost;
    },
    onSaveSetCost() {
      const list = this.vehicleModalList.map((item) => ({
        manufacturer_model_id: item.id,
        fuel_id: item.fuel_id,
        cost: item.cost,
      }));

      const obj = {
        vehicle_id: this.vehicle_id,
        manufacturer_id: this.manufacture_id,
        list: list,
      };
      this.$api
        .postAPI({
          _action: `set-cost/${this.workshop_id}`,
          _buttonId: "btn_save",
          _body: obj,
        })
        .then(() => {
          this.$router.push("/set-service-manufacture-cost/" + this.vehicle_id);
        })
        .catch(() => {});
    },
  },
};
</script>

