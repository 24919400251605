<template>
  <div class="page-content">
    <div class="container">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7 text-center">
          <h1 class="page-title">Account</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <div class="card p-3 rounded-4 shadow-sm bg-light mb-5">
            <h4 class="font-18 mb-3">Account Settings</h4>
            <div class="row justify-content-center">
              <div class="col-lg-6 mb-3">
                <div
                  class="card p-3 rounded-4 cursor-pointer"
                  v-if="this.meData.type != `SECONDARY`"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/profile')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-profile.svg"
                        width="30"
                        height="30"
                      />
                      <div>Profile</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/orders')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-cart.svg"
                        width="30"
                        height="30"
                      />
                      <div>Order</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/service-report')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-service-report.svg"
                        width="30"
                        height="30"
                      />
                      <div>Service Report</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/settings')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-setting.svg"
                        width="30"
                        height="30"
                      />
                      <div>Settings</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/choose-outlet')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-switch.svg"
                        width="30"
                        height="30"
                      />
                      <div>Switch Outlet</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card p-3 rounded-4 shadow-sm bg-light mb-5">
            <h4 class="font-18 mb-3">Data & Privacy</h4>
            <div class="row justify-content-center">
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/privacy-policy')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-privacy.svg"
                        width="30"
                        height="30"
                      />
                      <div>Privacy Policy</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="this.$router.push('/terms-condition')"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-terms.svg"
                        width="30"
                        height="30"
                      />
                      <div>Terms & Conditions</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div
                  class="card p-3 rounded-4 cursor-pointer"
                  v-if="this.meData.type != `SECONDARY`"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                    v-on:click="handleCloseAccount"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-close-account.svg"
                        width="30"
                        height="30"
                      />
                      <div>Close Account</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3" v-on:click="handleLogout">
                <div class="card p-3 rounded-4 cursor-pointer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="d-flex gap-2 align-items-center">
                      <img
                        src="static/images/icon-logout.svg"
                        width="30"
                        height="30"
                      />
                      <div>Logout</div>
                    </div>
                    <a href="javascript:void(0)">
                      <svg
                        width="10"
                        height="14"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L5 5L1 9"
                          stroke="#001D21"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorComponent ref="errorComponent" />
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "AccountNav",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      meData: {},
    };
  },

  mounted() {
    localStorage.setItem("page_type", "HOME");
    this.$store.commit("setPageType", localStorage.getItem("page_type"));
    this.getmecall;
  },
  methods: {
    getMeCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          this.meData = res.result;
        })
        .catch(() => {});
    },
    handleLogout() {
      this.$swal({
        title: "Are you sure?",
        text: "You want to logout ",
        icon: "Warning",
        showCancelButton: true,
        confirmButtonText: "Yes,",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(true);
          }
          this.$api
            .getAPI({
              _action: "logout",
            })
            .then(() => {
              // this.handleUnRegisterDevice();
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
              localStorage.clear();
              this.$api.clearCache();
              this.$store.commit("setPageType", "");
              this.$store.commit("setAccessToken", "");
              this.$store.commit("setWorkshopId", "");
              this.$router.push("/login");
            })
            .catch(() => {
              if (this.$refs.errorComponent) {
                this.$refs.errorComponent.showLoader(false);
              }
            });
        }
      });
    },
    // handleUnRegisterDevice() {
    //   const obj = {
    //     token: "",
    //     push_type: "WEB_PUSH",
    //   };
    //   this.$api
    //     .postAPI({
    //       _action: `unregister-device`,
    //       _body: obj,
    //     })
    //     .then(() => {})
    //     .catch(() => {});
    // },
    handleCloseAccount() {
      this.$sharedService.setMapValue("close-account-type", null);
      this.$router.push("/close-account");
      this.$sharedService.setMapValue("close-account-type", `CLOSE_ACCOUNT`);
    },
  },
};
</script>

