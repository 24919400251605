<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-10">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4"
            >
              <a @click="$router.go(-1)" class="cursor-pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-4">Workshop Photos & Videos</h1>
              <a href="javascript:void(0)" v-on:click="back()" class="text-brand">Skip</a>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <p class="font-18 font-medium">
                  Workshop Photos
                  <span class="font-12 text-secondary">(Maximum 10 Photos)</span>
                </p>
              </div>

              <div class="col-lg-4 mb-3" v-for="(res, index) in images" :key="index">
                <div class="uploaded-photo" v-if="res.image">
                  <img
                    :src="res.image"
                    class="image"
                    height="180"
                    alt=""
                    style="height: 180px"
                  />
                  <a @click="deletePhoto(res.id, index)" class="close-btn cursor-pointer"
                    ><img
                      src="static/images/close-icon.svg"
                      class="close-icon"
                      width="20"
                      alt=""
                  /></a>
                </div>

                <div class="upload-btn-container" v-else>
                  <div>
                    <input
                      type="file"
                      id="photo-file-upload"
                      accept="image/*"
                      @change="photoUpload($event, index)"
                    />
                    <label for="photo-file-upload h-auto">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.images.length < 10" class="text-center mt-4 mb-4">
              <button class="btn btn-outline-primary px-5" @click="addPhotoUploadField">
                <img
                  src="static/images/icon-camera.svg"
                  width="20"
                  class="align-text-top me-2"
                  alt=""
                />
                Add More Photos
              </button>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <p class="font-18 font-medium">Workshop Videos</p>
              </div>

              <div class="col-lg-4 mb-3" v-for="(item, i) in videos" :key="i">
                <div class="uploaded-photo" v-if="item.video">
                  <video style="width: 320px; height: 240px" class="image" controls>
                    <source :src="item.video" type="video/mp4" />
                  </video>
                  <a class="close-btn cursor-pointer" @click="deleteVideo(item.id, index)"
                    ><img
                      src="static/images/close-icon.svg"
                      class="close-icon"
                      width="20"
                      alt=""
                  /></a>
                </div>

                <div class="upload-btn-container" v-else>
                  <input
                    type="file"
                    id="tb-file-upload"
                    accept="video/*"
                    @change="videoUpload($event, i)"
                  />
                  <label for="tb-file-upload h-auto">
                    <div class="py-4 text-center">
                      <div class="mb-3">
                        <img
                          src="static/images/video-file.svg"
                          width="20"
                          class="mx-auto"
                          alt=""
                        />
                      </div>
                      <div class="mb-3 text-secondary">Select document to upload</div>
                      <div>
                        <button class="btn btn-primary">Upload File</button>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getWorkshopProgressList()" ref="errorComponent" />
      </div>
    </section>
    <div class="col-12 text-center border-top pt-3 mb-5">
      <button
        v-on:click="handleContinue"
        id="continue-btn"
        class="btn btn-primary px-5"
        type="button"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "PhotoVideos",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      id: "",
      list: [],
      images: [],
      videos: [],
      photoUploadFields: [],
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getVideoList(this.id);
      this.getPhotoList(this.id);
    } else {
      this.$router.push("/choose-outlet");
    }
  },
  methods: {
    back() {
      if (this.page_type == "EDIT") {
        this.$router.push("/settings");
      } else {
        this.$router.push("/onboarding-steps");
      }
    },
    getVideoList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-video/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          if (res.list && res.list.length <= 0) {
            this.addDefaultvedioUploadFields(1);
          } else {
            this.videos = res.list;
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
        });
    },
    getPhotoList() {
      this.images = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-gallery/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          const newArr = [];
          let mainList = res.list;
          for (let i = 0; i < mainList.length; i++) {
            if (mainList[i].type == "OTHER") {
              const listItem = mainList[i];
              if (listItem.list && listItem.list.length > 0) {
                for (let j = 0; j < listItem.list.length; j++) {
                  const nestedItem = listItem.list[j];
                  newArr.push(nestedItem);
                }
              }
            }
          }
          this.images = newArr;
          while (this.images.length < 6) {
            this.addPhotoUploadField();
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })

        .catch(() => {});
    },
    addDefaultPhotoUploadFields(count) {
      for (let i = 0; i < count; i++) {
        this.addPhotoUploadField();
      }
    },

    addDefaultvedioUploadFields(count) {
      for (let i = 0; i < count; i++) {
        this.addVideoUploadField();
      }
    },

    addVideoUploadField() {
      this.videos.push({});
    },

    addPhotoUploadField() {
      this.images.push({});
    },

    videoUpload(event, index) {
      const file = event.target.files[0];
      const validTypes = ["video/mp4", "video/webm", "video/ogg"];
      const maxDuration = 120; // Maximum duration in seconds (2 minutes)

      if (!validTypes.includes(file.type)) {
        alert("Only video files (mp4, webm, ogg) are allowed.");
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const video = document.createElement("video");
        video.onloadedmetadata = () => {
          if (video.duration > maxDuration) {
            this.$toast.open({
              message: `Maximum video duration allowed is ${maxDuration} seconds.`,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            return;
          }

          const blob = URL.createObjectURL(file);
          for (let i in this.videos) {
            if (index == i) {
              this.videos[i].video = blob;
              this.videos[i].file = file;
            }
          }
        };
        video.src = URL.createObjectURL(file);
      };
      reader.readAsDataURL(file);
    },

    photoUpload(event, index) {
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validTypes.includes(file.type)) {
        alert("Only jpg, png, and jpeg files are allowed.");
        this.isloading = false;
        return;
      }

      const blob = URL.createObjectURL(file);
      for (let i in this.images) {
        if (index == i) {
          this.images[i].image = blob;
          this.images[i].file = file;
        }
      }
      console.log(this.images);
    },

    handleContinue() {
      let count = 0;
      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i].file) {
          count++;
        }
      }
      for (let j = 0; j < this.videos.length; j++) {
        if (this.videos[j].file) {
          count++;
        }
      }
      if (count <= 0) {
        this.$toast.open({
          message: "Please select image or video !!",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      if (this.videos.length > 0) {
        this.handleVideoPost(0);
      }
      if (this.images.length > 0) {
        this.handleImagePost(0);
      }
    },

    handleImagePost(index) {
      if (index < this.images.length) {
        if (!this.images[index].id && this.images[index].file) {
          var obj = {
            image: this.images[index].file,
            type: "OTHER",
          };
          this.$api
            .uploadImageAPI({
              _action: "/upload-gallery/" + this.id,
              _body: obj,
            })
            .then((res) => {
              this.$toast.open({
                message: res.message,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
              if (index != this.images.length - 1) {
                this.handleImagePost(index + 1);
              } else {
                this.images[index].file = null;
                if (this.page_type == "EDIT") {
                  this.$router.push("/settings");
                } else {
                  this.$router.push("/onboarding-steps");
                }
              }
            })
            .catch((error) => {
              console.error("Error uploading video", error);
              this.$router.push("/photos-video");
            });
        } else {
          this.handleImagePost(index + 1);
        }
      } else {
        this.getPhotoList();
      }
    },

    handleVideoPost(index) {
      if (!this.videos[index].id && this.videos[index].file) {
        this.$api
          .uploadImageAPI({
            _action: "/upload-video/" + this.id,
            _file: this.videos[index].file,
            _key: "video",
            _buttonId: "continue-btn",
          })
          .then((res) => {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.videos[index].file = null;
            this.getVideoList();
            if (this.page_type == "EDIT") {
              this.$router.push("/settings");
            } else {
              this.$router.push("/onboarding-steps");
            }
          })
          .catch((error) => {
            console.error("Error uploading video", error);
            this.$router.push("/photos-video");
          });
      }
    },

    deleteVideo(id, index) {
      this.videos.push({});
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }

      if (!id) {
        this.videos.splice(index, 1);
        if (this.$refs.errorComponent) {
          this.$refs.errorComponent.showLoader(false);
        }
      } else {
        this.$api
          .deleteAPI({
            _action: "/delete-video/" + id,
          })
          .then(() => {
            this.videos.splice(index, 1);
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          });
      }
    },

    deletePhoto(id, index) {
      this.images.push({});
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      if (!id) {
        this.images.splice(index, 1);
        if (this.$refs.errorComponent) {
          this.$refs.errorComponent.showLoader(false);
        }
      } else {
        this.$api
          .deleteAPI({
            _action: "/delete-gallery-image/" + id,
          })
          .then(() => {
            this.getPhotoList();
            this.images.splice(index, 1);
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          });
      }
    },
  },
};
</script>

