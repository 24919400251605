import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import api from "./services/WebService";
import "./services/ValidationRules";
import sharedService from "./services/ShareService";
import helperService from "./services/HelperService";
import storeService from "./services/StoreService";
import Notifications from "@kyvg/vue3-notification";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import VueNumeric from "@handcrafted-market/vue3-numeric";
import "../public/static/css/style.scss";
import mitt from "mitt";
import store from "./services/StoreService";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// import firebaseMessaging from './firebase'
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { initializeApp } from "firebase/app";
// import 'firebase/messaging'

const options = {
  confirmButtonColor: "#41b882",
  cancelButtonColor: "#ff7674",
};
const emitter = mitt();

const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$storeService = storeService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(ToastPlugin);
app.use(router);
app.use(Notifications);
app.component("vue-numeric", VueNumeric);
app.use(store);
app.use(VueSweetalert2, options);
// app.config.globalProperties.$messaging = firebaseMessaging

// if (navigator.serviceWorker) {
//   setInterval(() => {
//           navigator.serviceWorker.register('/firebase-messaging-sw.js')
//               .then((registration) => {
//                   const firebaseApp = initializeApp({
//                     apiKey: "AIzaSyBliwf5q5HtKxcphl9kYocLc5Tvd5SxASk",
//                     authDomain: "garage-finder-vendor.firebaseapp.com",
//                     projectId: "garage-finder-vendor",
//                     storageBucket: "garage-finder-vendor.appspot.com",
//                     messagingSenderId: "237569574879",
//                     appId: "1:237569574879:web:ad7fff69b173a3a5aaa66a",
//                     measurementId: "G-8KQBCZM4KK"
//                   });




//                   const messaging = getMessaging(firebaseApp);

//                   if (!localStorage.getItem("push_token")) {
//                       getToken(messaging, {
//                               serviceWorkerRegistration: registration,
//                               vapidKey: "BBOJZ4lrBaU1fzAhYXc1MAk64xdJ7w9Mwdo9mYVNRS13kPbI2sIT-thUGdlv5QO8mWxelCzXcU_qxrZv2vVA3yo",
//                           })
//                           .then((currentToken) => {
//                               if (currentToken) {
//                                   console.log("token " + currentToken);
//                                   localStorage.setItem("push_token", currentToken)
//                               } else {
//                                   console.log(
//                                       "No registration token available. Request permission to generate one."
//                                   );
//                               }
//                           })
//                           .catch((err) => {
//                               console.log("An error occurred while retrieving token. ", err);
//                           });
//                   }


//                   onMessage(messaging, (payload) => {
//                       console.log("Payload", payload)
//                       const notificationOptions = {
//                           // body: payload.notification.body + payload.data ? moment.utc(payload.data.date).local().format("DD MMM YYYY hh:mm A") : '',
//                           body: payload.notification.body,
//                           icon: payload.notification.image,
//                       };
//                       registration.showNotification(
//                           payload.notification.title,
//                           notificationOptions
//                       );
//                       console.log("notifiva", registration.showNotification(
//                           payload.notification.title,
//                           notificationOptions
//                       ))
//                   });
//                   console.log('Registration successful, scope is:', registration.scope);

//               }).catch(err => {
//                   console.log(err)
//               })
//       }, 5000) // every 5 seconds
// }

app.mount("#app");
