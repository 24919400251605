<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
          <!-- <div class="text-center border-bottom py-3">
            <img
              src="static/images/logo-horizontal.svg"
              width="200"
              class="mx-auto"
              alt=""
            />
          </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a href="javascript:history.back()" class=" ">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Select Fuel Type</h1>
                <div></div>
              </div>

              <div
                class="row mb-4 justify-content-center"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="col-8"
                  v-if="item.fuel_list && item.fuel_list.length > 0"
                >
                  <p class="text-secondary">
                    Select fuel types for which workshop offers services
                  </p>
                  <p class="font-18 font-medium">
                    For {{ item.vehicle.title }}
                  </p>
                </div>

                <div
                  class="col-lg-8 d-lg-flex mb-4"
                  :class="{
                    'border-bottom pb-4':
                      item.fuel_list && item.fuel_list.length > 0,
                  }"
                >
                  <div class="btn-group check-btns flex-wrap" role="group">
                    <div class="" v-for="(data, i) in item.fuel_list" :key="i">
                      <input
                        type="checkbox"
                        class="btn-check"
                        :id="'fuel' + data.id"
                        autocomplete="off"
                        v-model="data.is_ticked"
                        @change="
                          storeVehicleAndFuelId(
                            data.fuel_id,
                            data.vehicle_id,
                            $event.target.checked
                          )
                        "
                        true-value="1"
                        false-value="0"
                      />
                      <label
                        class="btn btn-outline-primary"
                        :for="'fuel' + data.id"
                      >
                        <img
                          src="static/images/check-icon.svg"
                          width="12"
                          class="check-icon"
                        />
                        {{ data.fuel.title }}</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getFuelList()" ref="errorComponent" />
              <div class="text-center mb-3">
                <button
                  id="save-fuel"
                  v-on:click="saveFuel"
                  class="btn btn-primary px-5"
                  :disabled="
                    !(
                      selectedFuel &&
                      selectedFuel.length > 0 &&
                      totalSize === uniqueVehicleSize
                    )
                  "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import $ from "jquery";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "FuelType",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshop_id: "",
      list: [],
      selectedFuel: [],
      flag: false,
      totalSize: 0,
      uniqueVehicleSize: 0,
      page_type: localStorage.getItem("page_type")
    };
  },

  mounted() {
    $(".btn-check").change(function () {
      $(this).closest(".form-check").toggleClass("selected", this.checked);
    });
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    this.getFuelList();
  },
  methods: {
    getFuelList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-fuel/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            console.log(res.list);
            this.list = res.list;
            this.totalSize = res.list.length;
            for (var i = 0; i < res.list.length; i++) {
              if (!res.list[i].fuel_list || res.list[i].fuel_list.length == 0) {
                this.totalSize--;
              }
              if (res.list[i].fuel_list && res.list[i].fuel_list.length > 0) {
                for (var j = 0; j < res.list[i].fuel_list.length; j++) {
                  if (res.list[i].fuel_list[j]) {
                    if (!res.list[i].fuel_list[j].is_ticked) {
                      this.flag = true;
                      break;
                    }
                  }
                }
                res.list[i].fuel_list.forEach((item) => {
                  if (item.is_ticked === 1) {
                    this.selectedFuel.push({
                      fuel_id: item.fuel_id,
                      vehicle_id: item.vehicle_id,
                    });
                  }
                });
              }
            }
            let uniqueVehicleIds = new Set();
            this.selectedFuel.forEach((item) => {
              uniqueVehicleIds.add(item.vehicle_id);
            });
            uniqueVehicleIds = Array.from(uniqueVehicleIds);
            this.uniqueVehicleSize = uniqueVehicleIds.length;
          }
          if (this.$refs.errorComponent) {
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching vehicle list:", error);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    storeVehicleAndFuelId(fuelId, vehicleId, isChecked) {
      if (isChecked) {
        this.selectedFuel.push({
          fuel_id: fuelId,
          vehicle_id: vehicleId,
        });
      } else {
        let indexToRemove = this.selectedFuel.findIndex(
          (item) => item.fuel_id === fuelId && item.vehicle_id === vehicleId
        );
        if (indexToRemove !== -1) {
          this.selectedFuel.splice(indexToRemove, 1);
        }
      }

      let uniqueVehicleIds = new Set();
      this.selectedFuel.forEach((item) => {
        uniqueVehicleIds.add(item.vehicle_id);
      });
      uniqueVehicleIds = Array.from(uniqueVehicleIds);
      this.uniqueVehicleSize = uniqueVehicleIds.length;
    },
    saveFuel() {
      this.$api
        .postAPI({
          _action: "/set-fuel/" + this.workshop_id,
          _buttonId: "save-fuel",
          _body: {
            list: this.selectedFuel,
          },
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getFuelList();
          if(this.page_type == "ONBOARDING" || this.page_type == "ADD_OUTLET" ){
            this.$router.push("/onboarding-steps");
          }else{
            this.$router.push("/settings");

          }
        })
        .catch((error) => {
          console.error(error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>
