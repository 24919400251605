<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div>
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">
                  Choose {{ this.vehicleTitle }} Manufacturers
                </h1>
                <div></div>
              </div>
              <div class="row mb-4 justify-content-center">
                <div class="col-lg-6 text-center mb-4">
            

                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      ><img
                        src="/static/images/search-icon-dark.svg"
                        
                        width="14"
                        alt=""
                        v-on:click="getManufactureList"
                    /></span>

                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by Manufacturer"
                      v-model="keyword"
                      @keydown.enter="getManufactureList"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes logos flex-wrap"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, index) in manufacturerList"
                        :key="index"
                      >
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="btn-check form-check-input"
                            :id="item.tittle"
                            autocomplete="off"
                            true-value="1"
                            false-value="0"
                            v-model="item.is_ticked"
                            disabled
                          />
                        </div>
                        <label
                          class="btn btn-outline-primary"
                          :for="item.tittle"
                          data-bs-target="#selectModal"
                          @click="handleRedirect(item.id)"
                        >
                          <div class="label">{{ item.title }}</div>
                          <img
                            :src="
                              item.icon
                                ? item.icon
                                : 'static/images/default.png'
                            "
                            class="icon"
                            width="110"
                            alt=""
                          />
                        </label>
                      </div>
                      <ErrorComponent ref="errorComponent" />
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    id="veh_id"
                    class="btn btn-primary px-5"
                    @click="handleContinue"
                    :disabled="isShowButton"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import "./onboarding.scss";
// import $ from "jquery";
export default {
  props: ["data"],
  name: "SetServiceManufactureCost",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshop_id: "",
      vehicle_id: this.$route.params.id,
      manufacturerList: [],
      selectedVehicle: null,
      vehicleList: [],
      keyword: "",
      vehicleTitle: "",
      isShowButton: false,
    };
  },

  mounted() {
    // this.vehicleObj = this.$route.params && JSON.stringify(this.$route.params);
    // this.vehicleTitle = this.$route.params.title;
    this.vehicleTitle = this.$sharedService.getMapValue("vehicleTitle");

    setTimeout(() => {
      this.updateButtonState();
    }, 2000);

    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    if (this.workshop_id  && this.vehicleTitle) {
      this.getManufactureList();
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },

    updateButtonState() {
      console.log("this.manufacturerList.length", this.manufacturerList);
      for (let i = 0; i < this.manufacturerList.length; i++) {
        if (this.manufacturerList[i].is_ticked === 1) {
          this.isShowButton = false;
        } else {
          this.isShowButton = true;
        }
      }
      console.log("this.is_show", this.isShowButton);
    },
    handleContinue() {
      this.$router.push("/set-service-cost");
    },
    handleRedirect(Manufacturer_id) {
      this.$router.push(
        "/vehicle-modal/" + this.vehicle_id + "/" + Manufacturer_id
      );
    },

    getManufactureList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action:
            "/my-manufacturer/" + this.vehicle_id + "/" + this.workshop_id,
          _body: {
            keyword: this.keyword,
          },
          _hide_loader: true,
        })
        .then((res) => {
          this.manufacturerList = res.list;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
              this.isShowNoData = true;
            }
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error(error);
        });
    },
  },
};
</script>
