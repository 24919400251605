<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a @click="$router.go(-1)" style="cursor: pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">
                  Choose {{ vehicleName }} Manufacturers
                </h1>
                <div></div>
              </div>
              <div class="row mb-4 justify-content-center">
                <div class="col-lg-6 text-center mb-4">
                  <div class="input-group mb-3">
                    <span
                      class="input-group-text"
                      id="inputGroup-sizing-default"
                      style="cursor: pointer"
                      ><img
                        src="static/images/search-icon-dark.svg"
                        width="14"
                        alt=""
                        v-on:click="getManufactureList"
                    /></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by Manufacturer"
                      v-model="keyword"
                      @keydown.enter="getManufactureList"
                    />
                  </div>
                </div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes logos flex-wrap"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, index) in manufacturerList"
                        :key="index"
                      >
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="btn-check form-check-input"
                            :id="'checkbox-' + index"
                            autocomplete="off"
                            v-model="item.is_ticked"
                            true-value="1"
                            false-value="0"
                            v-on:change="storeManufacturerId(item.id)"
                            disabled
                          />
                        </div>
                        <label
                          class="btn btn-outline-primary"
                          :style="
                            item.is_ticked ? { borderColor: '#004852' } : {}
                          "
                          for="bike"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#selectModal"
                          v-on:click="
                            getManufactureModelList(item.id, item.title)
                          "
                        >
                          <div class="label">{{ item.title }}</div>
                          <img
                            :src="
                              item.icon
                                ? item.icon
                                : 'static/images/default.png'
                            "
                            class="icon"
                            width="110"
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <ErrorComponent
                    @retry="getManufactureList()"
                    ref="errorComponent1"
                  />
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-primary px-5"
                    v-on:click="moreManufature"
                    :disabled="
                      !(
                        selectedManufacturerId &&
                        selectedManufacturerId.length > 0
                      )
                    "
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- select modal  -->
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="selectModal"
    aria-labelledby="selectModalLabel"
  >
    <div>
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="selectModalLabel">
          Select Vehicle Model of <span>{{ modelName }}</span>
        </h5>
        <button
          id="close-manufatcurer-model"
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div
        class="offcanvas-body"
        style="max-height: 600px; overflow-y: auto"
        v-if="manufacturerModelList && manufacturerModelList.length > 0"
      >
        <div class="form-check mb-2">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selectAllChecked"
            @change="selectAllChanged"
            id="SelectAll"
          />
          <label class="form-check-label" for="SelectAll">Select All</label>
        </div>
        <div
          class="form-check mb-2"
          v-for="(item, index) in manufacturerModelList"
          :key="index"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="item.is_ticked"
            true-value="1"
            false-value="0"
            :id="'vehicle-model-' + index"
            @change="storeManufacturerModelId(item.id)"
          />
          <label class="form-check-label" :for="'vehicle-model-' + index">
            {{ item.title }}
          </label>
        </div>
      </div>
      <ErrorComponent @retry="getManufactureModelList()" ref="errorComponent" />
    </div>

    <div class="offcanvace-footer p-3 d-flex gap-3 border-top">
      <button
        id="save-manufacturerModel"
        type="button"
        class="btn btn-primary w-100"
        v-on:click="saveManufacturerModel"
        :disabled="selectedManufacturerModelId.length == 0"
      >
        Save
      </button>
      <button
        type="button"
        class="btn btn-outline-primary w-100"
        v-on:click="clearAllManufacturerModel"
        :disabled="selectedManufacturerModelId.length == 0"
      >
        Clear All
      </button>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";

import "./onboarding.scss";
export default {
  name: "ChooseManufacture",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshop_id: "",
      manufacturerList: [],
      selectedManufacturerId: [],
      vehicleIdList: [],
      count: 0,
      keyword: "",
      manufacturerModelList: [],
      modelName: "",
      selectedManufacturerModelId: [],
      selectAllChecked: false,
      manufacturerId: "",
      list: [],
      vehicleName: "",
      vehicleList: [],
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    if (this.workshop_id) {
      this.getVehicleList();
    } else {
      this.$router.go(-1);
    }
  },

  methods: {
    storeManufacturerId(id) {
      if (!this.selectedManufacturerId.includes(id)) {
        this.selectedManufacturerId.push(id);
      } else {
        this.selectedManufacturerId = this.selectedManufacturerId.filter(
          (item) => item !== id
        );
      }
    },

    storeManufacturerModelId(id) {
      if (!this.selectedManufacturerModelId.includes(id)) {
        this.selectedManufacturerModelId.push(id);
      } else {
        this.selectedManufacturerModelId =
          this.selectedManufacturerModelId.filter((item) => item !== id);
      }
      if (
        this.selectedManufacturerModelId.length ==
        this.manufacturerModelList.length
      ) {
        this.selectAllChecked = true;
      } else {
        this.selectAllChecked = false;
      }
    },

    selectAllChanged() {
      if (this.selectAllChecked) {
        this.manufacturerModelList.forEach((item) => {
          if (!this.selectedManufacturerModelId.includes(item.id)) {
            this.selectedManufacturerModelId.push(item.id);
          }
          item.is_ticked = 1;
        });
      } else {
        this.selectedManufacturerModelId = [];
        this.manufacturerModelList.forEach((item) => {
          item.is_ticked = 0;
        });
      }
    },

    moreManufature() {
      this.count++;
      this.keyword = "";
      this.getManufactureList();
    },

    getManufactureList() {
      this.manufacturerList = [];
      if (this.$refs.errorComponent1) {
        this.$refs.errorComponent1.showLoader(true);
      }
      if (
        this.vehicleIdList &&
        this.vehicleIdList.length > 0 &&
        this.count < this.vehicleIdList.length
      ) {
        this.vehicleName = this.vehicleList[this.count].title;
        this.$api
          .getAPI({
            _action:
              "/get-manufacturer/" +
              this.vehicleIdList[this.count] +
              "/" +
              this.workshop_id,
            _body: {
              keyword: this.keyword,
            },
            _hide_loader: true,
          })
          .then((res) => {
            this.selectedManufacturerId = [];
            if (res && res.list && res.list.length > 0) {
              this.manufacturerList = res.list;
              this.selectedManufacturerId = res.list
                .filter((item) => item.is_ticked === 1)
                .map((item) => item.id);

              for (var i = 0; i < res.list.length; i++) {
                if (
                  res.list[i].manufacturer_model_list &&
                  res.list[i].manufacturer_model_list.length > 0
                ) {
                  this.list = [...res.list[i].manufacturer_model_list];
                }
              }
            }
            if (this.$refs.errorComponent1) {
              if (!res || res.list.length == 0) {
                this.$refs.errorComponent1.setTitle("No Data Available");
              }
              this.$refs.errorComponent1.showLoader(false);
            }
          })
          .catch((error) => {
            if (this.$refs.errorComponent1) {
              this.$refs.errorComponent1.showLoader(false);
              this.$refs.errorComponent1.setTitle("");
            }
            console.error(error);
          });
      } else {
        this.$router.push("/select-fuel-type");
      }
    },

    getVehicleList() {
      this.$api
        .getAPI({
          _action: "/my-vehicle/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            this.vehicleIdList = res.list.map((vehicle) => vehicle.id);
            this.vehicleList = res.list;
          }
          this.getManufactureList();
        })
        .catch((error) => {
          console.error("Error fetching vehicle list:", error);
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    getManufactureModelList(id, modelName) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.manufacturerId = id;
      this.manufacturerModelList = [];
      this.selectAllChecked = false;
      if (id && this.vehicleIdList && this.vehicleIdList.length > 0) {
        this.modelName = modelName;
        this.$api
          .getAPI({
            _action:
              "/get-manufacturer-model/" +
              this.vehicleIdList[this.count] +
              "/" +
              id +
              "/" +
              this.workshop_id,
            _body: {},
            _hide_loader: true,
          })
          .then((res) => {
            if (this.$refs.errorComponent) {
              if (!res || res.list.length == 0) {
                this.$refs.errorComponent.setTitle("No Data Available");
              }
            }
            this.selectedManufacturerModelId = [];
            if (res && res.list && res.list.length > 0) {
              this.manufacturerModelList = res.list;
              this.selectedManufacturerModelId = res.list
                .filter((item) => item.is_ticked === 1)
                .map((item) => item.id);
              if (
                this.manufacturerModelList.length ==
                this.selectedManufacturerModelId.length
              ) {
                this.selectAllChecked = true;
              } else {
                this.selectAllChecked = false;
              }
            }
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          })
          .catch((error) => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
              this.$refs.errorComponent.setTitle("");
            }
            console.error("Error fetching vehicle list:", error);
          });
      }
    },

    clearAllManufacturerModel() {
      this.selectAllChecked = false;
      this.selectedManufacturerModelId = [];
      this.manufacturerModelList.forEach((item) => {
        item.is_ticked = 0;
      });
    },

    saveManufacturerModel() {
      if (
        this.vehicleIdList &&
        this.vehicleIdList.length > 0 &&
        this.selectedManufacturerModelId &&
        this.selectedManufacturerModelId.length > 0
      ) {
        this.$api
          .postAPI({
            _action:
              "/set-manufacturer-model/" +
              this.vehicleIdList[this.count] +
              "/" +
              this.manufacturerId +
              "/" +
              this.workshop_id,
            _buttonId: "save-manufacturerModel",
            _body: {
              list: this.selectedManufacturerModelId,
            },
          })
          .then((res) => {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            const closebutton = document.getElementById(
              "close-manufatcurer-model"
            );
            setTimeout(() => closebutton.click(), 3000);
            this.getManufactureList();
            this.selectedManufacturerModelId = [];
          })
          .catch((error) => {
            console.error(error);
            this.$toast.open({
              message: "Error",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          });
      }
    },
  },
};
</script>
