<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-10">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4"
            >
              <a @click="$router.go(-1)" class="cursor-pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-4">Rate Card</h1>
              <a href="javascript:void(0)" v-on:click="back()" class="text-brand">Skip</a>
            </div>

            <div class="row mb-4">
              <div class="col-12">
                <p class="font-18 font-medium">
                  Workshop Photos
                  <span class="font-12 text-secondary">(Maximum 10 Photos)</span>
                </p>
              </div>

              <div class="col-lg-4 mb-3" v-for="(res, index) in images" :key="index">
                <div class="uploaded-photo" v-if="res.image">
                  <img
                    :src="res.image"
                    class="image"
                    height="180"
                    alt=""
                    style="height: 180px"
                  />
                  <a @click="deletePhoto(res.id, index)" class="close-btn cursor-pointer"
                    ><img
                      src="static/images/close-icon.svg"
                      class="close-icon"
                      width="20"
                      alt=""
                  /></a>
                </div>

                <div class="upload-btn-container" v-else>
                  <div>
                    <input
                      type="file"
                      id="photo-file-upload"
                      accept="image/*"
                      @change="photoUpload($event, index)"
                    />
                    <label for="photo-file-upload h-auto">
                      <div class="py-4 text-center">
                        <div class="mb-3">
                          <img
                            src="static/images/icon-file.svg"
                            width="20"
                            class="mx-auto"
                            alt=""
                          />
                        </div>
                        <div class="mb-3 text-secondary">Select document to upload</div>
                        <div>
                          <button class="btn btn-primary">Upload File</button>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="this.images.length < 10" class="text-center mt-4 mb-4">
              <button class="btn btn-outline-primary px-5" @click="addPhotoUploadField">
                <img
                  src="static/images/icon-camera.svg"
                  width="20"
                  class="align-text-top me-2"
                  alt=""
                />
                Add More Photos
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="col-12 text-center border-top pt-3 mb-5">
      <button
        v-on:click="handleContinue"
        id="continue-btn"
        class="btn btn-primary px-5"
        native-type="button"
      >
        Continue
      </button>
    </div>
    <ErrorComponent ref="errorComponent" />
  </div>
</template>

<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "RateCard",
  components: {
    ErrorComponent,
  },
  data() {
    return {
      id: "",
      list: [],
      images: [],
      photoUploadFields: [],
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getPhotoList(this.id);
    } else {
      this.$router.push("/choose-outlet");
    }
  },
  methods: {
    getPhotoList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-ratecard/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          if (res.list && res.list.length <= 0) {
            this.addDefaultPhotoUploadFields(6);
          } else {
            this.images = res.list;
            if (res.list.length <= 6)
              this.addDefaultPhotoUploadFields(6 - res.list.length);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    addDefaultPhotoUploadFields(count) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(false);
      }

      for (let i = 0; i < count; i++) {
        this.addPhotoUploadField();
      }
    },

    addPhotoUploadField() {
      this.images.push({});
    },

    photoUpload(event, index) {
      this.isloading = true;
      const file = event.target.files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      // Validate file type
      if (!validTypes.includes(file.type)) {
        alert("Only jpg, png, and jpeg files are allowed.");
        this.isloading = false;
        return;
      }

      const blob = URL.createObjectURL(file);
      for (let i in this.images) {
        if (index == i) {
          this.images[i].image = blob;
          this.images[i].file = file;
        }
      }
      console.log("1122", this.images);
      this.isloading = false; // Reset loading state after processing
    },
    back() {
      if (this.page_type == "EDIT") {
        this.$router.push("/settings");
      } else {
        this.$router.push("/onboarding-steps");
      }
    },
    handleContinue() {
      if (this.images.length > 0) {
        this.handleImagePost(0);
      } else {
        if (this.page_type == "EDIT") {
          this.$router.push("/settings");
        } else {
          this.$router.push("/onboarding-steps");
        }
      }
    },

    handleImagePost(index) {
      if (index === undefined || index === null) {
        return; // Return if index is undefined or null
      }

      if (index >= 0 && index < this.images.length) {
        console.log("123", this.images, this.images[index].id);

        if (!this.images[index].id && this.images[index].file) {
          var obj = {
            image: this.images[index].file,
            type: "OTHER",
          };

          this.$api
            .uploadImageAPI({
              _action: "/upload-ratecard/" + this.id,
              _body: obj,
              _buttonId: "continue-btn",
            })
            .then(() => {
              this.images[index].file = null;
              if (index + 1 < this.images.length) {
                this.handleImagePost(index + 1);
              } else {
                if (this.page_type == "EDIT") {
                  this.$router.push("/settings");
                } else {
                  this.$router.push("/onboarding-steps");
                }
              }
            })
            .catch((error) => {
              console.error("Error uploading image", error);
            });
        } else {
          if (index + 1 < this.images.length) {
            this.handleImagePost(index + 1);
          } else {
            if (this.page_type == "EDIT") {
              this.$router.push("/settings");
            } else {
              this.$router.push("/onboarding-steps");
            }
          }
        }
      }
    },
    deletePhoto(id, index) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      if (!id) {
        this.images.splice(index, 1);
        if (this.$refs.errorComponent) {
          this.$refs.errorComponent.showLoader(false);
        }
      } else {
        this.$api
          .deleteAPI({
            _action: "delete-ratecard/" + id,

            // _buttonId: "delete-video" + del_ID,
          })
          .then(() => {
            this.images.splice(index, 1);
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          })
          .catch(() => {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.showLoader(false);
            }
          });
      }
    },
  },
};
</script>

