<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7 text-center">
          <a @click="$router.go(-1)" class="float-start">
            <svg
              width="12"
              height="16"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                stroke="#001D21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <h1 class="page-title">
            {{
              this.type == "CLOSE_ACCOUNT"
                ? " Close Account"
                : this.type == "ACTIVE"
                ? "Deactivate Workshop"
                : "Activate Workshop"
            }}
          </h1>
        </div>
      </div>
      <div>
        <div class="row justify-content-center">
          <div class="col-lg-5 mb-3 text-center">
            <p class="text-secondary my-5">
              {{
                this.type == "CLOSE_ACCOUNT"
                  ? "If the account is closed, you will not receive any more orders from the customer and the account will be deactivated"
                  : "If the Worksop is closed, you will not receive any more orders from the customer and the account will be deactivated"
              }}
            </p>

            <div
              class="d-flex justify-content-between mb-4 d-flex gap-3 form-style"
            >
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input form-control text-center"
                separator="&nbsp;&nbsp;&nbsp;&nbsp;"
                inputType="text"
                :num-inputs="4"
                v-model:value="code"
                :should-auto-focus="true"
                :should-focus-order="true"
                @on-change="handleOnChange"
              />
            </div>
            <button
              class="btn btn-outline-danger px-5"
              id="verrify_btn"
              @click="setOtp"
              :disabled="code.length < 4"
            >
              Confirm and
              {{
                this.type == "CLOSE_ACCOUNT"
                  ? " Close Account "
                  : "Deactive Workshop"
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Alert  -->
  <div
    class="modal fade"
    id="AlertrModal"
    tabindex="-1"
    aria-labelledby="AlertrModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <img src="static/images/success-icon.svg" alt="" />
            <p>
              We have received your closure request, we will get back to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
import VOtpInput from "vue3-otp-input";

export default {
  name: "CloseAccountOTP",
  components: {
    VOtpInput,
  },

  data() {
    return {
      code: "",
      type: "",
      meData: "",
      ActivationWorskShopId: "",
    };
  },

  mounted() {
    this.meData = JSON.parse(localStorage.getItem("userDetail"));
    if (
      !this.meData ||
      !this.$sharedService.getMapValue("close-account-type")
    ) {
      this.$router.go(-1);
    }

    if (this.$sharedService.getMapValue("close-account-type") == "INACTIVE") {
      this.type = "INACTIVE";
      this.ActivationWorskShopId = this.$sharedService.getMapValue(
        "Deactivate_Workshop_Id"
      );
    } else if (
      this.$sharedService.getMapValue("close-account-type") == "CLOSE_ACCOUNT"
    ) {
      this.type = "CLOSE_ACCOUNT";
    } else if (
      this.$sharedService.getMapValue("close-account-type") == "ACTIVE"
    ) {
      this.type = "ACTIVE";
      this.ActivationWorskShopId = this.$sharedService.getMapValue(
        "activate_Workshop_Id"
      );
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
    handleOnChange(value) {
      this.code = value;
    },
    setOtp() {
      const obj = {
        otp: this.code,
        type: this.type,
        mobile_number: this.meData.mobile_number,
        workshop_id: this.ActivationWorskShopId,
      };

      let actionUrl = "verify-otp";

      this.$api
        .postAPI({
          _action: actionUrl,
          _body: obj,
          _buttonId: "verrify_btn",
        })
        .then(() => {
          if (this.type == "CLOSE_ACCOUNT") {
            localStorage.clear();
            this.$sharedService.clearMap("close-account-type");
            this.$router.push("/login");
          } else {
            this.$router.push("/settings");
          }
        })
        .catch(() => {});
    },
  },
};
</script>
