<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <a href="javascript:void(0)" v-on:click="this.$router.push('/orders')" class=" ">
          <svg
            width="12"
            height="16"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
              stroke="#001D21"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
        <div class="col-lg-7 text-center">
          <h1 class="page-title">Quotation Detail</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="card order-card cursor-pointer mb-4">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="font-14 text-secondary">
                    Quotation ID: {{ quoteData.quotation_no }}
                  </div>
                </div>
                <div class="col-lg-6 text-end"></div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4">
                  <div class="border p-2 rounded text-center mb-2">
                    <img
                      class="img-fluid mb-2"
                      :src="
                        quoteData?.uservehicle?.manufacturer_model?.icon ||
                        '/static/images/default.png'
                      "
                    />

                    <p class="mb-0">
                      {{ quoteData?.uservehicle?.manufacturer_model?.title }}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="p-2 bg-secondary-light d-inline-block rounded px-3 mb-2">
                    MH12RS3134
                  </div>
                  <div class="mb-2">
                    <span class="text-secondary">Fuel type:</span
                    ><span class="ms-2">{{ quoteData?.uservehicle?.fuel?.title }}</span>
                  </div>
                  <div class="mb-2">
                    <span class="text-secondary">Transmission type:</span
                    ><span class="ms-2">{{
                      quoteData?.uservehicle?.transmission?.title
                    }}</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="mb-2">
                    <span class="text-secondary">Quotation:</span
                    ><span class="ms-2">{{ quoteData.category.title }} </span>
                  </div>
                  <div class="mb-2">
                    <span class="text-secondary">Requested Brand:</span
                    ><span class="ms-2">
                      {{ quoteData?.brands?.map((obj) => obj?.brand?.title).join(", ") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h4 class="font-20 font-bold mb-4">Selected Brand</h4>
          <Form @submit="handleContinue">
            <div class="row row-cols-lg-4">
              <div class="col-lg-4" v-for="(item, i) in this.selectedBrands" :key="i">
                <div class="card logo-card mb-3">
                  <a
                    v-if="status && status === 'PENDING'"
                    class="close-icon"
                    v-on:click="removeBrand(item)"
                    ><i class="bi bi-x-circle-fill cursor-pointer"></i
                  ></a>
                  <div class="card-body">
                    <div class="font-14 text-secondary text-center mb-3">
                      {{ item.title }}
                    </div>
                    <img
                      :src="item?.icon ? item?.icon : '/static/images/default.png'"
                      class="logo"
                      alt=""
                    />
                  </div>
                  <div class="card-footer" v-if="status && status == `PENDING`">
                    <Field
                      type="text"
                      class="form-control"
                      :name="`price_${i}`"
                      min="1"
                      rules="required:price|numeric"
                      v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                      :validateOnInput="true"
                      v-model="item.price"
                    />
                    <ErrorMessage :name="`price_${i}`" class="validation-msg mt-1" />
                  </div>
                  <div class="card-footer" v-else>₹ {{ item.price }}</div>
                </div>
              </div>
              <button id="form_hidden_button" type="submit" hidden>send</button>
            </div>
          </Form>

          <hr class="my-4" />
          <h4 class="font-20 font-bold mb-4">Select Brand to Send Quote</h4>

          <div class="row mb-4 justify-content-center">
            <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
              <div class="mb-4">
                <div
                  class="btn-group select-vehicle-boxes logos flex-wrap"
                  role="group"
                  aria-label="Basic checkbox toggle button group"
                >
                  <div
                    class="position-relative"
                    v-for="(item, index) in this.brandList"
                    :key="index"
                  >
                    <div class="form-check" v-if="status && status === 'PENDING'">
                      <input
                        type="checkbox"
                        class="btn-check form-check-input"
                        :id="`checkbox_${index}`"
                        autocomplete="off"
                        true-value="1"
                        false-value="0"
                        v-model="item.is_ticked"
                        @change="handleSelection(item)"
                      />
                    </div>
                    <label class="btn btn-outline-primary" :for="`checkbox_${index}`">
                      <div class="label">{{ item.title }}</div>
                      <img :src="item.icon" class="icon" width="110" alt="" />
                    </label>
                  </div>
                </div>
              </div>
              <!--v-if-->
              <div style="display: none">
                <div class="loader-container">
                  <div class="spinner-border" role="status"></div>
                </div>
                <div class="loader-text">Loading...</div>
              </div>
            </div>
            <div class="text-center">
              <!-- <button v-on:click="handleContinue" class="btn btn-primary px-5">
                Continue
              </button> -->

              <div class="card-footer d-flex gap-3">
                <button
                  v-if="status && status == 'PENDING'"
                  class="btn btn-outline-danger bg-danger-subtle rounded-3 w-100"
                  style="padding: 10px 20px"
                  @click="this.$router.go(-1)"
                >
                  Cancel
                </button>
                <button
                  v-if="status && status == 'PENDING'"
                  class="btn btn-primary w-100"
                  id="send_quote"
                  @click="onSend"
                >
                  Send Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent ref="errorComponent" />
  </div>
</template>
<style>
.logo-card {
  border-radius: 12px;
  position: relative;
}
.logo-card .logo {
  width: 100%;
  height: 40px;
  object-fit: contain;
}
.logo-card .card-footer {
  text-align: center;
  font-size: 18px;
}
.logo-card .close-icon {
  position: absolute;
  right: -4px;
  top: -13px;
  font-size: 18px;
  color: #f10000;
}
</style>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "QuotationDetails",
  components: {
    ErrorComponent,
    Field,
    ErrorMessage,
    Form,
  },

  data() {
    return {
      quoteData: { category: {} },
      brandList: [],
      id: this.$route.params.id,
      selectedBrands: [],
      workshop_id: localStorage.getItem("workshop_id"),
      status: "",
    };
  },

  mounted() {
    localStorage.setItem("page_type", "HOME");
    this.getQuotationDetail();
    this.$store.commit("setPageType", "HOME");
  },
  methods: {
    onSend() {
      let btn = document.getElementById("form_hidden_button");
      if (btn) {
        btn?.click();
      }
    },
    getQuotationDetail() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `quotation/detail/${this.id}/${this.workshop_id}`,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          if (res && res.info) {
            this.quoteData = res.info;
            this.status = res.info.status;
            if (res.info.list) {
              for (var i = 0; i < res.info.list.length; i++) {
                if (res.info.list[i].brand) {
                  res.info.list[i].icon = res.info.list[i].brand.icon;
                }
              }
            }
          }
          if (res.info.category.brand_type) {
            this.getBrandList(res.info.category.brand_type);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    getBrandList(type) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `my-brand/${type}/${this.workshop_id}`,
        })
        .then((res) => {
          this.brandList = res.list;
          if (this.quoteData.list.length > 0) {
            for (var i = 0; i < this.brandList.length; i++) {
              for (var j = 0; j < this.quoteData.list.length; j++) {
                if (this.brandList[i].id == this.quoteData.list[j].brand_id) {
                  this.selectedBrands.push(this.quoteData.list[j]);
                  this.quoteData.list[j].is_ticked = "1";
                }
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }

          console.log("brandList", this.brandList);
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    handleSelection(item) {
      if (item.is_ticked == "1") {
        if (this.selectedBrands.length == 3) {
          for (var j in this.brandList) {
            if (this.brandList[j].id == item.id) {
              this.brandList[j].is_ticked = "0";
            }
          }
          this.$notify({
            type: "error",
            text: "you cannot add more then 3 brand.",
          });
          return;
        }

        item.price = "";
        this.selectedBrands.push(item);
      } else {
        for (var i in this.selectedBrands) {
          if (this.selectedBrands[i].id == item.id) {
            this.selectedBrands.splice(i, 1);
          }
        }
      }

      console.log("selectedBrands", this.selectedBrands);
    },

    removeBrand(item) {
      this.selectedBrands = this.selectedBrands.filter((brand) => brand.id !== item.id);
      const brand = this.brandList.find((brand) => brand.id === item.id);
      if (brand) {
        brand.is_ticked = "0";
      }
    },

    handleContinue() {
      const selectedBrandsWithPrice = this.selectedBrands.map((item) => ({
        id: item.id,
        price: item.price,
      }));
      const obj = {
        quotation_id: this.id,
        list: selectedBrandsWithPrice,
      };
      this.$api
        .postAPI({
          _action: `send-quote`,
          _body: obj,
          _buttonId: "send_quote",
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
