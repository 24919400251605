<template>
  <div>
    <div v-for="message in messages" :key="message.id">
      <p>{{ message.sender }}: {{ message.text }}</p>
    </div>
    <input v-model="newMessage" placeholder="Type your message" />
    <button @click="sendMessage">Send</button>
  </div>
</template>

<script>
import Pusher from "pusher-js";
export default {
  data() {
    return {
      messages: [],
      newMessage: "",
      currentUserId: 743,
      recipientId: 664,
    };
  },
  mounted() {
    Pusher.logToConsole = true;
    console.log(`Mounting testVendor for Vendor ID: ${this.recipientId}`);
    this.listenForMessages();
  },
  methods: {
    listenForMessages() {
      console.log(`Mounting testVendorChat for User ID: ${this.currentUserId}`);
      const pusher = new Pusher("3a4267a10a17088f4c83", {
        cluster: "ap2",
        encrypted: false,
      });

      const channel = pusher.subscribe(`chat.user.${this.currentUserId}`);
      channel.bind("App\\Events\\MessageSent", (data) => {
        console.log("Message received in testVendor:", data);
        this.messages.push({
          id: data.id,
          sender: data.fromVendor ? data.fromVendor.name : "Unknown",
          text: data.message,
        });
      });

      channel.bind("pusher:subscription_succeeded", () => {
        console.log("Successfully subscribed to the channel");
      });
    },
    async sendMessage() {
      this.$api
        .postAPI({
          _action: "send-message",
          _body: {
            message: this.newMessage,
            from_user_id: this.currentUserId,
            to_vendor_id: this.recipientId,
          },
        })
        .then(() => {})
        .catch(() => {});

      this.newMessage = "";
    },
  },
};
</script>

