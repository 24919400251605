<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a @click="$router.go(-1)"  class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Select Vehicle Type</h1>
                <div></div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-12 text-center">
                  <p class="text-secondary">
                    Select the vehicle for which you offer services
                  </p>
                  <p class="font-18 font-medium">Private Vehicle</p>
                </div>

                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, index) in vehicleList"
                        :key="index"
                      >
                        <div v-if="item.type == `PRIVATE`">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="btn-check form-check-input"
                              :id="`PRIVATE_` + index"
                              v-on:change="handlecheckbox(item.id)"
                              autocomplete="off"
                              true-value="1"
                              false-value="0"
                              v-model="item.is_ticked"
                            />
                          </div>
                          <label class="btn btn-outline-primary" for="bike">
                            <div class="label">{{ item.title }}</div>
                            <img
                              :src="
                                item.icon
                                  ? item.icon
                                  : 'static/images/default.png'
                              "
                              class="icon"
                              width="100"
                              heigth="100"
                              alt=""
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 text-center border-top pt-3 mb-3">
                  <p class="font-18 font-medium">Commercial Vehicle</p>
                </div>
                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative"
                        v-for="(item, index) in vehicleList"
                        :key="index"
                      >
                        <div v-if="item.type == `COMMERCIAL`">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="btn-check form-check-input"
                              :id="`COMMERCIAL` + index"
                              autocomplete="off"
                              v-on:change="handlecheckbox(item.id)"
                              v-model="item.is_ticked"
                              true-value="1"
                              false-value="0"
                            />
                          </div>

                          <label
                            class="btn btn-outline-primary"
                            for="3-Wheeler"
                          >
                            <div class="label">{{ item.title }}</div>
                            <img :src="item.icon" class="icon" alt="" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    type="button"
                    id="veh_id"
                    class="btn btn-primary px-5"
                    :disabled="
                      !(selectedVehicleID && selectedVehicleID.length > 0)
                    "
                    v-on:click="handleSubmit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getWorkshopDetails()" ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import $ from "jquery";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "SelectVehicle",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: localStorage.getItem("workshop_id"),
      outlet_workshop_id: localStorage.getItem("outlet_workshop_id"),
      page_type: localStorage.getItem("page_type"),
      vehicleList: [],
      selectedVehicleID: [],
      is_checked: false,
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET" && this.outlet_workshop_id) {
      this.id = this.outlet_workshop_id;
      this.getvehiclelist();
    } else {
      if (this.id) {
        this.getvehiclelist();
      } else {
        this.$router.push("/onboarding-steps");
      }
    }

    if (this.id) {
      this.getvehiclelist();
    } else {
      this.$router.push("/onboarding-steps");
    }

    $(".btn-check").change(function () {
      $(this).closest(".form-check").toggleClass("selected", this.is_checked);
    });
  },
  methods: {
    getvehiclelist() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `get-vehicle/` + this.id,
        })
        .then((res) => {
          if (res && res.list) {
            this.vehicleList = res.list;
            if (res.list.length > 0) {
              this.selectedVehicleID = [];
              for (let i = 0; i < res.list.length; i++) {
                if (res.list[i].is_ticked && res.list[i].is_ticked) {
                  this.selectedVehicleID.push(res.list[i].id);
                }
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
          console.error(error);
        });
    },

    handlecheckbox(id) {
      if (!this.selectedVehicleID.includes(id)) {
        this.selectedVehicleID.push(id);
      } else {
        this.selectedVehicleID = this.selectedVehicleID.filter(
          (item) => item !== id
        );
      }
      console.log("this.selectedVehicleID---------", this.selectedVehicleID);
    },
    handleSubmit() {
      if (!this.selectedVehicleID || this.selectedVehicleID.length == 0) {
        this.$refs.errorComponent.showLoader(false);
        return;
      }
      const obj = {
        list: this.selectedVehicleID,
      };
      this.$api
        .postAPI({
          _action: `set-vehicle/` + this.id,
          _body: obj,
          _buttonId: "veh_id",
        })
        .then(() => {
          this.$router.push("/choose-manufacture");
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>
<!-- href="/choose-manufacture" -->
