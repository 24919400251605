<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-1">
          <a class="float-start mt-1 cursor-pointer" v-on:click="this.$router.go(-1)">
            <svg
              width="12"
              height="16"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                stroke="#001D21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div class="col-lg-7 text-center">
          <h1 class="page-title">Message</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card chat-card">
            <div
              class="card-header d-flex justify-content-between gap-3 align-items-center"
            >
              <div>
                <p class="font-14 mb-0 font-medium">
                  {{ messageList[0]?.user?.name }}
                </p>
                <div class="font-12 text-secondary"></div>
              </div>
            </div>
            <div
              class="card-body"
              @scroll="onScrollMessagesList"
              :style="{ maxHeight: '350px', overflowY: 'scroll' }"
            >
              <div v-for="(item, index) in messageList" :key="index">
                <div class="msg-body received" v-if="item.send_by == `USER`">
                  <div
                    class="col-lg-7 col-10"
                    style="width: auto !important ; max-width: 500px"
                  >
                    <div
                      class="msg-box"
                      v-if="item.message_type == `TEXT`"
                      :id="'chat_' + item.id"
                    >
                      {{ item.message }}
                    </div>
                    <div
                      class="msg-box cursor-pointer"
                      v-if="item.message_type == `DOCUMENT`"
                      @click="handleAttachmentDownload(item.doc_url)"
                      :id="'chat_' + item.id"
                    >
                      <img
                        :src="
                          item.doc_url
                            ? item.doc_url
                            : `/static/images/DefaultDocImage.png`
                        "
                        alt=""
                        :style="{
                          height: '100px',
                          width: '250px',
                          objectFit: 'cover',
                        }"
                      />
                    </div>
                    <div class="time">
                      {{
                        this.$helperService.getOrderDetailFormattedDate(item.created_at)
                      }}
                    </div>
                  </div>
                </div>

                <div class="msg-body sent" v-else>
                  <div
                    class="col-lg-7 col-10"
                    style="width: auto !important; max-width: 500px"
                  >
                    <div
                      class="msg-box"
                      v-if="item.message_type == `TEXT`"
                      :id="'chat_' + item.id"
                    >
                      {{ item.message }}
                    </div>
                    <div
                      class="msg-box cursor-pointer"
                      v-if="item.message_type == `DOCUMENT`"
                      @click="handleAttachmentDownload(item.doc_url)"
                      :id="'chat_' + item.id"
                    >
                      <img
                        :src="
                          item.doc_url
                            ? item.doc_url
                            : `/static/images/DefaultDocImage.png`
                        "
                        alt=""
                        :style="{
                          height: '100px',
                          width: '250px',
                          objectFit: 'cover',
                        }"
                      />
                    </div>
                    <div class="time">
                      {{
                        this.$helperService.getOrderDetailFormattedDate(item.created_at)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ms-4" v-if="image">
              <img
                :src="image ? image : `/static/images/DefaultDocImage.png`"
                :style="{ height: '80px', width: '100px' }"
              />
            </div>
            <div class="card-footer">
              <div class="type-msg">
                <div class="d-flex w-100 align-items-center input-wrap">
                  <Field
                    type="text"
                    id="message"
                    class="form-control"
                    name="message"
                    placeholder="Message"
                    v-model="message"
                    :validateOnInput="true"
                    @keydown.enter="sendMessage"
                  />

                  <div class="">
                    <div class="attach-btn">
                      <i class="bi bi-paperclip"></i>
                      <input
                        type="file"
                        id="tb-file-upload"
                        @change="onValidateAttachment"
                      />
                    </div>
                  </div>
                </div>
                <button class="btn btn-send-msg" v-on:click="sendMessage" id="send_msg">
                  <i class="bi bi-send"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent ref="errorComponent" />
  </div>
</template>
<script>
import { Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent.vue";
import Pusher from "pusher-js";

export default {
  name: "ChatPage",
  components: {
    Field,
    ErrorComponent,
  },
  data() {
    return {
      conversation_id: this.$route.params.id,
      messageList: [],
      meData: JSON.parse(localStorage.getItem("userDetail")),
      message: null,
      attachmentFile: null,
      workshop_id: localStorage.getItem("workshop_id"),
      image: null,
      isLoadingList: false,
      scrollHeight: 0,
      isGettingMessageData: false,
      messageNextOffset: 0,
      offset: 0,
      order_id: this.$sharedService.getMapValue("orderId") || "",
      time: null,
    };
  },

  mounted() {
    Pusher.logToConsole = true;
    this.listenForMessages();

    if (this.conversation_id) {
      this.getMessageList(0);
    } else if (!this.order_id) {
      this.$router.go(-1);
    }

    this.$sharedService.clearMap("orderId");
  },

  methods: {
    listenForMessages() {
      const pusher = new Pusher("3a4267a10a17088f4c83", {
        cluster: "ap2",
        encrypted: false,
      });

      const userChannel = pusher.subscribe(`chat.user.${this.conversation_id}`);
      const vendorChannel = pusher.subscribe(`chat.vendor.${this.conversation_id}`);

      userChannel.bind("App\\Events\\MessageSent", this.handleIncomingMessage);
      vendorChannel.bind("App\\Events\\MessageSent", this.handleIncomingMessage);

      userChannel.bind("pusher:subscription_succeeded", () => {
        console.log("Successfully subscribed to the user channel");
      });
    },

    handleIncomingMessage(data) {
      this.messageList.push(data.chat);
      this.message = null;
      this.attachmentFile = null;
      this.image = null;
      this.messageList.sort((a, b) => a.id - b.id);

      const elementID = `chat_${this.messageList[this.messageList.length - 1].id}`;
      setTimeout(() => {
        document.getElementById(elementID)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.isLoadingList = false;
      }, 200);
    },

    getMessageList(offset) {
      this.isLoadingList = true;

      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }

      this.$api
        .getAPI({
          _action: `message-list/${this.conversation_id}`,
          _body: { offset },
        })
        .then((res) => {
          this.offset = offset;
          this.time = res.time;
          this.messageList = offset === 0 ? res.list : [...this.messageList, ...res.list];
          this.messageList.sort((a, b) => a.id - b.id);

          const elementID = `chat_${this.messageList[this.messageList.length - 1].id}`;
          setTimeout(() => {
            document.getElementById(elementID)?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            this.isLoadingList = false;
          }, 200);

          this.messageNextOffset = res.next_offset;

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          this.isLoadingList = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    onScrollMessagesList(e) {
      if (this.messageNextOffset > 0 && e.target.scrollTop < 100 && !this.isLoadingList) {
        this.scrollHeight = e.target.scrollHeight;
        this.getMessageList(this.messageNextOffset);
      }
    },

    onValidateAttachment(event) {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 4 * 1024 * 1024;

      if (file) {
        if (!allowedTypes.includes(file.type)) {
          this.$notify({
            type: "error",
            text: "Only JPEG, PNG files are allowed.",
          });
          this.attachmentFile = null;
        } else if (file.size > maxSize) {
          this.$toast.open({
            message: "File size must be less than 4 MB!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.attachmentFile = null;
        } else {
          this.attachmentFile = file;
          this.image = URL.createObjectURL(file);
        }
      }
    },

    handleAttachmentDownload(url) {
      window.open(url, "_self");
    },

    sendMessage() {
      if (!this.message && !this.attachmentFile) return;

      const obj = {
        conversation_id: this.conversation_id,
        message: this.message,
        file: this.attachmentFile,
        order_id: this.order_id || undefined,
      };

      if (this.message) {
        this.$api
          .postAPI({
            _action: `send-message/${this.workshop_id}`,
            _body: obj,
          })
          .then(() => {})
          .catch(() => {});
      } else if (this.attachmentFile || (this.attachmentFile && this.image)) {
        this.$api
          .uploadImageAPI({
            _action: `send-message/${this.workshop_id}`,
            _body: obj,
            _key: "file",
          })
          .then(() => {})
          .catch(() => {});
      }
    },
  },
};
</script>
