<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Periodic Service Details</h1>
                <div></div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-8 mb-4">
                  <p class="text-secondary">
                    Please check below link or PDF of Standard Periodic Services
                    Information
                  </p>
                  <!-- <p class=" ">
                    Link: <a href="">www.google.com/PeriodicServiceInfo</a>
                  </p> -->
                </div>

                <div
                  class="col-lg-8 mb-4"
                  v-for="(item, index) in detailList"
                  :key="index"
                >
                  <p class="font-18 font-medium">
                    For {{ item.vehicle.title }}
                  </p>
                  <div class="card p-3 rounded-4 bg-secondary-light">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="d-flex gap-3 n">
                        <img
                          src="static/images/icon-file-color.svg"
                          width="20"
                          height="20"
                        />
                        <span
                          >{{ this.workshop_name }} periodic service
                          details</span
                        >
                      </div>
                      <a class="cursor-pointer"
                        ><img
                          src="static/images/icon-download.svg"
                          width="22"
                          height="22"
                          v-on:click="handlePdfDownload(item.pdf)"
                      /></a>
                    </div>
                  </div>
                </div>

                <div class="text-center mt-5">
                  <router-link
                    to="/set-service-cost"
                    class="btn btn-primary px-5"
                    >Set Service Cost</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "ServiceDetails",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      detailList: [],
      workshop_name: localStorage.getItem("workshop_name"),
      page_type: localStorage.getItem("page_type"),
      workshop_id: "",
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.workshop_id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.workshop_id = localStorage.getItem("workshop_id");
    }
    if (this.workshop_id) {
      this.getDetailData();
    }
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    getDetailData() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "get-category-setting/" + this.workshop_id,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          this.detailList = res.list;
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    handlePdfDownload(url) {
      window.open(url, "_self");
    },
  },
};
</script>

