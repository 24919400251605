<template>
  <div class="page-content">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-7">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
            >
              <a @click="$router.go(-1)" class="cursor-pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-0">Profile</h1>
              <div></div>
            </div>
            <Form @submit="saveProfile()" class="form-group">
              <div class="card p-3 form-style mb-3 bg-light">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <div>
                      <label class="form-label">Owner’s Full Name</label>
                      <Field
                        v-slot="{ field }"
                        name="name"
                        rules="required:Owner’s name"
                        v-model="details.name"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="details.name"
                          placeholder="Enter Owner’s full name"
                        />
                      </Field>
                    </div>
                    <div class="mt-3">
                      <ErrorMessage name="name" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="form-label">Primary Mobile</label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control border-end-0"
                        v-model="details.mobile_number"
                        disabled
                      />
                      <a
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target="#MobNoModal"
                        class="input-group-text rounded-end-3 bg-white border-start-0"
                        id="basic-addon2"
                        ><img src="static/images/icon-edit.svg" width="18" alt=""
                      /></a>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="form-label">Shop Name</label>
                    <Field
                      v-slot="{ field }"
                      name="shopName"
                      rules="required:Shop name"
                      v-model="workshop.workshop_name"
                    >
                      <input
                        type="text"
                        v-bind="field"
                        class="form-control"
                        v-model="workshop.workshop_name"
                        placeholder="Shop Name"
                      />
                    </Field>
                    <div class="mt-3">
                      <ErrorMessage name="shopName" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <label class="form-label">Business Type</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="workshop.business_type"
                      disabled
                    />
                  </div>
                  <div class="col-lg-12 mb-3">
                    <label class="form-label">Workshop Address</label>
                    <div class="input-group mb-3">
                      <Field
                        v-slot="{ field }"
                        name="workshop_address"
                        rules="required:Workshop address"
                        v-model="workshopAddress"
                      >
                        <input
                          type="text"
                          v-bind="field"
                          class="form-control"
                          v-model="workshopAddress"
                          placeholder="Shop Name"
                        />
                      </Field>
                      <a
                        data-bs-toggle="offcanvas"
                        data-bs-target="#AddAddressModal"
                        class="input-group-text rounded-end-3 bg-white border-start-0 cursor-pointer"
                        id="basic-addon2"
                        v-on:click="showAddressModel()"
                        ><img src="static/images/icon-edit.svg" width="18" alt=""
                      /></a>
                    </div>
                    <ErrorMessage name="workshop_address" class="validation-msg" />
                  </div>
                </div>
              </div>
              <div class="text-center mt-4">
                <button
                  id="save-profile"
                  class="btn btn-primary px-5"
                  native-type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
  <!-- Modal mobile no. -->
  <div
    class="modal fade"
    id="MobNoModal"
    tabindex="-1"
    aria-labelledby="MobNoModalLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="MobNoModalLabel">Mobile Number</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body form-style">
          <input type="text" class="form-control mb-4" v-model="mobile_number" />
          <button
            type="button"
            id="resend-otp"
            class="btn btn-primary w-100"
            data-bs-toggle="modal"
            data-bs-target="#OtpModal"
            v-on:click="sendOtp"
            :disabled="!mobile_number || mobile_number.length !== 10"
          >
            Send OTP
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OTP -->
  <div
    class="modal fade"
    id="OtpModal"
    tabindex="-1"
    aria-labelledby="OtpLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="OtplLabel">OTP</h1>
          <button
            id="close-verify-otp-popup"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body form-style">
          <label for="">Enter OTP</label>
          <div class="d-flex gap-3 mb-4">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input form-control text-center"
              separator="&nbsp;&nbsp;&nbsp;&nbsp;"
              inputType="text"
              :num-inputs="4"
              v-model:value="otp"
              :should-auto-focus="true"
              :should-focus-order="true"
            />
          </div>

          <button
            id="verify-otp"
            type="button"
            class="btn btn-primary w-100"
            v-on:click="verifyOtp"
            :disabled="!otp || otp.length !== 4"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Address  -->
  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="AddAddressModal"
    aria-labelledby="AddAddressModalLabel"
    style="width: 700px"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="AddAddressModalLabel">Address</h5>
      <button
        id="close-address-popup"
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="getMeCall"
      ></button>
      <button
        id="close-address-popup1"
        hidden
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="position-relative">
        <input
          type="text"
          class="form-control mb-2"
          placeholder="Search Address"
          id="autocompleteInput"
          v-model="searchAddress"
        />
      </div>
      <div class="mb-3">
        <div ref="map" class="google-map card p-4 mt-3"></div>
      </div>
      <Form @submit="saveAddress" class="form-style">
        <div class="mb-3">
          <label for="" class="font-14 mb-2"
            >Shop No / Building Name <span class="text-danger">*</span>
          </label>
          <Field
            v-slot="{ field }"
            name="address-line-1"
            rules="required:Shop No / Building Name"
            v-model="workshop.address_line_1"
          >
            <input
              type="text"
              v-bind="field"
              class="form-control"
              v-model="workshop.address_line_1"
              placeholder="Enter Shop No / Building Name"
            />
          </Field>
        </div>
        <div>
          <ErrorMessage name="address-line-1" class="validation-msg" />
        </div>
        <div class="mb-3">
          <label for="" class="font-14 mb-2"
            >Area / Sector / Locality <span class="text-danger">*</span>
          </label>
          <Field
            v-slot="{ field }"
            name="address-line-2"
            rules="required:Area / Sector / Locality"
            v-model="workshop.address_line_2"
          >
            <input
              type="text"
              v-bind="field"
              class="form-control"
              v-model="workshop.address_line_2"
              placeholder="Area / Sector / Locality"
            />
          </Field>
        </div>
        <div>
          <ErrorMessage name="address-line-2" class="validation-msg" />
        </div>
        <div class="row mb-4">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="" class="font-14 mb-2"
                >Pin code <span class="text-danger">*</span></label
              >
              <Field
                v-slot="{ field }"
                name="pincode"
                rules="required:pincode"
                v-model="workshop.pincode"
              >
                <input
                  v-bind="field"
                  type="text"
                  v-model="workshop.pincode"
                  class="form-control"
                  placeholder="Enter pincode"
              /></Field>
            </div>
            <ErrorMessage name="pincode" class="validation-msg" />
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="" class="font-14 mb-2"
                >Nearby Landmarks <span class="text-danger">*</span></label
              >
              <Field
                v-slot="{ field }"
                name="landmark"
                rules="required:Nearby Landmarks"
                v-model="workshop.landmark"
              >
                <input
                  v-bind="field"
                  type="text"
                  v-model="workshop.landmark"
                  class="form-control"
                  placeholder="Enter Nearby Landmarks"
              /></Field>
            </div>
            <ErrorMessage name="landmark" class="validation-msg" />
          </div>
        </div>

        <div class="text-center">
          <button id="save-address" native-type="submit" class="btn btn-primary">
            Save Details
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>
<script>
import "../Onboarding/onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import { Loader } from "@googlemaps/js-api-loader";
import VOtpInput from "vue3-otp-input";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "MyProfile",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,

    VOtpInput,
  },
  data() {
    return {
      details: {},
      workshop: {},
      googleMap: null,
      mapOptions: {
        center: { lat: 18.5204, lng: 73.8567 },
        zoom: 12,
      },
      marker: null,
      searchAddress: "",
      autocomplete: null,
      google: null,
      tempWorkshop: {},
      mobile_number: "",
      otp: "",
      workshop_id: "",
      workshopAddress: "",
    };
  },

  mounted() {
    this.getMeCall();
  },
  methods: {
    getMeCall() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res && res.result) {
            this.details = res.result;
            this.workshop = res.result.workshop;
            this.workshop_id = res.result.workshop_id;
            this.workshopAddress =
              res.result.workshop.address_line_1 +
              " " +
              res.result.workshop.address_line_2 +
              " " +
              res.result.workshop.pincode +
              " " +
              res.result.workshop.landmark;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    showAddressModel() {
      this.loadGoogleMaps();
    },
    loadGoogleMaps() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      const loader = new Loader({
        apiKey: "AIzaSyDF5xEC_aqtTstR0ItP-9-o4rg9d5Ccomk",
        version: "weekly",
        libraries: ["places"],
      });
      try {
        if (
          this.workshop &&
          this.workshop.latitude !== undefined &&
          this.workshop.longitude !== undefined
        ) {
          if (
            this.workshop &&
            !isNaN(Number(this.workshop.latitude)) &&
            !isNaN(Number(this.workshop.longitude))
          ) {
            const lat =
              typeof this.workshop.latitude === "string"
                ? parseFloat(this.workshop.latitude)
                : this.workshop.latitude;
            const lng =
              typeof this.workshop.longitude === "string"
                ? parseFloat(this.workshop.longitude)
                : this.workshop.longitude;

            this.mapOptions.center = {
              lat: lat,
              lng: lng,
            };
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }

      loader
        .load()
        .then((google) => {
          this.google = google;
          this.initMap();
          this.initAutocomplete();
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error("Error loading Google Maps API:", error);
        });
    },

    initMap() {
      this.googleMap = new this.google.maps.Map(this.$refs.map, this.mapOptions);

      const markerPosition = this.mapOptions.center;

      // if (this.marker) {
      //   this.marker.setMap(null); // Removes the existing marker from the map
      // }

      if (this.marker) {
        this.marker.setPosition(markerPosition); // Updates the marker's position
      } else {
        this.marker = new this.google.maps.Marker({
          position: markerPosition,
          map: this.googleMap,
          title: this.details.address_line_1 || "Pune, India",
          draggable: true, // Make the marker draggable
        });
      }
      // Add event listener to update coordinates and reverse geocode on drag end
      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.reverseGeocode(position);
      });
    },

    initAutocomplete() {
      this.autocomplete = new this.google.maps.places.Autocomplete(
        document.getElementById("autocompleteInput"),
        {
          componentRestrictions: { country: "IN" },
          fields: ["address_components", "formatted_address", "geometry", "name"],
          types: ["establishment"],
        }
      );
      this.autocomplete.addListener("place_changed", () => {
        const place = this.autocomplete.getPlace();
        if (!place.geometry) {
          console.error("No location found for input:", place);
          return;
        }

        this.addMarker(place);

        this.googleMap.setCenter(place.geometry.location);
        this.googleMap.setZoom(15);
      });
    },

    addMarker(place) {
      if (!this.googleMap) {
        console.error("Google Map not initialized yet.");
        return;
      }

      // if (this.marker) {
      //   this.marker.setMap(null); // Removes the existing marker from the map
      // }

      if (this.marker) {
        this.marker.setPosition(place.geometry.location); // Updates the marker's position
      } else {
        this.marker = new this.google.maps.Marker({
          position: place.geometry.location,
          map: this.googleMap,
          title: place.formatted_address,
          draggable: true, // Make the marker draggable
        });
      }

      // Update the workshop object with initial place details
      this.updateWorkshopCoordinates(this.marker.getPosition(), place);

      // Add event listener to update coordinates and reverse geocode on drag end
      this.marker.addListener("dragend", () => {
        const position = this.marker.getPosition();
        this.reverseGeocode(position);
      });
    },
    reverseGeocode(position) {
      const geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];
          this.updateWorkshopCoordinates(position, place);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    },
    updateWorkshopCoordinates(position, place) {
      this.workshop.latitude = position.lat().toString();
      this.workshop.longitude = position.lng().toString();

      if (place) {
        this.workshop.address_line_2 = place.formatted_address || "";
        this.workshop.pincode = this.getPincodeFromPlace(place) || "";
        this.workshop.landmark = place.name || "";
      }

      console.log("Updated coordinates:", {
        latitude: this.workshop.latitude,
        longitude: this.workshop.longitude,
      });

      if (place) {
        console.log("Updated place details:", place);
      }
    },
    getPincodeFromPlace(place) {
      const addressComponents = place.address_components || [];
      for (let component of addressComponents) {
        for (let type of component.types) {
          if (type === "postal_code") {
            return component.long_name;
          }
        }
      }
      return "";
    },
    saveProfile() {
      var obj = {
        name: this.details.name,
        workshop_name: this.workshop.workshop_name,
        address_line_1: this.workshop.address_line_1,
        address_line_2: this.workshop.address_line_2,
        landmark: this.workshop.landmark,
        longitude: this.workshop.longitude,
        latitude: this.workshop.latitude,
        pincode: this.workshop.pincode,
      };
      console.log(" obj----", obj);
      this.$api
        .postAPI({
          _action: "/set-info/" + this.workshop_id,
          _buttonId: "save-profile",
          _body: obj,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getMeCall();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    sendOtp() {
      var requestBody = {
        mobile_number: this.mobile_number,
        type: "CHANGE_MOBILE_NUMBER",
      };
      this.$api
        .postAPI({
          _action: "/resend-otp",
          _buttonId: "resend-otp",
          _body: requestBody,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    verifyOtp() {
      var requestBody = {
        otp: this.otp,
        mobile_number: this.mobile_number,
        type: "CHANGE_MOBILE_NUMBER",
      };
      this.$api
        .postAPI({
          _action: "/verify-otp",
          _buttonId: "verify-otp",
          _body: requestBody,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          const closebutton = document.getElementById("close-verify-otp-popup");
          closebutton.click();
          this.mobile_number = "";
          this.otp = "";
          this.getMeCall();
        })
        .catch((error) => {
          console.error(error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    saveAddress() {
      this.workshopAddress =
        this.workshop.address_line_1 +
        " " +
        this.workshop.address_line_2 +
        " " +
        this.workshop.pincode +
        " " +
        this.workshop.landmark;

      console.log("this.workshopAddress ", this.workshopAddress);
      const closebutton = document.getElementById("close-address-popup1");
      closebutton.click();
    },
  },
};
</script>

<style scoped>
.google-map {
  display: flex;
  width: 100%;
  height: 400px;
}
</style>