<template>
  <div class="login-page">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 mt-5">
          <div class="col-lg-12">
            <div class="text-center">
              <a
                @click="$router.go(-1)"
                class="float-start"
                style="cursor: pointer"
              >
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
            <h1 class="font-22 mb-3 text-center">Choose Outlet</h1>
            <div class="row">
              <div v-if="loading" class="text-center" style="margin-top: 25vh">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div>Loading...</div>
              </div>
              <div
                class="col-lg-4 mt-4"
                v-for="(item, i) in workshopList"
                :key="i"
              >
                <div
                  class="card p-3 border outlet-card"
                  @click="selectWorkshop(item.id)"
                  :class="{ selected: selectedWorkshop === item.id }"
                >
                  <div class="d-flex gap-3">
                    <div>
                      <img
                        :src="
                          item.image ? item.image : `static/images/default.png`
                        "
                        width="150"
                        height="100"
                        class="rounded-3 object-fit-cover"
                        alt=""
                      />
                    </div>
                    <div class="w-100">
                      <div
                        class="font-medium d-flex gap-3 justify-content-between font-16 mb-3"
                      >
                        {{ item.workshop_name }}
                        <span
                          ><i
                            class="fa fa-angle-right font-20"
                            aria-hidden="true"
                          ></i
                        ></span>
                      </div>
                      <p class="font-14 text-secondary mb-0">
                        {{ item.address_line_1 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>

      <div class="text-center mt-5">
        <button
          id="close_btn"
          class="btn btn-outline-danger px-5"
          :disabled="this.selectedWorkshop == null"
          @click="handleDeactivateAccount"
        >
          {{ "Activate Workshop" }}
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent.vue";

export default {
  name: "ActivateWorkshop",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      workshopList: [],
      type: "INACTIVE",
      loading: false,
      meData: null,
      selectedWorkshop: null,
    };
  },

  mounted() {
    this.getWorkshopList();
    this.meData = JSON.parse(localStorage.getItem("userDetail"));
    if (!this.meData) {
      this.$router.go(-1);
    }
  },
  methods: {
    getWorkshopList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `status-workshop-list/${this.type}`,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          this.workshopList = res.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    selectWorkshop(workShop_id) {
      this.selectedWorkshop = null;
      this.selectedWorkshop = workShop_id;
      this.$sharedService.setMapValue("activate_Workshop_Id", workShop_id);
    },
    handleDeactivateAccount() {
      if (this.meData && this.meData.mobile_number) {
        var obj = {
          type: "ACTIVE",
          mobile_number: this.meData.mobile_number,
        };

        this.$api
          .postAPI({
            _action: "resend-otp",
            _buttonId: "close_btn",
            _body: obj,
          })
          .then((res) => {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.$router.push("/close-account-otp");
            this.$sharedService.setMapValue("close-account-type", `ACTIVE`);
          })
          .catch(() => {});
      } else {
        console.error("Mobile number is null or undefined");
      }
    },
  },
};
</script>
<style scoped>
.outlet-card.selected {
  border-color: #004852 !important;
}
</style>
