import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import {
  notify
} from "@kyvg/vue3-notification";
var http = null;

class WebService {
  constructor({
    url = ""
  }) {
    http = axios.create({
      baseURL: url,
    });
  }
  getAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .get(_action, {
        params: params,
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  postAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }

    return http
      .post(_action, JSON.stringify(_body), {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  putAPI({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .put(_action, JSON.stringify(_body), {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  deleteAPI({
    _action,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    return http
      .delete(_action, {
        headers: this.getHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  getAccesstoken({
    _action,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    var headers = {};
    let params = new URLSearchParams();
    for (let key in _body) {
      params.set(key, _body[key]);
    }

    headers["Content-Type"] = "application/json";
    headers["Authorization"] = "Basic YXV0b3Nwcm86U3BIIW5Y";

    return http
      .post(_action + "?" + params, null, {
        headers: headers,
      })
      .then((response) => {
        // localStorage.setItem("token", response.data.token);
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._loginErrorHandler(e);
      });
  }
  uploadImageAPI({
    _action,
    _file,
    _key,
    _body,
    _buttonId,
    _hideLoader
  }) {
    if (!_key) {
      _key = "file";
    }
    if (this.addLoader(_buttonId, _hideLoader)) {
      return;
    }
    let formData = new FormData();
    formData.append(_key, _file);
    for (let key in _body) {
      formData.append(key, _body[key]);
    }

    return http
      .post(_action, formData, {
        headers: this.getBaseHeader(),
      })
      .then((response) => {
        this.removeLoader(_buttonId, _hideLoader);
        return response.data;
      })
      .catch((e) => {
        this.removeLoader(_buttonId, _hideLoader);
        return this._errorHandler(e);
      });
  }

  _loginErrorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 400 || error.status === 404) {
      errorMessage =
        (error && error.data && error.data.message) ||
        "Invalid Username or Password";
    } else if (error.status === 500) {
      errorMessage = "Server Error";
    } else {
      errorMessage =
        (error & error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    notify({
      type: "error",
      text: errorMessage,
    });
    throw new Error(errorMessage);
  }

  _errorHandler(error) {
    if (error.response != null) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
      this.logout();
    } else if (error.status === 500) {
      errorMessage = "webService.Server Error";
    } else {
      errorMessage =
        (error && error.data && error.data.message) || "Bad Response";
    }
    // alert(error.data.message)
    // notify({
    //   type: "error",
    //   text: errorMessage,
    // });
    Swal.fire({
      title: errorMessage,
      icon: "error",
      text: "An error occurred.",
      showConfirmButton: false,
      showCloseButton: true,
    });
    throw new Error(errorMessage);
  }

  logout() {
    localStorage.clear();
    window.location.href = "/home";
  }
  clearCache() {
    if ("caches" in window) {
      caches
        .keys()
        .then((cacheNames) => {
          return Promise.all(
            cacheNames.map((cacheName) => {
              return caches.delete(cacheName);
            })
          );
        })
        .then(() => {});
    } else {
      alert("Cache API not supported in this browser");
    }
  }
  getHeader() {
    var headers = this.getBaseHeader();
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";
    headers["x-platform"] = "WEB";
    headers["x-os"] = "WEB";
    headers["x-os-version"] = "1.0";
    headers["x-localization"] = "en";
    if (localStorage.getItem("product_uuid")) {
      headers["x-product"] = localStorage.getItem("product_uuid");
      headers["product_uuid"] = localStorage.getItem("product_uuid");
    }
    if (localStorage.getItem("company_uuid")) {
      headers["x-company"] = localStorage.getItem("company_uuid");
      headers["company_uuid"] = localStorage.getItem("company_uuid");
    }
    if (localStorage.getItem("user_type")) {
      headers["x-type"] = localStorage.getItem("user_type");
      headers["user_type"] = localStorage.getItem("user_type");
    }
    return headers;
  }

  getBaseHeader() {
    var headers = {};
    if (localStorage.getItem("access_token")) {
      headers["Authorization"] =
        "Bearer " + localStorage.getItem("access_token");
    }

    return headers;
  }
  addLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      if (button.disabled) {
        return true;
      }
      button.disabled = true;
      var loader = document.createElement("i");
      loader.className = "fa fa-spinner fa-spin mr-2";
      button.prepend(loader);
      return false;
    }
  }
  removeLoader(id) {
    if (id) {
      var button = document.getElementById(id);
      button.disabled = false;
      button.removeChild(button.childNodes[0]);
    }
  }
}

export default new WebService({
  // url: "https://carappapi.winayak.com/vendor/",
  url: "https://app.youhonk.com/vendor/"
  //url: "http://localhost:8081/api"
});