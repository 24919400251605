s
<template>
  <div class="page-content">
    <div class="container py-3" v-show="meData">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7 text-center">
          <a @click="$router.go(-1)" class="float-start cursor-pointer">
            <svg
              width="12"
              height="16"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                stroke="#001D21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <h1 class="page-title">
            {{
              this.type == "INACTIVE" ? "Deactive Workshop" : "Close Account  "
            }}
          </h1>
        </div>
      </div>
      <div>
        <div class="row justify-content-center">
          <div class="col-lg-7 mb-3 text-center">
            <p class="text-secondary">
              If the account is
              {{ this.type == "INACTIVE" ? "Deactived " : "Closed  " }}, you
              will not receive any more orders from the customer and the account
              will be {{ this.type == "INACTIVE" ? "Deactived " : "Closed  " }}
            </p>
            <p class="text-success">
              Note: OTP will be sent to primary mobile only
            </p>
            <p class="font-18 font-medium mb-5">
              We have received your closure request, we will get back to you.
            </p>
            <button
              @click="handleCloseAccount"
              id="close_btn"
              class="btn btn-outline-danger px-5"
              :disabled="this?.meData?.open_order > 0"
            >
              {{
                this.type == "INACTIVE"
                  ? "Deactive Workshop"
                  : "Close Account  "
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Alert  -->
  <div
    class="modal fade"
    id="AlertrModal"
    tabindex="-1"
    aria-labelledby="AlertrModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <img src="static/images/success-icon.svg" alt="" />
            <p>
              We have received your closure request, we will get back to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CloseAccount",
  data() {
    return {
      meData: null,
      TYPE: null,
    };
  },
  mounted() {
    this.meData = JSON.parse(localStorage.getItem("userDetail"));
    if (
      !this.meData ||
      !this.$sharedService.getMapValue("close-account-type")
    ) {
      this.$router.go(-1);
    }

    if (this.$sharedService.getMapValue("close-account-type") == "INACTIVE") {
      this.type = "INACTIVE";
    } else {
      this.type = "CLOSE_ACCOUNT";
    }
  },
  methods: {
    handleCloseAccount() {
      if (this.meData && this.meData.mobile_number) {
        var obj = {
          type: this.type,
          mobile_number: this.meData.mobile_number,
        };

        this.$api
          .postAPI({
            _action: "resend-otp",
            _buttonId: "close_btn",
            _body: obj,
          })
          .then((res) => {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.$router.push("/close-account-otp");
          })
          .catch(() => {});
      } else {
        console.error("Mobile number is null or undefined");
      }
    },
  },
};
</script>
