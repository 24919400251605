<template>
  <div v-if="showMessage" class="text-center">
    <div class="w-50 d-inline-block pt-5 pb-5 mt-5 mb-5">
      <h3 class="mb-4">
        <strong>{{ title }}</strong>
      </h3>
      <p v-if="subtitle">{{ subtitle }}</p>
      <a
        v-if="subtitle"
        class="btn btn-link"
        @click="onClick()"
        href="javascript: void(0);"
      >
        <!-- {{$lang.messages.retry}} -->
        Retry
      </a>
    </div>
  </div>
  <div v-show="isLoader">
    <div class="loader-container">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="loader-text">Loading...</div>
  </div>
</template>
<script>
export default {
  name: "ErrorComponent",
  data() {
    return {
      title: "",
      subTitle: "",
      showMessage: false,
      isLoader: false,
    };
  },
  methods: {
    onClick() {
      this.updateShowMessage();
      this.$emit("retry");
    },
    updateShowMessage() {
      this.showMessage = !this.showMessage;
    },
    setTitle(title) {
      if (title) {
        this.showMessage = true;
        this.setSubtitle();
        this.title = title;
      } else {
        this.setSubtitle(true);
        this.title = "Error";
        this.updateShowMessage();
      }
    },
    isShowMessage() {
      return this.showMessage;
    },
    setSubtitle(isShowSubtitle) {
      this.subtitle = isShowSubtitle
        ? "Looks like something went wrong!"
        : undefined;
    },
    showLoader(boolean) {
      this.isLoader = boolean;
      this.isLoader && (this.showMessage = false);
    },
    isShowMessageValue(value) {
      this.showMessage = value;
    },
  },
};
</script>
<style>
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 60px;
}
</style>
