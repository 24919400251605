<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <div class="px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Services</h1>
                <div></div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="accordion services-accordion" id="services_accordion">
                  <div
                    class="accordion-item"
                    v-for="(item, index) in setCategoryList"
                    :key="index"
                  >
                    <div class="accordion-header">
                      <div class="d-flex w-100 p-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="item.id"
                            :id="item.id"
                            v-on:click="handleCheckBox(index, 'ALL')"
                            :true-value="1"
                            :false-value="0"
                            v-model="item.is_ticked"
                          />
                          <label class="form-check-label" :for="item.id"></label>
                        </div>
                        <div
                          class="w-100 accordion-button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapse_' + index"
                          aria-expanded="false"
                          :aria-controls="'collapse_' + index"
                        >
                          <div>
                            <h4 class="tab-title">{{ item.title }}</h4>
                            <p class="tab-subtitle">
                              <!-- {{
                                item?.description.replace(/^\[|\]$/g, "").trim()
                              }} -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      :id="'collapse_' + index"
                      class="accordion-collapse collapse"
                      data-bs-parent="#services_accordion"
                    >
                      <div class="accordion-body">
                        <div
                          class="form-check mb-3"
                          v-for="(res, i) in item.list"
                          :key="i"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="res.id"
                            :id="res.id"
                            :true-value="1"
                            :false-value="0"
                            v-on:click="handleCheckBox(index, i)"
                            v-model="res.is_ticked"
                          />
                          <label class="form-check-label" :for="res.id">
                            <div>
                              <p class="mb-0 font-bold">{{ res.title }}</p>
                              <p class="font-12 text-secondary">
                                {{
                                  res.description && res.description != "[]"
                                    ? res.description.replace(/^\[|\]$/g, "").trim()
                                    : ""
                                }}
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item" v-if="roadsideList.length > 0">
                    <div class="accordion-header">
                      <div class="d-flex w-100 p-3">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value="road_side"
                            id="road_side"
                            v-on:click="handleCheckBoxRoadAll()"
                            :true-value="1"
                            :false-value="0"
                            v-model="road_side"
                          />
                          <label class="form-check-label" for="road_side"></label>
                        </div>
                        <div
                          class="w-100 accordion-button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse_road_side"
                          aria-expanded="false"
                          aria-controls="collapse_road_side"
                        >
                          <div>
                            <h4 class="tab-title">24*7 RoadSide Assistance</h4>
                            <p class="tab-subtitle">
                              <!-- {{
                                item?.description.replace(/^\[|\]$/g, "").trim()
                              }} -->
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="collapse_road_side"
                      class="accordion-collapse collapse"
                      data-bs-parent="#services_accordion"
                    >
                      <div class="accordion-body">
                        <div
                          class="form-check mb-3"
                          v-for="(res, i) in roadsideList"
                          :key="i"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="'road_' + res.id"
                            :id="'road_' + res.id"
                            :true-value="1"
                            :false-value="0"
                            v-on:click="handleCheckBoxRoad(index, i)"
                            v-model="res.is_ticked"
                          />
                          <label class="form-check-label" :for="'road_' + res.id">
                            <div>
                              <p class="mb-0 font-bold">{{ res.title }}</p>
                              <p class="font-12 text-secondary">
                                {{
                                  res.description && res.description != "[]"
                                    ? res.description.replace(/^\[|\]$/g, "").trim()
                                    : ""
                                }}
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    id="category_id"
                    v-on:click="onPostCategory"
                    class="btn btn-primary px-5"
                    :disabled="!isAnyItemTicked"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import $ from "jquery";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "SelectServices",

  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: "",
      setCategoryList: [],
      roadsideList: [],
      selectedid: [],
      roadId: [],
      road_side: 0,
      page_type: localStorage.getItem("page_type"),
    };
  },

  computed: {
    isAnyItemTicked() {
      return this.setCategoryList.some(
        (category) =>
          category.is_ticked == 1 || category.list.some((item) => item.is_ticked == 1)
      );
    },
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getCategoryList();
      this.getRoadList();
    }
    $(".btn-check").change(function () {
      $(this).closest(".form-check").toggleClass("selected", this.checked);
    });
  },

  methods: {
    handleBack() {
      this.$router.go(-1);
    },

    getCategoryList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "get-category/" + this.id,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          this.setCategoryList = res.list;
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error("Error fetching categories");
        });
    },

    getRoadList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "get-roadside-assitance/" + this.id,
        })
        .then((res) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          this.roadsideList = res.list;
          this.handleCheckBoxRoad();
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error("Error fetching categories");
        });
    },

    onPostCategory() {
      this.selectedid = [];
      this.roadId = [];

      for (var a in this.setCategoryList) {
        if (this.setCategoryList[a].is_ticked == "1") {
          this.selectedid.push(this.setCategoryList[a].id);
        }
        for (var b in this.setCategoryList[a].list) {
          if (this.setCategoryList[a].list[b].is_ticked == "1") {
            this.selectedid.push(this.setCategoryList[a].list[b].id);
          }
        }
      }

      for (var c in this.roadsideList) {
        if (this.roadsideList[c].is_ticked == "1") {
          this.roadId.push(this.roadsideList[c].id);
        }
      }

      const obj = {
        list: this.selectedid,
        roadside_assistance_list: this.roadId,
      };

      this.$api
        .postAPI({
          _action: `set-category/` + this.id,
          _body: obj,
          _buttonId: "category_id",
        })
        .then((res) => {
          if (this.page_type == "EDIT") {
            this.$router.push("/settings");
          } else {
            this.$sharedService.setMapValue("Brands", res.brands);
            if (res.is_cost === "Y") {
              this.$router.push("/onboarding-steps");
            } else if (res?.brands.length > 0) {
              this.$router.push("/onboarding-steps");
            } else {
              this.$router.push("/rate-card");
            }
          }
        })
        .catch(() => {});
    },

    handleCheckBox(index, subindex) {
      if (subindex >= 0) {
        for (var i in this.setCategoryList) {
          if (index == i) {
            for (var j in this.setCategoryList[i].list) {
              if (subindex == j) {
                this.setCategoryList[i].list[j].is_ticked =
                  this.setCategoryList[i].list[j].is_ticked == 1 ? 0 : 1;
              }
            }
            var anyTicked = this.setCategoryList[i].list.some(function (item) {
              return item.is_ticked == 1;
            });
            this.setCategoryList[i].is_ticked = anyTicked ? 1 : 0;
          }
        }
      } else if (subindex == "ALL") {
        for (var a in this.setCategoryList) {
          if (index == a) {
            this.setCategoryList[a].is_ticked =
              this.setCategoryList[a].is_ticked == 1 ? 0 : 1;
            for (var b in this.setCategoryList[a].list) {
              this.setCategoryList[a].list[b].is_ticked = this.setCategoryList[
                a
              ].is_ticked;
            }
          }
        }
      }

      console.log("Updated Category:", this.setCategoryList);
    },
    handleCheckBoxRoadAll() {
      setTimeout(() => {
        if (this.road_side) {
          for (var a in this.roadsideList) {
            this.roadsideList[a].is_ticked = 1;
          }
        } else {
          for (var b in this.roadsideList) {
            this.roadsideList[b].is_ticked = 0;
          }
        }
      }, 200);
    },
    handleCheckBoxRoad() {
      setTimeout(() => {
        var allTicked = this.roadsideList.every((item) => item.is_ticked == 1);
        this.road_side = allTicked ? 1 : 0;

        console.log("Updated Category:", this.roadsideList);
      }, 500);
    },
  },
};
</script>
