<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-10">
            <div class="text-center">
              <a @click="$router.go(-1)" class="float-start" style="cursor: pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-4">Workshop Document</h1>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <ul
                  class="nav nav-pills mb-3 tab-style-1 nav-fill"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      type="button"
                      class="nav-link"
                      :class="{ active: currentTab === 'PRIVATE' }"
                      @click="onChangeTab('PRIVATE')"
                      :disabled="
                        !this.status ||
                        this.status !== 'PENDING' ||
                        this.page_type == 'ADD_OUTLET'
                      "
                    >
                      Private Workshop
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      type="button"
                      class="nav-link"
                      :class="{
                        active: currentTab === 'AUTHORIZED',
                      }"
                      :disabled="
                        !this.status ||
                        this.status !== 'PENDING' ||
                        this.page_type == 'ADD_OUTLET'
                      "
                      @click="onChangeTab('AUTHORIZED')"
                    >
                      Authorized Workshop
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              <div v-if="currentTab == 'PRIVATE' || currentTab == 'AUTHORIZED'">
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="" class="form-label"
                        >Enter PAN Number
                        <span class="text-danger" style="font-size: 15px">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter PAN Number*"
                        v-model="panNumber"
                        @input="checkPanNumber"
                        :disabled="
                          !this.status ||
                          this.status != 'PENDING' ||
                          this.page_type == 'ADD_OUTLET'
                        "
                        @blur="isValidPan"
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mb-4">
                  <div class="col-lg-6 mb-3">
                    <label for="" class="font-medium mb-2">
                      Upload front side of PAN card</label
                    >
                    <div
                      v-if="
                        panCardImageArray &&
                        panCardImageArray.length > 0 &&
                        panCardImageArray[0].file
                      "
                      class="uploaded-photo"
                    >
                      <img
                        id="preview-pan-0"
                        :src="
                          panCardImageArray[0].file
                            ? panCardImageArray[0].file
                            : 'static/images/default.png'
                        "
                        class="image"
                        alt=""
                      />
                      <a
                        v-if="
                          this.status &&
                          this.status === 'PENDING' &&
                          this.page_type !== 'ADD_OUTLET'
                        "
                        @click="deletePanCard(panCardImageArray[0].id, 0)"
                        class="close-btn"
                        style="cursor: pointer"
                        :disabled="!this.status || this.status != 'PENDING'"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>
                    <div
                      class="upload-btn-container"
                      v-else-if="this.status && this.status === 'PENDING'"
                    >
                      <input
                        type="file"
                        @change="panHandleImageUpload($event, 0)"
                        accept="image/jpeg, image/png, image/jpg"
                        :disabled="!this.status || this.status != 'PENDING'"
                      />
                      <label htmlFor="tb-file-upload h-auto" for="preview-pan-0">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div v-if="this.status && this.status === 'PENDING'">
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label for="preview-pan-1" class="font-medium mb-2"
                      >Upload back side of PAN card</label
                    >
                    <div
                      class="uploaded-photo"
                      v-if="
                        panCardImageArray &&
                        panCardImageArray.length > 1 &&
                        panCardImageArray[1].file
                      "
                    >
                      <img
                        id="preview-pan-1"
                        :src="
                          panCardImageArray[1].file
                            ? panCardImageArray[1].file
                            : 'static/images/default.png'
                        "
                        class="image"
                        alt=""
                      />
                      <a
                        v-if="
                          this.status &&
                          this.status === 'PENDING' &&
                          this.page_type !== 'ADD_OUTLET'
                        "
                        @click="deletePanCard(panCardImageArray[1].id, 1)"
                        class="close-btn"
                        style="cursor: pointer"
                        :disabled="!this.status || this.status != 'PENDING'"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>

                    <div
                      class="upload-btn-container"
                      v-else-if="this.status && this.status === 'PENDING'"
                    >
                      <input
                        type="file"
                        @change="panHandleImageUpload($event, 1)"
                        accept="image/jpeg, image/png, image/jpg"
                        :disabled="!this.status || this.status != 'PENDING'"
                      />
                      <label htmlFor="tb-file-upload h-auto">
                        <div class="py-4 text-center">
                          <div class="mb-3">
                            <img
                              src="static/images/icon-file.svg"
                              width="20"
                              class="mx-auto"
                              alt=""
                            />
                          </div>
                          <div class="mb-3 text-secondary">Select document to upload</div>
                          <div v-if="this.status && this.status === 'PENDING'">
                            <button class="btn btn-primary">Upload File</button>
                          </div>
                        </div>
                      </label>
                      <span class="font-12 text-secondary"
                        >Supported files: Jpeg, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                    <ErrorComponent ref="errorComponent" />
                  </div>
                </div>

                <hr />
                <!-- --------------------------------GST Image------------------------------------------- -->
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="" class="form-label"
                        >Workshop GST Number
                        <span class="text-danger" style="font-size: 15px">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter Workshop GST Number* "
                        v-model="workshop_GST_number"
                        @input="checkGSTNumber"
                        :disabled="
                          !this.status ||
                          this.status != 'PENDING' ||
                          this.page_type == 'ADD_OUTLET'
                        "
                        @blur="isValidGST"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 mb-3">
                    <p class="font-bold font-18 mb-1">Upload GST Certificate</p>
                    <p class="font-12 text-secondary">
                      Supported files: Jpeg, PNG, (Max. file size 4 MB)
                    </p>
                  </div>

                  <div class="col-lg-4 mb-3" v-for="(item, i) in gstImageArray" :key="i">
                    <div class="uploaded-photo" v-if="item.file">
                      <img
                        :src="item.file ? item.file : 'static/images/default.png'"
                        class="image"
                        alt=""
                      />
                      <a
                        v-if="
                          this.status &&
                          this.status === 'PENDING' &&
                          this.page_type !== 'ADD_OUTLET'
                        "
                        @click="deleteGallery(item.id, i, 'GST')"
                        class="close-btn"
                        style="cursor: pointer"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>

                    <div
                      class="upload-btn-container"
                      v-else-if="
                        this.status &&
                        this.status === 'PENDING' &&
                        this.page_type !== 'ADD_OUTLET'
                      "
                    >
                      <div>
                        <input
                          type="file"
                          :id="'preview-gst-' + i"
                          @change="gstHandleImageUpload($event, i)"
                          accept="image/jpeg, image/png, image/jpg"
                          :disabled="
                            !this.status ||
                            this.status != 'PENDING' ||
                            item.type === 'PAST' ||
                            this.page_type == 'ADD_OUTLET'
                          "
                        />
                        <label for="photo-file-upload h-auto">
                          <div class="py-4 text-center">
                            <div class="mb-3">
                              <img
                                src="static/images/icon-file.svg"
                                width="20"
                                class="mx-auto"
                                alt=""
                              />
                            </div>
                            <div class="mb-3 text-secondary">
                              Select document to upload
                            </div>
                            <div
                              v-if="
                                this.status &&
                                this.status === 'PENDING' &&
                                this.page_type !== 'ADD_OUTLET'
                              "
                            >
                              <button class="btn btn-primary">Upload File</button>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <ErrorComponent ref="errorComponent" />
                  <div class="text-center mt-4 mb-4">
                    <button
                      type="button"
                      class="btn btn-outline-primary px-5"
                      @click="addGSTImage"
                      v-if="
                        this.status &&
                        this.status === 'PENDING' &&
                        this.page_type !== 'ADD_OUTLET'
                      "
                    >
                      <img
                        src="static/images/icon-camera.svg"
                        width="20"
                        class="align-text-top me-2"
                        alt=""
                      />
                      Add More GST Image
                    </button>
                  </div>
                </div>
              </div>

              <hr v-show="currentTab == 'AUTHORIZED'" />
              <div v-if="currentTab == 'AUTHORIZED'">
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="" class="form-label"
                        >Enter Authorization Number
                        <span class="text-danger" style="font-size: 15px">*</span></label
                      >
                      <input
                        type="text"
                        onkeypress="return /[0-9]/i.test(event.key)"
                        class="form-control"
                        placeholder="Enter Authorization Number"
                        v-model="authorization_Number"
                        @blur="checkAuthorizedNumber"
                        :disabled="!this.status || this.status != 'PENDING'"
                      />
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mb-4">
                  <div class="col-12 mb-3">
                    <p class="font-bold font-18 mb-1">Upload Authorization Certificate</p>
                    <p class="font-12 text-secondary">
                      Supported files: Jpeg, PNG, (Max. file size 4 MB)
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4 mb-3"
                    v-for="(item, i) in authorizedImageArray"
                    :key="i"
                  >
                    <div class="uploaded-photo" v-if="item.file">
                      <img
                        :src="item.file ? item.file : 'static/images/default.png'"
                        class="image"
                        alt=""
                      />
                      <a
                        v-if="this.status && this.status === 'PENDING'"
                        @click="deleteGallery(item.id, i, 'Authorized')"
                        class="close-btn"
                        style="cursor: pointer"
                        ><img
                          src="static/images/close-icon.svg"
                          class="close-icon"
                          width="20"
                          alt=""
                      /></a>
                    </div>

                    <div
                      class="upload-btn-container"
                      v-else-if="this.status && this.status === 'PENDING'"
                    >
                      <div>
                        <input
                          type="file"
                          :id="'authorized-img-' + i"
                          @change="authorizedHandleImageUpload($event, i)"
                          accept="image/jpeg, image/png, image/jpg"
                          :disabled="
                            !this.status ||
                            this.status != 'PENDING' ||
                            item.type === 'PAST'
                          "
                        />
                        <label for="photo-file-upload h-auto">
                          <div class="py-4 text-center">
                            <div class="mb-3">
                              <img
                                src="static/images/icon-file.svg"
                                width="20"
                                class="mx-auto"
                                alt=""
                              />
                            </div>
                            <div class="mb-3 text-secondary">
                              Select document to upload
                            </div>
                            <div v-if="this.status && this.status === 'PENDING'">
                              <button class="btn btn-primary">Upload File</button>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <ErrorComponent ref="errorComponent" />
                <div class="text-center mt-4 mb-4">
                  <button
                    type="button"
                    class="btn btn-outline-primary px-5"
                    @click="addAuthorizedImage"
                    v-if="this.status && this.status === 'PENDING'"
                  >
                    <img
                      src="static/images/icon-camera.svg"
                      width="20"
                      class="align-text-top me-2"
                      alt=""
                    />
                    Add More Authorized Image
                  </button>
                </div>
              </div>
              <div class="col-12 text-center border-top pt-3 mb-5">
                <button
                  id="save-data"
                  type="button"
                  class="btn btn-primary px-5"
                  v-on:click="typeWiseImageUplaod"
                  v-if="
                    this.status &&
                    this.status === 'PENDING' &&
                    this.page_type !== 'ADD_OUTLET'
                  "
                  :disabled="isValid"
                >
                  Save
                </button>
                <div v-show="isValid && currentTab == 'PRIVATE'">
                  <span class="text-danger">Please enter valid PAN or GST number</span>
                </div>
                <div v-show="isValid && currentTab == 'AUTHORIZED'">
                  <span class="text-danger"
                    >Please enter valid PAN or GST or Authorization number</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "WorkShopDetail",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: null,
      panCardImageArray: [],
      panNumber: "",
      workshop_GST_number: "",
      gstImageArray: [],
      gstObjArray: [],
      currentTab: "PRIVATE",
      authorization_Number: "",
      authorizedImageArray: [],
      page_type: localStorage.getItem("page_type"),
      isValid: null,
      panCardObjArray: [],
      authorizedObjArray: [],
      status: "PENDING",
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
      if (this.id) {
        this.getGSTDetails();
      }
    } else {
      this.id = localStorage.getItem("workshop_id");
      this.getGalleryList(false);
    }
  },
  methods: {
    onChangeTab(tab) {
      this.currentTab = tab;
    },

    getGalleryList(flag) {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-gallery/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          if (flag) {
            this.$router.push("/onboarding-steps");
          }
          this.gstImageArray = [];
          this.authorizedImageArray = [];
          this.panCardImageArray = [];
          if (res && res.list && res.list.length > 0) {
            for (var i = 0; i < res.list.length; i++) {
              if (res.list[i].type == "PAN_NUMBER") {
                this.panNumber = res.list[i].number;
                if (res.list[i].list && res.list[i].list.length > 0) {
                  for (var m = 0; m < res.list[i].list.length; m++) {
                    this.panCardImageArray.push({
                      file: res.list[i].list[m].image,
                      id: res.list[i].list[m].id,
                      type: "PAST",
                    });
                  }
                }
              }

              if (res.list[i].type == "GST_NUMBER") {
                this.workshop_GST_number = res.list[i].number;
                if (res.list[i].list && res.list[i].list.length > 0) {
                  for (var j = 0; j < res.list[i].list.length; j++) {
                    this.gstImageArray.push({
                      file: res.list[i].list[j].image,
                      id: res.list[i].list[j].id,
                      type: "PAST",
                    });
                  }
                }
              }

              if (res.list[i].type == "AUTHORIZED_NUMBER") {
                this.authorization_Number = res.list[i].number;
                if (res.list[i].list && res.list[i].list.length > 0) {
                  for (var n = 0; n < res.list[i].list.length; n++) {
                    this.authorizedImageArray.push({
                      file: res.list[i].list[n].image,
                      id: res.list[i].list[n].id,
                      type: "PAST",
                    });
                  }
                }
              }
            }
          }
          this.status = res.status ? res.status : "PENDING";
          this.currentTab = res.type ? res.type : "PRIVATE";
          while (this.panCardImageArray.length < 2) {
            this.addPanCardImage();
          }
          while (this.gstImageArray.length < 6) {
            this.addGSTImage();
          }
          while (this.authorizedImageArray.length < 6) {
            this.addAuthorizedImage();
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error(error);
        });
    },

    // -------------------------------Pan Card-------------------------

    panHandleImageUpload(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
        alert("Invalid file format. Please use JPEG or PNG.");
        return;
      }

      const maxSizeInBytes = 4 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("Maximum file size allowed is 4 MB.");
        return;
      }
      this.panCardImageArray[index].new_file = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.panCardImageArray[index].file = reader.result;
        this.panCardImageArray[index].type = "NEW";
        const inputElement = document.getElementById(`preview-pan-${index}`);
        if (inputElement) {
          inputElement.src = reader.result;
        }
      };
      reader.readAsDataURL(file);
      console.log("this.panCardImageArray", this.panCardImageArray);
    },

    panUpload(b, newArray) {
      var obj = newArray[b];
      this.panCardObjArray = [];
      this.panCardObjArray.push({
        type: "PAN_NUMBER",
        number: this.panNumber,
      });
      var objData = this.panCardObjArray[0];
      var $this = this;
      if (obj) {
        this.allUploadImage(obj, objData, function (res) {
          console.log(res);
          if (b > newArray.length) {
            this.$router.push("/onboarding-steps");
            return false;
          } else {
            $this.panUpload(++b, newArray);
          }
        });
      } else {
        this.getGalleryList();
      }
    },

    deletePanCard(id, index) {
      if (!this.status || this.status !== "PENDING") {
        return;
      }
      if (!id) {
        this.panCardImageArray.splice(index, 1);
        this.addPanCardImage();
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (id) {
              this.$api
                .deleteAPI({ _action: "/delete-gallery-image/" + id })
                .then((res) => {
                  this.getGalleryList();
                  this.$toast.open({
                    message: res.message,
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                  });
                })
                .catch((error) => {
                  console.error(error);
                  this.$toast.open({
                    message: "Error",
                    type: "error",
                    duration: 3000,
                    position: "top-right",
                  });
                });
            }
          }
        });
      }
    },

    addPanCardImage() {
      this.panCardImageArray.push({
        file: "",
        id: "",
        type: "NEW",
      });
    },

    // -------------------------------GST Image---------------------------------

    gstUpload(b, newArray) {
      var obj = newArray[b];
      this.gstObjArray = [];
      this.gstObjArray.push({
        type: "GST_NUMBER",
        number: this.workshop_GST_number,
      });
      var objData = this.gstObjArray[0];
      var $this = this;
      if (obj) {
        this.allUploadImage(obj, objData, function (res) {
          console.log(res);
          if (b > newArray.length) {
            this.$router.push("/onboarding-steps");
            return false;
          } else {
            $this.gstUpload(++b, newArray);
          }
        });
      } else {
        this.getGalleryList(true);
      }
    },

    gstHandleImageUpload(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
        alert("Invalid file format. Please use JPEG or PNG.");
        return;
      }
      const maxSizeInBytes = 4 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("Maximum file size allowed is 4 MB.");
        return;
      }
      this.gstImageArray[index].new_file = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.gstImageArray[index].file = reader.result;
        this.gstImageArray[index].type = "NEW";
        const inputElement = document.getElementById(`preview-gst-${index}`);
        if (inputElement) {
          inputElement.src = reader.result;
        }
      };

      reader.readAsDataURL(file);
    },

    addGSTImage() {
      this.gstImageArray.push({
        file: "",
        id: "",
        type: "NEW",
      });
    },

    // --------------------------Authorization Image---------------------------------

    authorizedHandleImageUpload(event, index) {
      const file = event.target.files[0];
      if (!file) return;

      if (!file.type.match(/image\/(jpeg|png|jpg)/)) {
        alert("Invalid file format. Please use JPEG or PNG.");
        return;
      }
      const maxSizeInBytes = 4 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        alert("Maximum file size allowed is 4 MB.");
        return;
      }
      this.authorizedImageArray[index].new_file = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.authorizedImageArray[index].file = reader.result;
        this.authorizedImageArray[index].type = "NEW";
        const inputElement = document.getElementById(`authorized-img-${index}`);
        if (inputElement) {
          inputElement.src = reader.result;
        }
      };
      reader.readAsDataURL(file);
    },

    authorizeUpload(b, newArray) {
      var obj = newArray[b];
      this.authorizedObjArray = [];
      this.authorizedObjArray.push({
        type: "AUTHORIZED_NUMBER",
        number: this.authorization_Number,
      });
      var objData = this.authorizedObjArray[0];
      var $this = this;
      if (obj) {
        this.allUploadImage(obj, objData, function (res) {
          console.log(res);
          if (b > newArray.length) {
            this.$router.push("/onboarding-steps");
            return false;
          } else {
            $this.authorizeUpload(++b, newArray);
          }
        });
      } else {
        this.getGalleryList(true);
      }
    },

    addAuthorizedImage() {
      this.authorizedImageArray.push({
        file: "",
        id: "",
        type: "NEW",
      });
    },

    typeWiseImageUplaod() {
      if (this.currentTab == "PRIVATE") {
        if (!this.panNumber) {
          this.$toast.open({
            message: "Please enter PAN number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        } else if (!this.isValidPanNumber(this.panNumber)) {
          this.$toast.open({
            message: "Please enter a valid PAN number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let flag1 = false;
        for (let i = 0; i < this.panCardImageArray.length; i++) {
          if (!this.panCardImageArray[i].file) {
            flag1 = true;
            break;
          } else {
            flag1 = false;
          }
        }
        if (flag1) {
          this.$toast.open({
            message: "Please select PAN card image !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        if (!this.workshop_GST_number) {
          this.$toast.open({
            message: "Please enter GST number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        } else if (!this.isValidGstNumber(this.workshop_GST_number)) {
          this.$toast.open({
            message: "Please enter a valid GST number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let flag2 = false;
        for (let i = 0; i < this.gstImageArray.length; i++) {
          if (this.gstImageArray[i].file) {
            flag2 = true;
            break;
          } else {
            flag2 = false;
          }
        }
        if (!flag2) {
          this.$toast.open({
            message: "Please select GST certificate image !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let panArray = [];
        for (var j = 0; j < this.panCardImageArray.length; j++) {
          if (this.panCardImageArray[j].type === "NEW") {
            panArray.push(this.panCardImageArray[j]);
          }
        }
        if (panArray.length > 0) {
          this.panUpload(0, panArray);
        }
        let newArray = [];
        for (var i = 0; i < this.gstImageArray.length; i++) {
          if (this.gstImageArray[i].type === "NEW") {
            newArray.push(this.gstImageArray[i]);
          }
        }
        if (newArray.length > 0) {
          this.gstUpload(0, newArray);
        }
        this.setWorkshopType();
        let arr = [];
        arr.push({
          type: "PAN_NUMBER",
          number: this.panNumber,
        });
        arr.push({
          type: "GST_NUMBER",
          number: this.workshop_GST_number,
        });
        for (let i = 0; i < 2; i++) {
          this.uploadOneTimeImage(arr[i]);
          if (i == 1) {
            this.getGalleryList(true);
          }
        }
      }
      if (this.currentTab == "AUTHORIZED") {
        if (!this.panNumber) {
          this.$toast.open({
            message: "Please enter PAN number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        } else if (!this.isValidPanNumber(this.panNumber)) {
          this.$toast.open({
            message: "Please enter a valid PAN number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let flag1 = false;
        for (let i = 0; i < this.panCardImageArray.length; i++) {
          if (!this.panCardImageArray[i].file) {
            flag1 = true;
            break;
          } else {
            flag1 = false;
          }
        }
        if (flag1) {
          this.$toast.open({
            message: "Please select PAN card image !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        if (!this.workshop_GST_number) {
          this.$toast.open({
            message: "Please enter GST number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        } else if (!this.isValidGstNumber(this.workshop_GST_number)) {
          this.$toast.open({
            message: "Please enter a valid GST number!",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let flag2 = false;
        for (let i = 0; i < this.gstImageArray.length; i++) {
          if (this.gstImageArray[i].file) {
            flag2 = true;
            break;
          } else {
            flag2 = false;
          }
        }
        if (!flag2) {
          this.$toast.open({
            message: "Please select GST certificate image !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        if (!this.authorization_Number) {
          this.$toast.open({
            message: "Please enter authorization number !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let flag3 = false;
        for (let i = 0; i < this.authorizedImageArray.length; i++) {
          if (this.authorizedImageArray[i].file) {
            flag3 = true;
            break;
          } else {
            flag3 = false;
          }
        }
        if (!flag3) {
          this.$toast.open({
            message: "Please select authorization certificate  image !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          return;
        }
        let panArray = [];
        for (var m = 0; m < this.panCardImageArray.length; m++) {
          if (this.panCardImageArray[m].type === "NEW") {
            panArray.push(this.panCardImageArray[m]);
          }
        }
        if (panArray.length > 0) {
          this.panUpload(0, panArray);
        }
        let newArray = [];
        for (var n = 0; n < this.gstImageArray.length; n++) {
          if (this.gstImageArray[n].type === "NEW") {
            newArray.push(this.gstImageArray[n]);
          }
        }
        if (newArray.length > 0) {
          this.gstUpload(0, newArray);
        }
        var tempArrayForAuthorized = [];
        for (var k = 0; k < this.authorizedImageArray.length; k++) {
          if (this.authorizedImageArray[k].type === "NEW") {
            tempArrayForAuthorized.push(this.authorizedImageArray[k]);
          }
        }
        if (tempArrayForAuthorized.length > 0) {
          this.authorizeUpload(0, tempArrayForAuthorized);
        }
        this.setWorkshopType();
        let arr = [];
        arr.push({
          type: "PAN_NUMBER",
          number: this.panNumber,
        });
        arr.push({
          type: "GST_NUMBER",
          number: this.workshop_GST_number,
        });
        arr.push({
          type: "AUTHORIZED_NUMBER",
          number: this.authorization_Number,
        });
        for (let i = 0; i < 3; i++) {
          this.uploadOneTimeImage(arr[i]);
          if (i == 2) {
            this.getGalleryList(true);
          }
        }
      }
    },

    setWorkshopType() {
      this.$api
        .putAPI({
          _action: "/workshop-type/" + this.id,
          _body: {
            workshop_type: this.currentTab,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    uploadMultipleImage(obj, objData, callback) {
      this.$api
        .uploadImageAPI({
          _action: "/upload-gallery/" + this.id,
          _file: obj.new_file ? obj.new_file : null,
          _key: "image",
          _body: {
            type: objData.type,
            number: objData.number,
            workshop_type: this.currentTab,
          },
        })
        .then((res) => {
          if (res) {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            callback();
          }
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    uploadOneTimeImage(objData) {
      this.$api
        .uploadImageAPI({
          _action: "/upload-gallery/" + this.id,
          _file: null,
          _key: "image",
          _body: {
            type: objData.type,
            number: objData.number,
            workshop_type: this.currentTab,
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.$toast.open({
            message: "Error",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },
    allUploadImage(obj, objData, callback) {
      console.log(obj);
      if (obj && obj.new_file) {
        this.uploadMultipleImage(obj, objData, callback);
        // setTimeout(() => {

        //   this.$router.push("/onboarding-steps");
        // }, 5000);
      } else {
        callback();
      }
    },
    deleteGallery(id, index, type) {
      if (!this.status || this.status !== "PENDING") {
        return;
      }
      if (!id) {
        if (type === "Authorized") {
          this.authorizedImageArray.splice(index, 1);
        } else if (type === "GST") {
          this.gstImageArray.splice(index, 1);
        }
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
        }).then((result) => {
          if (result.isConfirmed) {
            if (id) {
              this.$api
                .deleteAPI({ _action: "/delete-gallery-image/" + id })
                .then((res) => {
                  this.$toast.open({
                    message: res.message,
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                  });
                  this.getGalleryList();
                })
                .catch((error) => {
                  this.$toast.open({
                    message: "An error occurred",
                    type: "error",
                    duration: 3000,
                    position: "top-right",
                  });
                  console.error(error);
                });
            }
          }
        });
      }
    },
    isValidPanNumber(panNumber) {
      const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      return panPattern.test(panNumber);
    },

    isValidGstNumber(gstNumber) {
      const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
      return gstPattern.test(gstNumber);
    },

    checkPanNumber() {
      if (this.panNumber && this.panNumber.length == 10) {
        this.$api
          .postAPI({
            _action: "check-gallery-number/" + this.id,
            _body: {
              type: "PAN_NUMBER",
              number: this.panNumber,
            },
          })
          .then((res) => {
            if (res && res.status == "success") {
              this.isValid = false;
            } else {
              this.isValid = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    checkGSTNumber() {
      if (this.workshop_GST_number && this.workshop_GST_number.length == 15) {
        this.$api
          .postAPI({
            _action: "check-gallery-number/" + this.id,
            _body: {
              type: "GST_NUMBER",
              number: this.workshop_GST_number,
            },
          })
          .then((res) => {
            if (res && res.status == "success") {
              this.isValid = false;
            } else {
              this.isValid = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    checkAuthorizedNumber() {
      if (!this.authorization_Number) {
        return;
      }
      this.$api
        .postAPI({
          _action: "check-gallery-number/" + this.id,
          _body: {
            type: "AUTHORIZED_NUMBER",
            number: this.authorization_Number,
          },
        })
        .then((res) => {
          if (res && res.status == "success") {
            this.isValid = false;
          } else {
            this.isValid = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGSTDetails() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-gst-detail/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.info) {
            this.workshop_GST_number = res.info.number;
          }
          this.gstImageArray = [];
          if (res && res.info && res.info.list && res.info.list.length > 0) {
            for (let i = 0; i < res.info.list.length; i++) {
              this.gstImageArray.push({
                file: res.info.list[i].image,
                id: res.info.list[i].id,
                type: "PAST",
              });
            }
          }
          while (this.gstImageArray.length < 6) {
            this.addGSTImage();
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }

          this.getPANDetails();
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error(error);
        });
    },

    getPANDetails() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-pan-detail/" + this.id,
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.info) {
            this.panNumber = res.info.number;
          }
          this.panCardImageArray = [];
          if (res && res.info && res.info.list && res.info.list.length > 0) {
            for (let i = 0; i < res.info.list.length; i++) {
              this.panCardImageArray.push({
                file: res.info.list[i].image,
                id: res.info.list[i].id,
                type: "PAST",
              });
            }
          }
          while (this.panCardImageArray.length < 6) {
            this.addPanCardImage();
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }

          if (this.id) {
            this.getGalleryList(false);
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
          console.error(error);
        });
    },
    isValidPan() {
      if (this.isValidPanNumber(this.panNumber)) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
    isValidGST() {
      if (this.isValidGstNumber(this.workshop_GST_number)) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>