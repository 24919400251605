<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-7">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
            >
              <a v-on:click="$router.go(-1)" class="cursor-pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-0">Service Report</h1>
              <div></div>
            </div>

            <Form @submit="handleSave">
              <div class="card p-3 mb-4">
                <div class="row form-style">
                  <div class="col-lg-6 d-flex gap-2 gap-3 mb-4">
                    <div>
                      <Field
                        name="from_date"
                        rules="required:from_date"
                        id="from_date"
                        v-slot="{ field }"
                        v-model="serviceData.from_date"
                        class="form-control"
                      >
                        <Datepicker
                          autoApply
                          v-model="serviceData.from_date"
                          inputFormat="dd-MM-yyyy"
                          format="yyyy-MM-dd"
                          placeholder="Select Date"
                          v-bind="field"
                          :maxDate="serviceData.to_date"
                        />
                      </Field>
                      <ErrorMessage name="from_date" class="validation-msg mt-1" />
                    </div>

                    <div>
                      <Field
                        name="to_date"
                        rules="required:to_date"
                        id="to_date"
                        v-slot="{ field }"
                        v-model="serviceData.to_date"
                        class="form-control"
                      >
                        <Datepicker
                          autoApply
                          v-model="serviceData.to_date"
                          inputFormat="dd-MM-yyyy"
                          format="yyyy-MM-dd"
                          placeholder="Select Date"
                          v-bind="field"
                          :minDate="serviceData.from_date"
                        />
                      </Field>
                      <ErrorMessage
                        name="to_date"
                        class="validation-msg mt-1"
                        component="span"
                      />
                    </div>

                    <!-- <input type="date" class="form-control"> -->
                  </div>
                  <div class="col-lg-7">
                    <div class="btn-group check-btns font-12 gap-2" role="group">
                      <input
                        type="radio"
                        name="time-period"
                        class="btn-check"
                        id="btncheck1"
                        autocomplete="off"
                      />
                      <label
                        class="btn btn-outline-primary font-12 px-3"
                        name="time-period"
                        for="btncheck1"
                        v-on:click="handleDateSelection(`30`)"
                        >Last 30 days</label
                      >

                      <input
                        type="radio"
                        class="btn-check"
                        name="time-period"
                        id="btncheck2"
                        autocomplete="off"
                      />
                      <label
                        class="btn btn-outline-primary font-12 px-3"
                        for="btncheck2"
                        v-on:click="handleDateSelection(`60`)"
                        >Last 60 days</label
                      >

                      <input
                        type="radio"
                        class="btn-check"
                        name="time-period"
                        id="btncheck3"
                        autocomplete="off"
                      />
                      <label
                        class="btn btn-outline-primary font-12 px-3"
                        for="btncheck3"
                        v-on:click="handleDateSelection(`365`)"
                        >Last 1 year</label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mb-4 d-flex justify-content-between">
                <div>
                  <div class="d-flex gap-2 align-items-center">
                    <label class="text-nowrap">Export as</label>
                    <Field
                      v-slot="{ field }"
                      name="type"
                      rules="required:type"
                      :validateOnInput="true"
                      v-model="serviceData.type"
                    >
                      <select
                        class="form-select"
                        v-model="serviceData.type"
                        name="type"
                        v-bind="field"
                      >
                        <option :value="null">Select File Type</option>
                        <option value="PDF">PDF</option>
                        <option value="EXCEL">EXCEL</option>
                      </select>
                    </Field>
                  </div>

                  <ErrorMessage name="type" component="span" class="text-danger" />
                </div>
                <button native-type="submit" class="btn btn-primary" id="save_btn">
                  Export
                </button>
              </div>
            </Form>

            <!-- <div class="card p-4">
                          <h4 class="font-18 mb-4">Downloads</h4>
                          <div class="mb-3">
                            <p class="font-16 font-medium mb-2">PDF File</p>
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex gap-3 n">
                                  <img src="static/images/icon-file-color.svg" width="20" height="20"><span>Om Sai Workshop periodic service details</span>
                                </div>
                                <a class="cursor-pointer"><img src="static/images/icon-download.svg" width="22" height="22"></a>
                              </div>
                            </div>
                          </div>
                          <div class="mb-3">
                            <p class="font-16 font-medium mb-2">Excel File</p>
                            <div class="card p-3 rounded-4 bg-secondary-light">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex gap-3 n">
                                  <img src="static/images/icon-file-color.svg" width="20" height="20"><span>Om Sai Workshop periodic service details</span>
                                </div>
                                <a class="cursor-pointer"><img src="static/images/icon-download.svg" width="22" height="22"></a>
                              </div>
                            </div>
                          </div>
                        </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import { Form, Field, ErrorMessage } from "vee-validate";

import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "ServiceReport",
  components: {
    Datepicker,
    Form,
    Field,
    ErrorMessage,
  },

  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),
      serviceData: {
        from_date: "",
        to_date: "",
        type: null,
      },
    };
  },

  mounted() {},
  methods: {
    handleDateSelection(days) {
      const toDate = new Date();
      const fromDate = new Date();
      fromDate.setDate(toDate.getDate() - days);
      this.serviceData.from_date = fromDate.toISOString().split("T")[0];
      this.serviceData.to_date = toDate.toISOString().split("T")[0];
    },

    handlePdfDownload(url) {
      window.open(url, "_self");
    },
    handleSave() {
      let obj = { ...this.serviceData };
      obj.from_date = this.$helperService.sendFormattedDate(this.serviceData.from_date);
      obj.to_date = this.$helperService.sendFormattedDate(this.serviceData.to_date);
      this.$api
        .postAPI({
          _action: `service/report/${this.workshop_id}`,
          _body: obj,
          _buttonId: `save_btn`,
        })
        .then((res) => {
          if (res.path) {
            window.open(res.path);
          }
        });
    },
  },
};
</script>

