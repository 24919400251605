import {
  createStore
} from "vuex";
const store = createStore({
  state() {
    return {
      userInfo: {},
      isShowSidebar: false,
      summary: {
        company: [],
        businessGroup: [],
        webinars: [],
        activity: [],
      },
      keyword: "",
      notificationCount: 0,
      loanDisbursedOnject: null,
      dashBoardBranch: [],
      title: "",
      branches: [],
      allBranches: [],
      setOfficeExpenseBranch: [],
      has_full_access: false,
      id: "",
      workshopDetails: {},
      pageType: localStorage.getItem("page_type"),
      tabValue: "",
      currentPage: 0,
      orderId: 0,
      headerTab: "",
      accessToken: localStorage.getItem("access_token"),
      workshop_id: localStorage.getItem("workshop_id"),
      meCallData: JSON.parse(localStorage.getItem("userDetail")),
    };
  },
  mutations: {
    setUser(state, payload) {
      state.userInfo = payload;
    },
    setSummary(state, payload) {
      state.summary = payload;
    },
    showSidebar(state, payload) {
      state.isShowSidebar = payload;
    },
    keyword(state, payload) {
      state.keyword = payload;
    },
    setNotificationCount(state, payload) {
      state.notificationCount = payload;
    },
    setTitle(state, payload) {
      state.title = payload;
    },
    setBranches(state, payload) {
      state.branches = payload;
    },
    setDashBoardBranches(state, payload) {
      state.dashBoardBranch = payload;
    },
    setAllBranches(state, payload) {
      state.allBranches = payload;
    },
    setOfficeExpensesBranch(state, payload) {
      state.setOfficeExpenseBranch = payload;
    },
    hasFullAccess(state, payload) {
      state.has_full_access = payload;
    },
    setLoanDisbursedObj(state, payload) {
      state.loanDisbursedOnject = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setWorkshopDetails(state, payload) {
      state.workshopDetails = payload;
    },
    setPageType(state, payload) {
      state.pageType = payload;
    },
    setTabValue(state, payload) {
      state.tabValue = payload;
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },
    setOrderId(state, payload) {
      state.orderId = payload;
    },
    setHeaderTab(state, payload) {
      state.headerTab = payload;
    },
    setAccessToken(state, payload) {
      state.accessToken = payload;
    },
    setWorkshopId(state, payload) {
      state.workshop_id = payload;
    },
    setMeCallData(state, payload) {
      state.meCallData = payload;
    },
  },
  getters: {
    getPageType(state) {
      return state.pageType;
    },
    getUser(state) {
      return state.userInfo;
    },
    getShowSidebar(state) {
      return state.isShowSidebar;
    },
    getSummary(state) {
      return state.summary;
    },
    getNotificationCount(state) {
      return state.notificationCount;
    },
    getTitle(state) {
      return state.title;
    },
    getDashBoardBranches(state) {
      return state.dashBoardBranch;
    },
    getBranches(state) {
      return state.branches;
    },
    getAllBranches(state) {
      return state.allBranches;
    },
    getOfficeExpenseBranch(state) {
      return state.setOfficeExpenseBranch;
    },
    getHasFullAccess(state) {
      return state.has_full_access;
    },
    getLoanDisbursedObj(state) {
      return state.loanDisbursedOnject;
    },
    getId(state) {
      return state.id;
    },
    getWorkshopDetails(state) {
      return state.workshopDetails;
    },
    getTabValue(state) {
      return state.tabValue;
    },
    getCurrentPage(state) {
      return state.currentPage;
    },
    getOrderId(state) {
      return state.orderId;
    },
    getHeaderTab(state) {
      return state.headerTab;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getWorkshopId(state) {
      return state.workshop_id;
    },
    getMeCallData(state) {
      return state.meCallData;
    },
  },
  actions: {},
});

export default store;