<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-1">
          <a class="float-start mt-1 cursor-pointer" v-on:click="this.$router.go(-1)">
            <svg
              width="12"
              height="16"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                stroke="#001D21"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div class="col-lg-7 text-center">
          <h1 class="page-title">Request Callback</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="row justify-content-center">
            <Form @submit="getLogin()" ref="loginForm">
              <div class="mb-4">
                <div class="form-group">
                  <label>Mobile Number</label>
                  <span class="text-danger">*</span>
                  <div class="input-group">
                    <span class="input-group-text bg-white">
                      <img
                        src="static/images/mobile-icon.svg"
                        width="16"
                        class=""
                        alt=""
                      />
                      <span class="ms-2 font-bold">+91</span>
                    </span>
                    <Field
                      type="text"
                      name="phone"
                      placeholder="Mobile Number"
                      class="form-control mobile_no"
                      rules="required:mobile number|tenDigitNumber:10"
                      :validateOnInput="true"
                      v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                      v-on:keypress.enter="getLogin"
                      v-model="loginData.mobile_number"
                    />
                  </div>
                </div>
                <ErrorMessage name="phone" class="validation-msg mt-2" />
              </div>
              <button
                id="login-id"
                native-type="submit"
                class="btn btn-primary w-100 mb-3"
              >
                Request Callback
              </button>
            </Form>

            <h2 class="font-18 font-medium mb-3">Need Help?</h2>
            <div class="col-lg-12 mb-3" v-on:click="openWhatsapp()">
              <div class="card p-3 rounded-4 cursor-pointer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex gap-2 align-items-center">
                    <img src="static/images/icon-whatsapp.svg" width="50" height="50" />
                    <div>
                      <h3 class="font-16 font-medium mb-1">Ask us on whatsapp</h3>
                      <p class="mb-0 text-secondary">Get instant support</p>
                    </div>
                  </div>
                  <a href="">
                    <svg
                      width="10"
                      height="14"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#001D21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mb-3" v-on:click="$router.push('/faq')">
              <div class="card p-3 rounded-4 cursor-pointer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex gap-2 align-items-center">
                    <img src="static/images/icon-faq.svg" width="50" height="50" />
                    <div>
                      <h3 class="font-16 font-medium mb-1">Frequently asked questions</h3>
                      <p class="mb-0 text-secondary">Our team will contact you</p>
                    </div>
                  </div>
                  <a href="">
                    <svg
                      width="10"
                      height="14"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#001D21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mb-3" v-on:click="sendEmail()">
              <div class="card p-3 rounded-4 cursor-pointer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex gap-2 align-items-center">
                    <img src="static/images/icon-email.svg" width="50" height="50" />
                    <div>
                      <h3 class="font-16 font-medium mb-1">Mail your query</h3>
                      <p class="mb-0 text-secondary">Get instant support</p>
                    </div>
                  </div>
                  <a href="">
                    <svg
                      width="10"
                      height="14"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L5 5L1 9"
                        stroke="#001D21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "RequestCallback",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      setting: {},
      loginData: {},
    };
  },

  mounted() {
    this.GetSettingData();
  },
  methods: {
    sendEmail() {
      var mail = "mailto:" + this.setting.email;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
    openWhatsapp() {
      window.location.href =
        "https://api.whatsapp.com/send?phone=" + this.setting.whatsapp_number;
    },
    GetSettingData() {
      this.$api
        .getAPI({
          _action: `app-support`,
        })
        .then((res) => {
          this.setting = res.info;
        })
        .catch(() => {});
    },
    getLogin() {
      if (this.loginData.mobile_number.length < 10) {
        return;
      }
      this.loginData.dial_code = "+91";
      this.loginData.country_code = "in";
      this.$api
        .postAPI({
          _action: "/request-callback",
          _body: this.loginData,
          _buttonId: "login-id",
        })
        .then(() => {
          this.$notify({
            type: "success",
            text: "Thank you for your call back request. Our executive will respond shortly.",
          });
          this.loginData.mobile_number = "";
        })
        .catch(() => {});
    },
  },
};
</script>

