<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light px-3">
          <div class="row justify-content-center pt-5 form-style">
            <div class="col-lg-10">
              <div
                class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
              >
                <a v-on:click="handleBack" class="cursor-pointer">
                  <svg
                    width="12"
                    height="16"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                      stroke="#001D21"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <h1 class="font-22 mb-0">Set Service Cost</h1>
                <div></div>
              </div>

              <div class="row mb-4 justify-content-center">
                <div class="col-12 text-center">
                  <p class="text-secondary">
                    Select the vehicle for which you have to set service cost
                  </p>
                </div>

                <div class="col-lg-10 d-lg-flex justify-content-center mb-4">
                  <div class="mb-4">
                    <div
                      class="btn-group select-vehicle-boxes flex-wrap"
                      role="group"
                      aria-label="Basic checkbox toggle button group"
                    >
                      <div
                        class="position-relative mb-4"
                        v-for="(item, index) in vehicleList"
                        :key="index"
                      >
                        <div>
                          <div class="form-check">
                            <input
                              type="checkbox"
                              class="btn-check form-check-input"
                              :id="`PRIVATE_` + index"
                              autocomplete="off"
                              true-value="1"
                              false-value="0"
                              v-model="item.is_ticked"
                              disabled
                            />
                          </div>
                          <label
                            :class="['btn', 'btn-outline-primary']"
                            :for="`PRIVATE_` + index"
                            v-on:click="handleRedirect(item.id, item.title)"
                            :style="item.is_ticked ? { borderColor: '#004852' } : null"
                          >
                            <div class="label">{{ item.title }}</div>
                            <img
                              :src="item.icon"
                              class="icon"
                              width="100"
                              height="100"
                              alt=""
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <button
                    class="btn btn-primary px-5"
                    v-on:click="handleSubmit"
                    id="cost_btn"
                    :disabled="this.is_show"
                    native-type="submit"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>
<script>
import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "SelectVehicle",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      id: "",
      vehicleList: [],
      selectedVehicleID: [],
      is_checked: false,
      is_show: true,
      page_type: localStorage.getItem("page_type"),
    };
  },

  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getvehiclelist();
    } else {
      this.$router.push("/onboarding-steps");
    }
    setTimeout(() => {});

    setTimeout(() => {
      this.updateButtonState();
    }, 2000);

    // $(".btn-check").change(function () {
    //   $(this).closest(".form-check").toggleClass("selected", this.is_checked);
    // });
  },
  methods: {
    handleBack() {
      this.$router.go(-1);
    },
    // updateButtonState() {
    //   this.is_show = !this.vehicleList.forEach(
    //     (vehicle) => vehicle.is_ticked !== 1
    //   );
    // },

    getvehiclelist() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `my-vehicle/` + this.id,
        })
        .then((res) => {
          if (res && res.list) {
            this.vehicleList = res.list;
            if (res.list.length > 0) {
              this.selectedVehicleID = [];
              for (let i = 0; i < res.list.length; i++) {
                if (res.list[i].is_ticked && res.list[i].is_ticked) {
                  this.selectedVehicleID.push(res.list[i].id);
                }
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length == 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
        })
        .catch((error) => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
          console.error(error);
        });
    },

    updateButtonState() {
      console.log("this.vehicleList.length", this.vehicleList);
      for (let i = 0; i < this.vehicleList.length; i++) {
        if (this.vehicleList[i].is_ticked === 1) {
          this.is_show = false;
        } else {
          this.is_show = true;
        }
      }
      console.log("this.is_show", this.is_show);
    },

    handlecheckbox(id) {
      if (!this.selectedVehicleID.includes(id)) {
        this.selectedVehicleID.push(id);
      } else {
        this.selectedVehicleID = this.selectedVehicleID.filter((item) => item !== id);
      }
      console.log("this.selectedVehicleID---------", this.selectedVehicleID);
    },
    // handleRedirect(id) {
    //   this.$router.push("/set-service-manufacture-cost/"+id);

    // },

    handleRedirect(id, title) {
      this.$router.push({
        path: `/set-service-manufacture-cost/${id}`,
        // query: { title },
      });
      this.$sharedService.setMapValue("vehicleTitle", title);
    },

    handleSubmit() {
      this.$api
        .getAPI({
          _action: `get-category-brand/` + this.id,
          _buttonId: "cost_btn",
        })
        .then((res) => {
          if (res.brands.length > 0) {
            this.$router.push("/onboarding-steps");
          } else {
            this.$router.push("/rate-card");
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>

