<template>
  <div class="page-content">
    <div class="container py-3">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-7 text-center">
          <!-- <a href="/settings" class="float-start">
                    <svg
                      width="12"
                      height="16"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                        stroke="#001D21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a> -->
          <h1 class="page-title">Privacy Notice</h1>
        </div>
      </div>
      <!-- <div>
               <p class="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
               <p class="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
               <p class="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
               <p class="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div> -->

      <div class="text-secondary">
        <!-- The privacy policy content will be rendered here -->
        <span v-html="privacyPolicyData" class="text-secondary"></span>
      </div>
    </div>
    <ErrorComponent ref="errorComponent" />
  </div>
  <!-- Alert  -->
  <div
    class="modal fade"
    id="AlertrModal"
    tabindex="-1"
    aria-labelledby="AlertrModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-center">
            <img src="static/images/success-icon.svg" alt="" />
            <p>
              We have received your closure request, we will get back to you.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import "./onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    ErrorComponent,
  },

  data() {
    return {
      privacyPolicyData: null,
    };
  },

  mounted() {
    this.getPrivacyPolicy();
  },
  methods: {
    getPrivacyPolicy() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: `privacy-policy`,
        })
        .then((res) => {
          if (res) {
            this.privacyPolicyData = res;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
  },
};
</script>
