<template>
  <div class="login-onboarding">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-7">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
            >
              <a href="/settings" class=" ">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-0">Login Mobile Numbers</h1>
              <div></div>
            </div>
            <Form @submit="handleSave">
              <div class="card p-3 form-style mb-3 bg-light">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="font-bold font-18">Owner Numbers</p>
                  <a
                    v-on:click="handleAddNumber(`PRIMARY`)"
                    class="font-16 font-bold text-brand cursor-pointer"
                    >Add +</a
                  >
                </div>
                <p class="text-secondary mb-4">
                  Note: Primary number will have full access to app settings
                </p>
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label">Primary Mobile 1</label>
                    <div class="input-group mb-3">
                      <Field
                        type="text"
                        id="mobile_number"
                        class="form-control"
                        v-model="primaryNumber.mobile_number"
                        name="mobile_number"
                        placeholder="Enter Mobile Number "
                        :validateOnInput="true"
                        disabled
                      />
                    </div>

                  </div>

                  <div
                    class="col-lg-6"
                    v-for="(item, index) in this.primaryNumberList"
                    :key="index"
                  >
                    <label class="form-label"
                      >Primary Mobile {{ index + 2 }}</label
                    >
                    <div class="input-group mb-3">
                      <Field
                        type="text"
                        id="mobile_number"
                        class="form-control"
                        v-model="item.mobile_number"
                        :name="`mobile_number` + index + index"
                        placeholder="Enter Primary Number  "
                        :validateOnInput="true"
                        v-on:keypress="
                          $helperService.allowOnlyNumericValue($event)
                        "
                        rules="mobileNumber"
                      />
                    </div>

                      <ErrorMessage
                        :name="`mobile_number` + index + index"
                        component="span"
                        class="text-danger"
                      />
                  </div>
                </div>
              </div>

              <div class="card p-3 form-style mb-3 bg-light">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="font-bold font-18">Secondary Numbers</p>
                  <a
                    v-on:click="handleAddNumber(`SECONDRY`)"
                    class="font-16 font-bold text-brand cursor-pointer"
                    >Add +</a
                  >
                </div>
                <p class="text-secondary mb-4">
                  Note: Secondry number will have limited access to app
                </p>
                <div class="row">
                  <div
                    class="col-lg-6"
                    v-for="(item, index) in this.secondaryNumberList"
                    :key="index"
                  >
                    <label class="form-label"
                      >Secondary Mobile {{ index + 1 }}</label
                    >
                    <div class="input-group mb-3">
                      <Field
                        type="text"
                        id="mobile_number"
                        class="form-control"
                        v-model="item.mobile_number"
                        :name="`mobile_number` + index"
                        placeholder="Enter Secondry Number "
                        :validateOnInput="true"
                        v-on:keypress="
                          $helperService.allowOnlyNumericValue($event)
                        "
                        rules="mobileNumber"
                      />
                    </div>
                      <ErrorMessage
                        :name="`mobile_number` + index"
                        component="span"
                        class="text-danger"
                      />
                  
                  </div>
                  <!-- <div class="col-lg-6">
                  <label class="form-label">Secondary Mobile 2</label>
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control border-end-0"
                      value="839290290101"
                    />
                   
                  </div>
                </div> -->
                </div>
              </div>
              <div class="text-center mt-5">
                <button
                  native-type="submit"
                  id="btn_save"
                  class="btn btn-primary px-5"
                >
                  Save
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "../Onboarding/onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "LoginMobileNumbers",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),

      primaryNumberList: [
        {
          id: 0,
          mobile_number: "",
          dial_code: "91",
          country_code: "IN",
        },
      ],
      primaryNumber: {},
      secondaryNumberList: [
        {
          id: 0,
          mobile_number: "",
          dial_code: "91",
          country_code: "IN",
        },
      ],
    };
  },

  mounted() {
    this.getModalData();
  },
  methods: {
    getModalData() {
      this.$api
        .getAPI({
          _action: `get-workshop-mobile-number`,
        })
        .then((res) => {
          this.primaryNumber = res.primary;
          if (res.primary_list.length > 0) {
            this.primaryNumberList = res.primary_list;
          }
          if (res.secondary_list.length > 0) {
            this.secondaryNumberList = res.secondary_list;
          }
        })
        .catch(() => {});
    },
    handleAddNumber(type) {
      if (type == "PRIMARY") {
        this.primaryNumberList.push({
          id: 0,
          mobile_number: "",
          dial_code: "91",
          country_code: "IN",
        });
      } else {
        this.secondaryNumberList.push({
          id: 0,
          mobile_number: "",
          dial_code: "91",
          country_code: "IN",
        });
      }
    },

    handleSave() {
      const filteredPrimaryList = [];
      const filteredSecondaryList = [];
      for (let i = 0; i < this.primaryNumberList.length; i++) {
        const item = this.primaryNumberList[i];
        if (item.mobile_number !== "") {
          filteredPrimaryList.push(item);
        }
      }

      for (let i = 0; i < this.secondaryNumberList.length; i++) {
        const item = this.secondaryNumberList[i];
        if (item.mobile_number !== "") {
          filteredSecondaryList.push(item);
        }
      }

      const obj = {
        primary_list: filteredPrimaryList,
        secondary_list: filteredSecondaryList,
      };

      console.log("obj", obj);
      this.$api
        .postAPI({
          _action: `set-workshop-mobile-number`,
          _buttonId: "btn_save",
          _body: obj,
        })
        .then((res) => {
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        })
        .catch(() => {});
    },
  },
};
</script>
