<template>
  <div class="login-onboarding">
    <section>
      <div class="container">
        <!-- <div class="text-center border-bottom py-3">
          <img
            src="static/images/logo-horizontal.svg"
            width="200"
            class="mx-auto"
            alt=""
          />
        </div> -->
        <div class="bg-light">
          <div class="row justify-content-center pt-lg-5 pt-3 form-style">
            <div class="col-lg-10">
              <Form @submit="onSaveBankDetail">
                <div class="text-center">
                  <a @click="$router.go(-1)" class="float-start cursor-pointer">
                    <svg
                      width="12"
                      height="16"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                        stroke="#001D21"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                  <h1 class="font-22 mb-4">Bank Account Details</h1>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="beneficiary_name" class="form-label"
                        >Account Holder Name * </label
                      >
                      <Field
                        type="text"
                        id="beneficiary_name"
                        class="form-control"
                        name="beneficiary_name"
                        placeholder="Account Holder Name"
                        v-model="detail.beneficiary_name"
                        rules="required:Account Holder Name"
                        :validateOnInput="true"
                        :disabled="status == 'INACTIVE' || status == 'REVIEW'"
                      />
                      <ErrorMessage
                        name="beneficiary_name"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="account_number" class="form-label"
                        >Account Number *</label
                      >
                      <Field
                        type="text"
                        id="account_number"
                        class="form-control"
                        name="account_number"
                        placeholder="Enter Account Number"
                        v-model="detail.account_number"
                        rules="required:Account Number"
                        :validateOnInput="true"
                        v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                        :disabled="status == 'INACTIVE' || status == 'REVIEW'"
                      />
                      <ErrorMessage
                        name="account_number"
                        component="span"
                        class="text-danger"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="ifsc_code" class="form-label">IFSC Code *</label>
                      <Field
                        type="text"
                        id="ifsc"
                        class="form-control"
                        name="ifsc"
                        placeholder="Enter IFSC Code"
                        v-model="detail.ifsc"
                        rules="required:IFSC Code"
                        :validateOnInput="true"
                        @input="checkIFSC"
                        :disabled="status == 'INACTIVE' || status == 'REVIEW'"
                      />
                      <ErrorMessage name="ifsc" component="span" class="text-danger" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="bank_name" class="form-label">Bank Name *</label>
                      <Field
                        type="text"
                        id="bank"
                        class="form-control"
                        name="bank"
                        placeholder="Enter Bank Name"
                        v-model="detail.bank"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="mb-3">
                      <label for="branch_name" class="form-label">Branch Name *</label>
                      <Field
                        type="text"
                        id="branch"
                        class="form-control"
                        name="branch"
                        placeholder="Enter Branch Name"
                        v-model="detail.branch"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center mb-4">
                  <div class="col-lg-6 mb-3">
                    <label for="tb-file-upload" class="font-medium mb-2"
                      >Upload Cancelled Cheque</label
                    >
                    <div class="upload-btn-container">
                      <input type="file" id="tb-file-upload" @change="onValidateCheque" v-if="status == 'ACTIVE' || status == 'PENDING'" />
                      <label for="tb-file-upload" class="h-auto">
                        <div class="py-4 text-center">
                          <div v-if="!image">
                            <div class="mb-3">
                              <img
                                src="static/images/icon-file.svg"
                                width="80"
                                class="mx-auto"
                                alt=""
                              />
                            </div>
                            <div class="mb-3 text-secondary">
                              Select document to upload
                            </div>
                            <div>
                              <button type="button" class="btn btn-primary">
                                Upload File
                              </button>
                            </div>
                          </div>

                          <div v-else>
                            <img
                              :src="image ? image : 'static/images/default.png'"
                              width="100"
                              height="700"
                              alt="Uploaded Cheque Image"
                              style="width: 100% !important; height: 100% !important"
                            />
                          </div>
                        </div>
                      </label>
                      <span class="font-12 text-secondary"
                        >Supported files: JPEG, PNG (Max. file size 4 MB)</span
                      >
                    </div>
                  </div>
                </div>

                <div class="text-center mb-4">
                  <button
                    type="submit"
                    class="btn btn-primary px-5"
                    native-type="submit"
                    id="save-btn"
                    v-if="status == 'ACTIVE' || status == 'PENDING'"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ErrorComponent ref="errorComponent" />
      </div>
    </section>
  </div>
</template>

<script>
import "./onboarding.scss";
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent.vue";
export default {
  name: "BankDetails",
  components: {
    Form,
    Field,
    ErrorMessage,
    ErrorComponent,
  },
  data() {
    return {
      id: "",
      detail: {
        beneficiary_name: null,
        account_number: null,
        ifsc: null,
        bank: "",
        branch: "",
      },
      chequeImage: "",
      image: "",
      page_type: localStorage.getItem("page_type"),
      status: "",
    };
  },
  mounted() {
    if (this.page_type == "ADD_OUTLET") {
      this.id = localStorage.getItem("outlet_workshop_id");
    } else {
      this.id = localStorage.getItem("workshop_id");
    }
    if (this.id) {
      this.getWorkshopDetails();
    }
  },
  methods: {
    onValidateCheque(event) {
      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png"];
      const maxSize = 4 * 1024 * 1024;
      if (file) {
        if (!allowedTypes.includes(file.type)) {
          this.$notify({
            type: "error",
            text: "Only JPEG, PNG files are allowed.",
          });
          this.chequeImage = "";
        } else if (file.size > maxSize) {
          this.$toast.open({
            message: "File size must be less than 4 MB !",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.chequeImage = "";
        } else {
          this.chequeImage = file;
          const blob = URL.createObjectURL(file);
          this.image = blob;
        }
      }
    },

    onSaveBankDetail() {
      if (!this.detail.bank || !this.detail.branch) {
        this.$toast.open({
          message: "Please Enter valid IFSC",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }

      if (!this.chequeImage && !this.image) {
        this.$toast.open({
          message: "Please Upload Cheque Image",
          type: "error",
          duration: 3000,
          position: "top-right",
        });
        return;
      }

      this.$api
        .postAPI({
          _action: `set-bank/${this.id}`,
          _body: this.detail,
          _buttonId: "save-btn",
        })
        .then((res) => {
          this.onUploadCheque();
          this.$toast.open({
            message: res.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        });
    },

    onUploadCheque() {
      if (this.chequeImage) {
        console.log(" this.chequeImage", this.chequeImage);
        this.$api
          .uploadImageAPI({
            _action: "upload-cheque/" + this.id,
            _body: { image: this.chequeImage },
          })
          .then((res) => {
            this.$toast.open({
              message: res.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.chequeImage = "";
            this.$router.push("/onboarding-steps");
          })
          .catch((error) => {
            this.$toast.open({
              message: error.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          });
      }
    },
    checkIFSC() {
      if (this.detail.ifsc && this.detail.ifsc.length == 11) {
        this.detail.bank = "";
        this.detail.branch = "";

        this.$api
          .getAPI({
            _action: "/ifsc/" + this.detail.ifsc,
            _body: {},
            _hide_loader: true,
          })
          .then((res) => {
            if (res && res.info == "Not Found") {
              this.$toast.open({
                message: "Invalid IFSC Code",
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            } else {
              if (res.info.BRANCH) {
                this.detail.branch = res.info.BRANCH;
              }
              if (res.info.BANK) {
                this.detail.bank = res.info.BANK;
              }
            }
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      }
    },
    getWorkshopDetails() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/get-workshop/" + this.id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.info && res.info.workshop) {
            this.status = res.info.workshop.status;
            this.detail.beneficiary_name = res.info.workshop.beneficiary_name;
            this.detail.account_number = res.info.workshop.account_number;
            this.detail.ifsc = res.info.workshop.ifsc;
            this.detail.bank = res.info.workshop.bank;
            this.detail.branch = res.info.workshop.branch;
            this.image = res.info.workshop.cheque;
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            this.$refs.errorComponent.setTitle("");
          }
        });
    },
  },
};
</script>

