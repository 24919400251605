<template>
  <div class="page-content">
    <!-- <div class="container"><h1>Login</h1></div> -->
    <section>
      <div class="container">
        <div class="row justify-content-center pt-5 form-style">
          <div class="col-lg-7">
            <div
              class="text-center d-flex justify-content-between align-items-center mb-4 pb-3"
            >
              <a v-on:click="$router.go(-1)" style="cursor: pointer">
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.40059 10.7993L1.60059 5.99932L6.40059 1.19932"
                    stroke="#001D21"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
              <h1 class="font-22 mb-0">Service Cost</h1>
              <div></div>
            </div>

            <div class="row mb-4 justify-content-center">
              <div class="card p-3 mb-4 bg-light">
                <p class="font-bold mb-3">Vehicle Type</p>
                <div class="btn-group check-btns font-12 gap-2 flex-wrap" role="group">
                  <span v-for="(item, index) in vehicleList" :key="index">
                    <input
                      class="btn-check"
                      type="checkbox"
                      :id="item.title"
                      autocomplete="off"
                      v-model="item.selected"
                      @change="onVehicleSelection(item)"
                    />
                    <label
                      class="btn btn-outline-primary font-12 px-3"
                      :for="item.title"
                      >{{ item.title }}</label
                    >
                  </span>
                </div>
              </div>
              <div class="card p-3 mb-4 bg-light">
                <p class="font-bold mb-3">Manufacturer Name</p>
                <div class="btn-group check-btns font-12 gap-2 flex-wrap" role="group">
                  <span v-for="(item, index) in manufactureList" :key="index">
                    <input
                      class="btn-check"
                      type="checkbox"
                      :id="item.title"
                      autocomplete="off"
                      v-model="item.selected"
                      @change="onManufacturerSelection(item)"
                    />
                    <label
                      class="btn btn-outline-primary font-12 px-3"
                      :for="item.title"
                      >{{ item.title }}</label
                    >
                  </span>
                </div>
                <ErrorComponent ref="errorComponent" />
              </div>
              <Form @submit="onSaveSetCost()" class="form-group">
                <div class="mb-4">
                  <p class="font-bold font-18 mb-2">
                    Select Vehicle Model and Set the Cost
                  </p>
                  <p class="text-secondary mb-2">
                    This cost is applicable to Periodic services only
                  </p>
                  <p class="text-secondary">
                    If you unable see the desired model then add the vehicle from vehicle
                    setting and then set the cost
                  </p>
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Vehicle Model</th>
                        <th>Type</th>
                        <th width="150">Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <ErrorComponent ref="errorComponentModel" />
                      </tr>
                      <tr
                        v-for="(item, index) in this.myManufactureModalList"
                        :key="index"
                      >
                        <td>{{ item.title }}</td>
                        <td>{{ item?.fuel?.title }}</td>
                        <td>
                          <Field
                            type="number"
                            class="form-control"
                            :name="'cost_' + index"
                            placeholder="Enter Cost"
                            rules="required:cost"
                            v-model="item.cost"
                            v-on:keypress="$helperService.allowOnlyNumericValue($event)"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            :name="'cost_' + index"
                            class="validation-msg mt-1"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-center mt-5">
                  <button
                    id="btn_save"
                    :disabled="!myManufactureModalList.length >= 1"
                    class="btn btn-primary px-5"
                    native-type="submit"
                  >
                    Save
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import "../Onboarding/onboarding.scss";
import ErrorComponent from "@/components/ErrorComponent.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ServiceCost",
  components: {
    ErrorComponent,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      workshop_id: localStorage.getItem("workshop_id"),

      vehicleList: [],
      manufactureList: [],
      myManufactureModalList: [],
      vehicle_id: "",
      manufacture_id: "",
    };
  },

  mounted() {
    this.getVehicleList();
  },
  methods: {
    getVehicleList() {
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }
      this.$api
        .getAPI({
          _action: "/my-vehicle/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            this.vehicleList = res.list.map((item, index) => ({
              ...item,
              selected: index === 0, // select the first item by default
            }));
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }

          this.getManufactureList(res.list[0].id);
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },
    getManufactureList(vehicle_id) {
      this.manufactureList = [];
      this.myManufactureModalList = [];
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.showLoader(true);
      }

      this.manufactureList = [];

      this.vehicle_id = vehicle_id;
      this.$api
        .getAPI({
          _action: "/my-manufacturer/" + vehicle_id + "/" + this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            this.manufactureList = res.list.map((item, index) => ({
              ...item,
              selected: index === 0, // select the first item by default
            }));
          }

          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponent.setTitle("No Data Available");
            }
          }
          this.getMyManufactureModalList(vehicle_id, res.list[0].id);
        })
        .catch(() => {
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.showLoader(false);
          }
        });
    },

    getMyManufactureModalList(vehicle_id, manufacture_id) {
      if (this.$refs.errorComponentModel) {
        this.$refs.errorComponentModel.showLoader(true);
      }
      this.manufacture_id = manufacture_id;

      this.$api
        .getAPI({
          _action:
            "/my-manufacturer-model/" +
            vehicle_id +
            "/" +
            manufacture_id +
            "/" +
            this.workshop_id,
          _body: {},
          _hide_loader: true,
        })
        .then((res) => {
          if (res && res.list && res.list.length > 0) {
            this.myManufactureModalList = res.list;
          }

          if (this.$refs.errorComponentModel) {
            this.$refs.errorComponentModel.showLoader(false);
            if (!res.list || res.list.length <= 0) {
              this.$refs.errorComponentModel.setTitle("No Data Available");
            }
          }
        })
        .catch(() => {
          if (this.$refs.errorComponentModel) {
            this.$refs.errorComponentModel.showLoader(false);
          }
        });
    },

    onVehicleSelection(selectedItem) {
      this.vehicleList.forEach((item) => {
        item.selected = item.id === selectedItem.id;
      });
      this.manufactureList = [];
      this.myManufactureModalList = [];

      this.getManufactureList(selectedItem.id);
    },
    onManufacturerSelection(selectedItem) {
      this.manufactureList.forEach((item) => {
        item.selected = item.id === selectedItem.id;
      });
      this.myManufactureModalList = [];

      this.getMyManufactureModalList(this.vehicle_id, selectedItem.id);
    },
    onSaveSetCost() {
      console.log("this.myManufactureModalList", this.myManufactureModalList.length);

      const transformedList = this.myManufactureModalList.map((item) => ({
        manufacturer_model_id: item.id,
        fuel_id: item.fuel_id,
        cost: item.cost || 0,
      }));

      const obj = {
        vehicle_id: this.vehicle_id,
        manufacturer_id: this.manufacture_id,
        list: transformedList,
      };
      this.$api
        .postAPI({
          _action: `set-cost/${this.workshop_id}`,
          _buttonId: "btn_save",
          _body: obj,
        })
        .then(() => {
          this.getMyManufactureModalList(this.vehicle_id, this.manufacture_id);
        })
        .catch(() => {});
    },
  },
};
</script>

